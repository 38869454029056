export const getDiffBetweenObjects = (
  prev: Record<string, any>,
  next: Record<string, any>,
): Record<string, any> =>
  Object.keys(next).reduce((acc, item) => {
    const prevValue = prev[item];
    const nextValue = next[item];

    return prevValue === nextValue ? acc : { ...acc, [item]: nextValue };
  }, {});
