import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import { FetchArrayResponseWithoutNext } from '../../types';
import { getMultiSortingParams, getUrlFromPayload } from '../../utils';
import { ReconApiService } from '../api';
import { BreakItemsFilters, FileMonitorItem, BreakTypes } from '../types';

import { BreakMonitorInfoItem, AggregatedBreakStatistic } from './types';

const breaksStartInfo = '2022-01-01';
export async function getBreakMonitorInfo(mode: string, type: string) {
  if (!mode) {
    return {
      items: [],
      pagination: { total: 0 },
    };
  }
  try {
    const response = await ReconApiService.getResource<{
      results: BreakMonitorInfoItem[];
    }>(
      getUrlFromPayload('breaks-dashboard/aggregated-break/?', {
        mode,
        type,
      }),
    );
    const items = response.results.map((legal) => ({
      ...legal,
      subRows: legal.counterparties.map((cp) => ({
        ...cp,
        legalEntity: legal.name,
        subRows: cp.categories.map((category) => ({
          ...category,
          legalEntity: legal.name,
          counterparty: cp.name,
          subRows: category.side.map((item) => ({
            ...item,
            legalEntity: legal.name,
            counterparty: cp.name,
            category: category.name,
          })),
          side: '',
        })),
      })),
    }));
    return {
      items,
      pagination: { total: response.results.length },
    };
  } catch (e) {
    if (mode) {
      Notification.error({ title: `Get break monitor info error: ${e}` });
    }
  }
  return {
    items: [],
    pagination: { total: 0 },
  };
}

export async function getBreakItems(
  { params }: IOnFetchArguments,
  filters: BreakItemsFilters,
) {
  try {
    const payload = {
      from_date: breaksStartInfo,
      page: params.page + 1,
      limit: params.limit,
      side: filters.sideFilter,
      counterparty: filters.cpFilter,
      le: filters.leFilter,
      category: filters.categoryFilter,
    };
    const response = await ReconApiService.getResource<
      FetchArrayResponseWithoutNext<FileMonitorItem>
    >(
      `${getUrlFromPayload(
        '/breaks-dashboard/break-detail/?',
        payload,
      )}order=${getMultiSortingParams(params.sorting)}`,
    );
    return {
      items: response.results,
      pagination: { total: response.total },
    };
  } catch (e) {
    Notification.error({ title: `Get breaks error: ${e}` });
  }
  return {
    items: [],
    pagination: { total: 0 },
  };
}

export async function getBreakStatistic(
  filters: BreakItemsFilters & { typeFilter: BreakTypes },
) {
  if (!filters.modeFilter) {
    return [];
  }
  const payload = {
    start_date: breaksStartInfo,
    type: filters.typeFilter,
    mode: filters.modeFilter,
    cp: filters.cpFilter,
    legal_entity: filters.leFilter,
  };
  try {
    const response = await ReconApiService.getResource<
      FetchArrayResponseWithoutNext<AggregatedBreakStatistic>
    >(
      `${getUrlFromPayload(
        'breaks-dashboard/aggregated-break-statistics/?',
        payload,
      )}`,
    );
    return response.results;
  } catch (e) {
    Notification.error({ title: `Get break statistic error: ${e}` });
  }
  return [];
}
