import {
  shallowEqual,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';

import { AppDispatch, RootState } from 'store/store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const usePropSelector = <
  TState = unknown,
  TSelected = unknown,
  Args extends unknown[] = unknown[],
>(
  selector: (state: TState, ...args: Args) => TSelected,
  ...args: Args
): TSelected =>
  useSelector((state: TState) => selector(state, ...args), shallowEqual);
