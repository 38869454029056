import { styled } from '../../../../../theme';

export const CalendarButton = styled('button')(({ disabled }) => ({
  display: 'block',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: disabled ? 'auto' : 'pointer',
  padding: '0',
  fontSize: '0',
}));
