import { FC, memo, useEffect, useMemo, useState } from 'react';
import { Panel, Indicator } from 'react-ui-kit-exante';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

import {
  AggregatedBreakStatistic,
  BreakTypes,
  getBreakStatistic,
} from 'services/recon';
import { theme } from 'theme';

import { DetailedTableType } from '../types';

import classes from './StatisticBlock.module.css';

const barGreenColor = theme.color.typo.source;
const barRedColor = theme.color.typo.radical;
const gridGrayColor = theme.color.typo.ghost;

interface Props extends DetailedTableType {
  typeFilter: BreakTypes;
  width: number;
}

// eslint-disable-next-line react/display-name
export const StatisticBlock: FC<Props> = memo((props) => {
  const [statistic, setStatistic] = useState<AggregatedBreakStatistic[]>([]);

  const getStatistic = async () => {
    const stats = await getBreakStatistic(props);
    setStatistic(stats);
  };
  useEffect(() => {
    getStatistic();
  }, [props.modeFilter, props.typeFilter, props.leFilter, props.cpFilter]);

  const chartData = useMemo(
    () =>
      statistic.map((item) => ({
        opened: item.open_count,
        resolved: item.close_count,
        name: item.mon,
      })),
    [statistic],
  );

  const lineData = useMemo(() => {
    const { openedTotal, resolvedTotal, total } = statistic.reduce(
      (acc, item) => ({
        openedTotal: acc.openedTotal + item.open_count,
        resolvedTotal: acc.resolvedTotal + item.close_count,
        total: acc.total + item.count,
      }),
      {
        openedTotal: 0,
        resolvedTotal: 0,
        total: 0,
      },
    );
    const resolvedPercent = (resolvedTotal / total) * 100;
    const openedPercent = (openedTotal / total) * 100;
    return [
      {
        color: barGreenColor,
        tooltipText: `${resolvedTotal} breaks resolved`,
        value: resolvedPercent,
      },
      {
        color: barRedColor,
        tooltipText: `${openedTotal} breaks opened`,
        value: openedPercent,
      },
    ];
  }, [statistic]);

  return (
    <Panel title="Statistics" className="pt-0">
      <Indicator lines={lineData} />
      <div className="d-flex mt-3">
        {lineData.map((i) => (
          <div className="d-flex align-items-start ml-3" key={i.tooltipText}>
            <div
              className={classes.PointCircle}
              style={{ backgroundColor: i.color }}
            />
            <p className="m-0 ml-3">{i.tooltipText}</p>
          </div>
        ))}
      </div>
      <BarChart
        width={props.width}
        height={380}
        data={chartData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <Tooltip />
        <CartesianGrid strokeDasharray="10 10" stroke={gridGrayColor} />
        <XAxis
          height={30}
          tickMargin={15}
          axisLine={false}
          tickLine={false}
          dataKey="name"
        />
        <YAxis
          width={45}
          tickMargin={15}
          axisLine={false}
          tickLine={false}
          orientation="right"
        />
        <Bar
          radius={[5, 5, 0, 0]}
          dataKey="resolved"
          stackId="a"
          fill={barGreenColor}
        />
        <Bar
          radius={[5, 5, 0, 0]}
          dataKey="opened"
          stackId="a"
          fill={barRedColor}
        />
      </BarChart>
    </Panel>
  );
});
