import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  IOnFetchArguments,
  ISortBy,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import { Transaction, getTransactionBreakItems } from 'services/recon';
import { TableDataWithPagination } from 'types';
import { calculateCountOfPages } from 'utils';

import { DetailedTableType } from '../types';
import { getTableTitle } from '../utils/getTableTitle';

import { getColumns } from './utils/getColumns';

export const TransactionsTable: FC<DetailedTableType> = ({
  cpFilter,
  leFilter,
  sideFilter,
  categoryFilter,
  modeFilter,
}) => {
  const getTableData = useCallback(
    (props: IOnFetchArguments) =>
      getTransactionBreakItems(props, {
        cpFilter,
        leFilter,
        sideFilter,
        categoryFilter,
        modeFilter,
      }),
    [cpFilter, leFilter, modeFilter, sideFilter, categoryFilter],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTableData },
      pagination: { getDefaultPagination: () => ({ limit: 5, skip: 0 }) },
    }),
    [getTableData],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    setSorting,
  } = useTableData<TableDataWithPagination<Transaction>>(tableData);

  useEffect(() => {
    setPage(0);
  }, [sideFilter, cpFilter, leFilter]);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );
  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [setFilter, removeFilter],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const tableTile = useMemo(
    () => getTableTitle({ leFilter, sideFilter, categoryFilter, cpFilter }),
    [leFilter, categoryFilter, sideFilter, cpFilter],
  );

  return (
    <div className="mui-row">
      <div className="mui-col-md-12">
        <Table
          tableId="transactionBreaksTable"
          title={tableTile}
          showTableInfo
          hasPagination
          manualSortBy
          isFlexLayout
          showScrollbar
          locale={defaultLocale}
          data={data?.items ?? []}
          columns={columns}
          isLoading={isLoading}
          pageSizes={pageSizes}
          onSort={handleSorting}
          defaultSortBy={[]}
          serverPaginationProps={{
            pageIndex: page,
            pageCount,
            pageSize: limit,
            total: data?.pagination.total || 0,
            setPage,
            setPageSize: setLimit,
          }}
        />
      </div>
    </div>
  );
};
