import { SyntheticEvent } from 'react';
import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { ReportWithFields } from 'services/recon';
import { SelectOption } from 'types';

type IProps = {
  cpOptions: SelectOption[];
  leOptions: SelectOption[];
  modeOptions: SelectOption[];
  typeOptions: SelectOption[];
  stateOptions: SelectOption[];
  onSelectReport: (report: ReportWithFields) => void;
};
export const getColumns = ({
  onSelectReport,
  cpOptions,
  leOptions,
  modeOptions,
  typeOptions,
  stateOptions,
}: IProps) => {
  const columns: IColumn<ReportWithFields>[] = [
    {
      Header: 'Id',
      accessor: 'id',
      minWidth: 40,
      disableFilters: true,
    },
    {
      Header: 'Cp',
      accessor: 'counterparty',
      disableSortBy: true,
      type: 'select',
      filterOptions: cpOptions,
    },
    {
      Header: 'Legal entity',
      accessor: 'legal_entity',
      disableSortBy: true,
      type: 'select',
      filterOptions: leOptions,
    },
    {
      Header: 'Mode',
      accessor: 'mode',
      minWidth,
      disableSortBy: true,
      type: 'select',
      filterOptions: modeOptions,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth,
    },
    {
      Header: 'Type',
      accessor: 'type',
      minWidth,
      type: 'select',
      filterOptions: typeOptions,
    },
    {
      Header: 'State',
      accessor: 'state',
      minWidth,
      type: 'select',
      filterOptions: stateOptions,
    },
    {
      Header: 'Field mappings',
      accessor: 'fields',
      minWidth,
      tooltip: null,
      Cell: (cell: ICellValue<ReportWithFields>) => {
        const handleClickOnLink = (event: SyntheticEvent) => {
          event.preventDefault();
          onSelectReport(cell.row.original);
        };
        return (
          <div>
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="" onClick={handleClickOnLink}>
                Show
              </a>
            }
          </div>
        );
      },
      disableFilters: true,
      disableSortBy: true,
    },
  ];
  return columns;
};
