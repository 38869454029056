import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Notification, Table } from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { defaultPageSize, pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks';
import { ReactComponent as Copy } from 'icons/copy.svg';
import { ReactComponent as Trash } from 'icons/eye.svg';
import {
  ReportWithFields,
  getListMappingReportWithFields,
  postCloneReportWithFields,
} from 'services/recon';
import {
  modeNamesSelector,
  newCounterPartyNames,
  newLegalEntityNames,
  reportStatesSelector,
  reportTypesSelector,
} from 'store/reducers/commonReducer';
import { getSelectOptions } from 'utils';

import { ModalWithFieldsList } from './components';
import { getColumns } from './utils/getColumns';

export const ReportsListPage: FC = () => {
  const history = useHistory();
  const [reports, setReports] = useState<ReportWithFields[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ReportWithFields | null>(
    null,
  );

  const counterPartyNames = useAppSelector(newCounterPartyNames);
  const cpOptions = useMemo(
    () => getSelectOptions(counterPartyNames),
    [counterPartyNames],
  );

  const legalEntityNames = useAppSelector(newLegalEntityNames);
  const leOptions = useMemo(
    () => getSelectOptions(legalEntityNames),
    [legalEntityNames],
  );

  const modes = useAppSelector(modeNamesSelector);
  const modeOptions = useMemo(() => getSelectOptions(modes), [modes]);

  const reportTypes = useAppSelector(reportTypesSelector);
  const typeOptions = useMemo(
    () => getSelectOptions(reportTypes, 'type', 'type'),
    [reportTypes],
  );

  const reportStates = useAppSelector(reportStatesSelector);
  const stateOptions = useMemo(
    () => getSelectOptions(reportStates, 'state', 'state'),
    [reportStates],
  );

  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await getListMappingReportWithFields();

      setReports(response.filter((i) => i.type !== 'unpivot'));
    } catch (error) {
      Notification.error({
        title: 'Load reports error',
        description: String(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleCloneReport = useCallback(async (id: number) => {
    try {
      postCloneReportWithFields(id);
      Notification.success({ title: 'Cloned success' });
      loadData();
    } catch (error) {
      Notification.error({ title: 'Clone error', description: String(error) });
    }
  }, []);

  const handleSelectReport = useCallback(
    (report: ReportWithFields) => {
      setSelectedReport(report);
    },
    [setSelectedReport],
  );
  const columns = useMemo(
    () =>
      getColumns({
        onSelectReport: handleSelectReport,
        cpOptions,
        leOptions,
        modeOptions,
        typeOptions,
        stateOptions,
      }),
    [
      handleSelectReport,
      cpOptions,
      leOptions,
      modeOptions,
      typeOptions,
      stateOptions,
    ],
  );

  return (
    <div className="mui-container-fluid">
      <div className="mui-row">
        <div className="mui-col-md-12">
          <Table
            tableId="reportListTable"
            title="Report list"
            showTableInfo
            isFlexLayout
            hasPagination
            showScrollbar
            hasFilters
            locale={defaultLocale}
            columns={columns}
            isLoading={isLoading}
            data={reports}
            pageSize={defaultPageSize}
            pageSizes={pageSizes}
            defaultSortBy={[{ id: 'id', desc: true }]}
            rowActions={{
              show: true,
              additionalActions: [
                {
                  label: <Copy />,
                  onClick: (value) => {
                    handleCloneReport(value.id);
                  },
                  title: 'Copy',
                },
                {
                  label: <Trash />,
                  onClick: (value) => {
                    history.push(`/mapping/report/${value.id}`);
                  },
                  title: 'Detail',
                },
              ],
            }}
          />
          {selectedReport && (
            <ModalWithFieldsList
              fields={selectedReport.fields}
              reportId={selectedReport.id}
              onClose={() => setSelectedReport(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
