import { ICellValue, IRow } from 'react-ui-kit-exante';

import { theme } from 'theme';

export const compareCp = (
  cp?: string,
  counterparty?: string,
  secondCp?: string,
) =>
  [cp?.toUpperCase(), counterparty?.toUpperCase()].includes(
    secondCp?.toUpperCase(),
  );

export const getGetRowProps =
  ({
    selectedLe,
    selectedCp,
    selectedRawDataName,
  }: {
    selectedLe: string;
    selectedCp: string;
    selectedRawDataName: string;
  }) =>
  (row: IRow<Record<string, any>>) => {
    let background = theme?.color.table.bg.basic2;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const depth = row.depth;
    const leWasSelected = depth === 0 && row.values.name === selectedLe;
    const cpWasSelected = depth === 1 && row.values.name === selectedCp;
    const rawDataWasSelected =
      depth === 2 && row.values.name === selectedRawDataName;

    if (leWasSelected || cpWasSelected || rawDataWasSelected) {
      background = theme?.color.table.bg.source;
    }
    return {
      style: {
        background,
      },
    };
  };

export interface DataType {
  name: string;
  leName?: string;
  cpName?: string;
  rawDataName?: string;
  label: string;
}

export const getCellValue = (cell: ICellValue<DataType>) => {
  const { name, label } = cell.row.original;
  return (
    <div className="text-left">
      <div>
        <p className="m-0">{name}</p>
        <p className="font-size-8 m-0 blur">{label}</p>
      </div>
    </div>
  );
};
