import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Panel } from 'react-ui-kit-exante';

import { ReportForm } from './ReportForm/ReportForm';
import { ReportFormProvider } from './ReportFormProvider';

const urlPrefix = window.runUIhistoryContainer ? '/recon' : '';
export const NewReportPage: FC = () => {
  const [addedReports, setAddedReports] = useState<number[]>([]);
  const onNewReportCreated = (reportId: number) => {
    const newReports = [...addedReports];
    newReports.push(reportId);
    setAddedReports(newReports);
  };

  return (
    <div className="container-fluid">
      <Panel title="Create report">
        <div className="mt-2">
          <ReportFormProvider
            isUpdate={false}
            report={null}
            reloadData={() => undefined}
          >
            <ReportForm onNewReportCreated={onNewReportCreated} />
          </ReportFormProvider>
        </div>
        <div className="mt-2">
          {addedReports.map((reportId) => (
            <div key={reportId} className="alert alert-success" role="alert">
              Report added:{' '}
              <Link to={`${urlPrefix}/mapping/report/${reportId}/`}>
                {reportId}
              </Link>
            </div>
          ))}
        </div>
      </Panel>
    </div>
  );
};
