import React, { FC } from 'react';
import { IconButton, Loader, Tooltip } from 'react-ui-kit-exante';

interface IProps {
  isLoading: boolean;
  label?: string;
  onClick: VoidFunction;
}
export const DownloadIcon: FC<IProps> = ({
  isLoading,
  label = 'Download',
  onClick,
}) => {
  return isLoading ? (
    <Loader />
  ) : (
    <Tooltip title={label}>
      <IconButton iconName="DownloadIcon" onClick={onClick} />
    </Tooltip>
  );
};
