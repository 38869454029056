import dayjs from 'dayjs';

import { RawDataReportSetup } from 'services/recon';

export const mapRawData = (item: RawDataReportSetup) => {
  const today = dayjs();
  const fromDate = item.active_from ? dayjs(item.active_from) : null;
  const toDate = item.active_to ? dayjs(item.active_to) : null;
  const isSame =
    (fromDate && today.isSame(fromDate)) || (toDate && today.isSame(toDate));
  let isBetween = false;
  if (fromDate && toDate) {
    isBetween = today.isAfter(fromDate) && today.isBefore(toDate);
  }
  if (fromDate && !toDate) {
    isBetween = today.isAfter(fromDate);
  }
  if (!fromDate && toDate) {
    isBetween = today.isBefore(toDate);
  }
  return { ...item, isActive: isSame || isBetween || (!fromDate && !toDate) };
};
