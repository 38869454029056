import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Table,
  Select,
  Tabs,
  Tab,
  Panel,
  useTableData,
  CenteredLoader,
  styled,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { useAppSelector } from 'hooks/redux';
import { useResize } from 'hooks/useResize';
import { getBreakMonitorInfo, BreakTypes } from 'services/recon';
import { TableDataWithPagination } from 'types';
import { getSelectOptions } from 'utils/getSelectOptions';

import { StatisticBlock } from './StatisticBlock/StatisticBlock';
import { TradesTable } from './TradesTable/TradesTable';
import { TransactionsTable } from './TransactionsTable/TransactionsTable';
import { getRowProps, getCellProps } from './utils/getCellStyles';
import { getColumns } from './utils/getColumns';

const typeTabsValues = [
  BreakTypes.TRADE,
  BreakTypes.TRANSACTION,
  BreakTypes.POSITION,
  BreakTypes.BALANCE,
];

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
}));

export const BreakDashboard: FC = () => {
  const { containerRef, contentWidth } = useResize();

  const modeList = useAppSelector((state) => state.modeList);

  const [modeFilter, setModeFilter] = useState<string>('');
  const [cpFilter, setCpFilter] = useState('');
  const [leFilter, setLeFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [sideFilter, setSideFilter] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);

  const typeFilter = useMemo(() => typeTabsValues[selectedTab], [selectedTab]);
  const modeOptions = useMemo(
    () => getSelectOptions(modeList, 'name', 'name'),
    [modeList],
  );

  useEffect(() => {
    const firstElement = modeList[0];
    if (firstElement) {
      setModeFilter(firstElement.name);
    }
  }, [modeList]);
  useEffect(() => {
    setCpFilter('');
    setLeFilter('');
  }, [selectedTab]);

  const getTableData = useCallback(
    () => getBreakMonitorInfo(modeFilter, typeFilter),
    [modeFilter, typeFilter],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTableData },
    }),
    [getTableData],
  );

  const { data, isLoading } =
    useTableData<TableDataWithPagination<any>>(tableData);

  const columns = useMemo(() => getColumns(), []);

  const cleanFilters = () => {
    setLeFilter('');
    setCpFilter('');
    setCategoryFilter('');
    setSideFilter('');
  };

  const handleCellClick = useCallback(
    (cell: any) => {
      if (cell.column.id === 'name' && cell.row.depth !== 3) {
        return;
      }
      cleanFilters();
      const { name, category, counterparty, legalEntity } = cell.row.original;
      if (cell.row.depth === 0) {
        setLeFilter(name);
      }
      if (cell.row.depth === 1) {
        setLeFilter(legalEntity);
        setCpFilter(name);
      }
      if (cell.row.depth === 2) {
        setLeFilter(legalEntity);
        setCpFilter(counterparty);
        setCategoryFilter(name);
      }
      if (cell.row.depth === 3) {
        setLeFilter(legalEntity);
        setCpFilter(counterparty);
        setCategoryFilter(category);
        setSideFilter(name);
      }
    },
    [setLeFilter, setCpFilter, setCategoryFilter, setSideFilter],
  );

  const handleChangeMode = (event: ChangeEvent<HTMLInputElement>) => {
    setModeFilter(event.target.value);
  };
  const filtersWasDefined = !!leFilter && !!typeFilter;

  const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setSelectedTab(newValue);
  };

  if (!modeList.length) {
    return <CenteredLoader />;
  }

  return (
    <Container className="mui-container-fluid">
      <div className="mui-row">
        <div className="mui-col-md-12">
          <Panel title="Break Dashboard" className="pb-0">
            <Tabs value={selectedTab} onChange={handleChange}>
              <Tab label="Trades" />
              <Tab label="Transactions" />
              <Tab disabled label="Positions" />
              <Tab disabled label="Balances" />
            </Tabs>
            <div className="mui-row">
              <div className="mui-col-md-6 pr-0">
                <div className="w-lg mt-3 d-flex flex-column align-items-start">
                  <Select
                    size="small"
                    fullWidth
                    title="Direction"
                    value={modeFilter}
                    onChange={handleChangeMode}
                    options={modeOptions}
                  />
                </div>
                <Table
                  tableId="breakDashboardTree"
                  isFlexLayout
                  showScrollbar
                  locale={defaultLocale}
                  handleCellClick={handleCellClick}
                  getRowProps={getRowProps}
                  getCellProps={getCellProps}
                  data={data?.items ?? []}
                  columns={columns}
                  isLoading={isLoading}
                  defaultSortBy={[]}
                  noDataHeight="auto"
                  expanded={{
                    listOfInitialExpandedRowKeys: {
                      0: true,
                      '0.0': true,
                    },
                  }}
                />
              </div>
              <div className="mui-col-md-6 pl-0" ref={containerRef}>
                <StatisticBlock
                  width={contentWidth}
                  categoryFilter={categoryFilter}
                  sideFilter={sideFilter}
                  cpFilter={cpFilter}
                  leFilter={leFilter}
                  modeFilter={modeFilter}
                  typeFilter={typeFilter}
                />
              </div>
            </div>
          </Panel>
        </div>
      </div>
      {filtersWasDefined ? (
        <div className="mui-row mt-3">
          <div className="mui-col-md-12">
            {typeFilter === typeTabsValues[0] && (
              <TradesTable
                categoryFilter={categoryFilter}
                sideFilter={sideFilter}
                cpFilter={cpFilter}
                leFilter={leFilter}
                modeFilter={modeFilter}
              />
            )}
            {typeFilter === typeTabsValues[1] && (
              <TransactionsTable
                categoryFilter={categoryFilter}
                sideFilter={sideFilter}
                cpFilter={cpFilter}
                leFilter={leFilter}
                modeFilter={modeFilter}
              />
            )}
          </div>
        </div>
      ) : (
        <p className="mt-3 text-left">
          Please choose counterparty or legal entity for details
        </p>
      )}
    </Container>
  );
};
