export const clickToLinkForFetchingData = async (
  response: Response,
  fileName: string,
) => {
  const result = await response.blob();
  const aElement = document.createElement('a');
  aElement.setAttribute(
    'download',
    fileName.replace(/[.,/#!$%^&*;:{}=_`~()-]/g, '_'),
  );
  const href = URL.createObjectURL(result);
  aElement.href = href;
  aElement.setAttribute('target', '_blank');
  aElement.click();
  URL.revokeObjectURL(href);
};
