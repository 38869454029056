export const getObjectFromSearchString = (
  str: string,
): Record<string, string> =>
  str
    .replace('?', '')
    .split('&')
    .reduce((acc, i) => {
      const [key, val] = i.split('=');
      return { ...acc, [key]: val };
    }, {});
