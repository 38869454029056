import { theme } from 'theme';

export const getRowProps = (row: any) => ({
  style: {
    background:
      row.depth > 0
        ? theme?.color.table.bg.basic1
        : theme?.color.table.bg.basic2,
    boxShadow: `0 0 0 ${theme?.color.table.bg.basic2}`,
  },
});

export const getCellProps = (cell: any) => getRowProps(cell.row);
