import { FC, useEffect, useState } from 'react';
import { Modal, Notification, Loader } from 'react-ui-kit-exante';

import { getScriptOutput } from 'services/reconRunner';

interface IProps {
  onClose: VoidFunction;
  scriptID: string;
  outputID: string;
}
export const ShowLogModal: FC<IProps> = ({ onClose, scriptID, outputID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');
  const fetchOutput = async () => {
    try {
      setIsLoading(true);
      const response = await getScriptOutput(scriptID, outputID);
      setDescription(response.result);
    } catch (error) {
      Notification.error({ title: 'Load output error' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOutput();
  }, []);

  return (
    <Modal title="Detail" onClose={onClose} isOpened>
      <div className="d-flex justify-content-center w-100">
        {isLoading ? <Loader size="l" /> : <p>{description}</p>}
      </div>
    </Modal>
  );
};
