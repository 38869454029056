import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  OnSaveEditableRow,
  Table,
  Notification,
  IconButton,
  Checkbox,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks';
import { ReactComponent as Copy } from 'icons/copy.svg';
import { ReactComponent as Trash } from 'icons/trash.svg';
import {
  MappingReport,
  updateMappingReport,
  unbindMappingReport,
} from 'services/recon';
import {
  modeNamesSelector,
  reportStatesSelector,
} from 'store/reducers/commonReducer';
import { getSelectOptions } from 'utils';

import { getColumns } from './utils';

interface IProps {
  mappings: MappingReport[];
  cp: string;
  le: string;
  rawDataSetup: string;
  rawDataSetupId: number;
  fetchData: VoidFunction;
  onOpenCreating: VoidFunction;
  onOpenCloning: (mapping: MappingReport) => void;
}

const displayedColumnKeys = ['report_id', 'name', 'mode', 'state', 'Type'];

export const MappingReportsTable: FC<IProps> = ({
  mappings,
  cp,
  le,
  rawDataSetup,
  rawDataSetupId,
  fetchData,
  onOpenCreating,
  onOpenCloning,
}) => {
  const reportStates = useAppSelector(reportStatesSelector);
  const reportModeNames = useAppSelector(modeNamesSelector);
  const [isShowInActive, setIsShowInActive] = useState(false);

  const reportStateOptions = useMemo(
    () => getSelectOptions(reportStates, 'state', 'state'),
    [reportStates],
  );
  const reportModeOptions = useMemo(
    () => getSelectOptions(reportModeNames),
    [reportModeNames],
  );
  const handleUnbind = useCallback(async (id: number) => {
    try {
      await unbindMappingReport(id, rawDataSetupId);
      Notification.success({
        title: 'Report was unbinded',
      });
    } catch (error) {
      Notification.error({
        title: 'Error in unbind process',
        description: JSON.stringify(error),
      });
    }
  }, []);
  const handleUpdateReport: OnSaveEditableRow<MappingReport> = async (
    previousValues,
    updatedValues,
  ) => {
    try {
      await updateMappingReport(
        {
          name: updatedValues.name,
          mode: updatedValues.mode,
          state: updatedValues.state,
        },
        updatedValues.report_id,
      );
      fetchData();
      Notification.success({
        title: 'Report was updated',
      });
    } catch (error) {
      Notification.error({
        title: 'Update report error',
        description: JSON.stringify(error),
      });
    }
  };

  const additionalActions = [
    {
      component: (
        <Checkbox
          className="ml-3"
          label="Show inActive"
          checked={isShowInActive}
          onChange={(_, checked) => {
            setIsShowInActive(checked);
          }}
        />
      ),
      onClick: () => {},
      title: 'Show inActive',
    },
    {
      component: (
        <IconButton
          iconColor="action"
          iconName="AddIcon"
          label="Add normalization"
          onClick={onOpenCreating}
        />
      ),
      onClick: () => {},
      title: 'Add normalization',
    },
  ];
  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: handleUpdateReport,
      additionalActions: [
        {
          label: <Copy />,
          onClick: (value: MappingReport) => {
            onOpenCloning(value);
          },
          title: 'Copy',
        },
        {
          label: <Trash />,
          onClick: (value: MappingReport) => {
            handleUnbind(value.report_id);
          },
          title: 'Unbind',
        },
      ],
    }),
    [handleUnbind],
  );

  const columns = useMemo(
    () => getColumns(reportStateOptions, reportModeOptions),
    [reportStateOptions, reportModeOptions],
  );

  const mappedMappings = useMemo(
    () =>
      mappings
        .map((item) => ({
          ...item,
          isActive: ['Active', 'Testing'].includes(item.state),
        }))
        .filter((item) => (isShowInActive ? true : item.isActive)),
    [mappings, isShowInActive],
  );

  return (
    <Table
      tableId="rawDataTableForSetup"
      title={`${le} - ${cp} - ${rawDataSetup} - Normalization`}
      displayedColumnKeys={displayedColumnKeys}
      additionalActions={additionalActions}
      rowActions={rowActions}
      columns={columns}
      data={mappedMappings}
      pageSizes={pageSizes}
      pageSize={5}
      isFlexLayout
      hasPagination
      showTableInfo
      locale={defaultLocale}
    />
  );
};
