import { CpDataMonitorItemStatuses, Types } from 'services/recon';
import { theme } from 'theme';

export const backgroundByStatus = {
  [CpDataMonitorItemStatuses.FILE_NOT_FOUND]: theme?.color.table.bg.radical,
  [CpDataMonitorItemStatuses.FILE_NOT_FOUND_NOTIFIED]:
    theme?.color.table.bg.radical,
  [CpDataMonitorItemStatuses.COMPLETED]: theme?.color.table.bg.source,
  [CpDataMonitorItemStatuses.HOLIDAY]: theme?.color.table.bg.source,
  [CpDataMonitorItemStatuses.NOT_READY]: theme?.color.button.radical.typo.focus,
  [CpDataMonitorItemStatuses.WAITING]: theme?.color.button.radical.typo.focus,
  [CpDataMonitorItemStatuses.IN_PROGRESS]: theme?.color.table.bg.basic2,

  [CpDataMonitorItemStatuses.HAVE_NOT_STATUS]: theme?.color.table.bg.basic2,
  [CpDataMonitorItemStatuses.NOT_STARTED]:
    theme?.color.button.radical.typo.focus,
  [CpDataMonitorItemStatuses.FAILED]: theme?.color.table.bg.radical,
  [CpDataMonitorItemStatuses.DONE]: theme?.color.table.bg.source,
};

export const dataTypesBySegments = [
  [Types.Transactions, Types.Cash],
  [Types.Trades, Types.Positions],
  [Types.Other],
];
export const typeLabels = {
  [Types.Positions]: 'Positions',
  [Types.Trades]: 'Trades',
  [Types.Transactions]: 'Transactions',
  [Types.Cash]: 'Cash Balances',
  [Types.Other]: 'Other',
};

export const statusWeights = {
  [CpDataMonitorItemStatuses.FILE_NOT_FOUND]: 1,
  [CpDataMonitorItemStatuses.FILE_NOT_FOUND_NOTIFIED]: 1,
  [CpDataMonitorItemStatuses.FAILED]: 1,

  [CpDataMonitorItemStatuses.NOT_READY]: 1,
  [CpDataMonitorItemStatuses.IN_PROGRESS]: 1,
  [CpDataMonitorItemStatuses.WAITING]: 1,
  [CpDataMonitorItemStatuses.NOT_STARTED]: 1,
  [CpDataMonitorItemStatuses.HAVE_NOT_STATUS]: 1,

  [CpDataMonitorItemStatuses.COMPLETED]: 3,
  [CpDataMonitorItemStatuses.DONE]: 3,
  [CpDataMonitorItemStatuses.HOLIDAY]: 3,
};
