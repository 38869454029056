export const getTableTitle = ({
  leFilter,
  cpFilter,
  categoryFilter,
  sideFilter,
}: {
  leFilter: string;
  cpFilter?: string;
  categoryFilter?: string;
  sideFilter?: string;
}) => {
  const cpTitle = cpFilter ? `∙${cpFilter}` : '';
  const categoryTitle = categoryFilter ? `∙${categoryFilter}` : '';
  const sideTitle = sideFilter ? `∙${sideFilter}` : '';

  return `${leFilter}${cpTitle}${categoryTitle}${sideTitle}`;
};
