import { FC, useEffect, useState } from 'react';
import { Panel, Notification, Input, Tooltip } from 'react-ui-kit-exante';

import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as SaveIcon } from 'icons/save.svg';
import {
  ParsingModuleParam,
  getParsingModuleParams,
  RawDataReportSetup,
  UpdateRawDataReportPayload,
  updateRawDataReportConfig,
} from 'services/recon';

import { ModuleItem, ModuleValue } from '../ModuleItem/ModuleItem';
import { paramValuesMap } from '../ModuleItem/utils';

interface IProps {
  reloadData: VoidFunction;
  onClose: VoidFunction;
  setup: RawDataReportSetup;
}

export const UpdateRawData: FC<IProps> = ({ reloadData, onClose, setup }) => {
  const legalEntity = setup.legal_entity;
  const counterparty = setup.counterparty || setup.cp;

  const [name, setName] = useState('');

  const [selectedModuleParams, setSelectedModuleParams] = useState<
    ParsingModuleParam[]
  >([]);
  const [selectedModuleValues, setSelectedModuleValues] = useState<
    ModuleValue[]
  >([]);

  const handleChangeValues = (value: ModuleValue) => {
    const editedValues = selectedModuleValues.find(
      (i) => i.param_name === value.param_name,
    );
    if (editedValues) {
      const withoutEdited = selectedModuleValues.filter(
        (i) => i.param_name !== value.param_name,
      );
      setSelectedModuleValues([...withoutEdited, value]);
    }
  };

  useEffect(() => {
    if (!setup.module) {
      return;
    }
    setName(setup.name);
    getParsingModuleParams({
      legal_entity_name: legalEntity,
      counterparty_name: counterparty,
      module: setup.module,
    })
      .then((res) => {
        setSelectedModuleParams(res.fields);
        setSelectedModuleValues(
          res.fields
            .map((item) => ({
              ...item,
              default_value:
                setup.module_configs.find(
                  (config) => config.param_name === item.param_name,
                )?.param_value ?? null,
            }))
            .map(paramValuesMap),
        );
      })
      .catch((error) => {
        Notification.error({
          title: `Fetch parsing module params error: ${error}`,
        });
      });
  }, [setup]);

  const handleUpdateRawData = async () => {
    const payload: UpdateRawDataReportPayload = {
      name,
      module_configs: selectedModuleValues
        .filter((item) => !!item.param_value)
        .map((item) => {
          const foundElement = setup.module_configs.find(
            (config) => config.param_name === item.param_name,
          );
          return foundElement?.id
            ? {
                id: foundElement.id,
                param_value: String(item.param_value),
                param_name: item.param_name,
              }
            : {
                param_value: String(item.param_value),
                param_name: item.param_name,
              };
        }),
    };
    try {
      await updateRawDataReportConfig(payload, setup.id);
      Notification.success({
        title: `Raw data with id ${setup.id} was updated`,
      });
      setName('');
      setSelectedModuleValues([]);
      reloadData();
      onClose();
    } catch (error) {
      Notification.error({
        title: `Update raw data with id ${setup.id} error:`,
        description: JSON.stringify(error),
      });
    }
  };

  return (
    <Panel
      title="Update raw data"
      action={
        <div className="d-flex">
          <div className="pointer mr-1" onClick={handleUpdateRawData}>
            <SaveIcon />
          </div>
          <div className="pointer" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
      }
    >
      <div className="text-left">
        <p>Cp: {counterparty}</p>
        <p>Le: {legalEntity}</p>
        <p>Module: {setup.module}</p>
        <Input
          label="Name"
          className="mb-3"
          fullWidth
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        {selectedModuleParams.map((item) => (
          <div className="my-3" key={item.id}>
            {item.description ? (
              <Tooltip title={item.description}>
                <ModuleItem
                  disabled={item.param_name === 'mode'}
                  item={item}
                  onChangeValue={handleChangeValues}
                  values={selectedModuleValues}
                />
              </Tooltip>
            ) : (
              <ModuleItem
                item={item}
                onChangeValue={handleChangeValues}
                values={selectedModuleValues}
              />
            )}
          </div>
        ))}
      </div>
    </Panel>
  );
};
