import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { CheckOfMovement } from 'services/recon';

export const operationTypes = [
  'ACCRUED INTEREST',
  'ATM WITHDRAWAL',
  'CORPORATE ACTION',
  'COUPON PAYMENT',
  'DEBIT CARD',
  'DIVIDEND',
  'ELECTRONIC TRANSFER',
  'EXERCISE',
  'FUNDING/WITHDRAWAL',
  'INTERNAL TRANSFER',
  'ISSUANCE FEE',
  'POSITION TRANSFER',
  'REDEMPTION',
  'SECURITY TRANSFER',
  'STOCK SPLIT',
  'SUBACCOUNT TRANSFER',
  'SUBSCRIPTION',
  'SUBSCRIPTION/REDEMPTION',
  'TAX',
  'TRADE',
  'TRADE CORRECTION',
  'US TAX',
];
export const columns: IColumn<CheckOfMovement>[] = [
  {
    Header: 'Legal entity',
    accessor: 'legal_entity',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Asset',
    accessor: 'asset',
    minWidth,
    disableFilters: true,
    Cell: ({ row }: ICellValue<CheckOfMovement>) => (
      <span style={{ whiteSpace: 'pre-wrap', fontWeight: '700' }}>
        {row.values.asset}
      </span>
    ),
  },
  {
    Header: 'Operation type',
    accessor: 'operation_type',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Liabilities',
    accessor: 'liabilities',
    minWidth,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Assets',
    accessor: 'assets_xle',
    minWidth,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Diff',
    accessor: 'diff',
    minWidth,
    disableFilters: true,
    Cell: ({ row }: ICellValue<CheckOfMovement>) => (
      <span style={{ whiteSpace: 'pre-wrap', fontWeight: '700' }}>
        {row.values.diff}
      </span>
    ),
  },
];
