import React from 'react';
import { createRoot } from 'react-dom/client';

import { addBaseTag } from 'utils';

import './styles/index.css';
import App from './App';
import { ComposedThemeProvider } from './theme/ComposedThemeProvider';

addBaseTag();

export const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('root-recon');

  if (!rootNode) {
    throw new Error('Root element was not found');
  }

  const root = createRoot(rootNode);

  window.RECON_UI = {
    ...(window.RECON_UI || {}),
    root,
  };

  root.render(
    <React.StrictMode>
      <ComposedThemeProvider>
        <App />
      </ComposedThemeProvider>
    </React.StrictMode>,
  );
};

window.RECON_UI = {
  ...(window.RECON_UI || {}),
  render: (node: Element) => initApp(node),
};

// is rendered not inside run-ui
if (!window.runUIhistoryContainer) {
  initApp();
}
