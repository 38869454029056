import React from 'react';

interface GetFilterParams {
  filterKeys: string[];
  applyObject: Record<string, any>;
  isApply: React.MutableRefObject<boolean>;
  shouldApply?: boolean;
  tableId?: string;
}

export const getDefaultFilterParams =
  ({
    filterKeys,
    applyObject,
    isApply,
    shouldApply = true,
    tableId,
  }: GetFilterParams) =>
  (params: Record<string, unknown>): Record<string, unknown> => {
    const tempParams = params;
    const localStorageItem = localStorage.getItem(`${tableId}-view-params`);
    const localStorageObject = JSON.parse(localStorageItem ?? '{}') as Record<
      string,
      Record<string, unknown>
    >;
    if (!isApply.current && shouldApply) {
      Object.keys(applyObject).forEach((key) => {
        if (!tempParams[key]) {
          tempParams[key] =
            localStorageObject?.filters?.[key] ?? applyObject[key];
        }
      });
      // eslint-disable-next-line no-param-reassign
      isApply.current = true;
    }
    return Object.keys(tempParams).reduce(
      (acc, param) =>
        filterKeys.includes(param)
          ? { ...acc, [param]: tempParams[param] }
          : acc,
      {},
    );
  };
