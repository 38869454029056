import { SelectOption } from 'types';

export const options: SelectOption[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Trades',
    value: 'trades',
  },
  {
    label: 'Cash',
    value: 'cash',
  },
  {
    label: 'Positions',
    value: 'positions',
  },
  {
    label: 'Recon',
    value: 'recon',
  },
  {
    label: 'Transaction Recon',
    value: 'transaction-recon',
  },
  {
    label: 'Transactions',
    value: 'transactions',
  },
];
