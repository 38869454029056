import { ChangeEvent, useState } from 'react';
import { Notification, useModal } from 'react-ui-kit-exante';

import { useAppSelector } from 'hooks';
import { confirmNoFile } from 'services/recon';

export function useHolidayConfirmation({
  reportDate,
  reloadData,
}: {
  reportDate: string;
  reloadData: VoidFunction;
}) {
  const {
    isOpened,
    onClose: closeModal,
    onOpen: openModal,
  } = useModal({ defaultOpen: false });

  const counterPartyList = useAppSelector(
    (state) => state.new_counterparty_list,
  );
  const [cp, setCp] = useState('');
  const [comment, setComment] = useState('');

  const handleChangeComment = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };
  const handleOpenConfirmModal = (cpName: string) => {
    setCp(cpName);
    openModal();
  };

  const handleCloseModal = () => {
    setCp('');
    setComment('');
    closeModal();
  };

  const handleConfirm = async () => {
    try {
      await confirmNoFile({
        comment,
        counterparty_id:
          counterPartyList.find((item) => item.name === cp)?.id ?? null,
        report_date: reportDate,
      });
      handleCloseModal();
      Notification.success({ title: 'Information was updated successfully' });
      setTimeout(() => {
        reloadData();
      }, 1000);
    } catch (error) {
      Notification.error({
        title: 'Updating error',
        description: String(error),
      });
    }
  };

  return {
    isOpened,
    handleCloseModal,
    handleOpenConfirmModal,
    comment,
    handleConfirm,
    handleChangeComment,
  };
}
