import { ComparisonType, ReportWithFields } from 'services/recon';

import { FieldMappingConditionItem, FieldMappingItem } from './types';

type Type = {
  fields: FieldMappingItem[];
  conditions: FieldMappingConditionItem[];
};
export const getMappingFieldsAndConditions = (report: ReportWithFields): Type =>
  report.fields.reduce(
    (acc: Type, item) => ({
      fields: [
        ...acc.fields,
        {
          condition_operations: item.condition_operations ?? '',
          date_format: item.date_format ?? '',
          mapping_field: item.mapping_field ?? '',
          cp_field: item.cp_field ?? '',
          skip: String(item.skip),

          uuid: String(item.id),
          isNew: false,
        },
      ],
      conditions: [
        ...acc.conditions,
        ...item.conditions.map((condition) => ({
          condition_field: condition.condition_field,
          condition_value: condition.condition_value,
          comparison_type: condition.comparison_type as ComparisonType,

          mappingId: String(item.id),
          uuid: String(condition.id),
          isNew: false,
        })),
      ],
    }),
    {
      fields: [],
      conditions: [],
    },
  );
