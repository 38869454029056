import { ICellValue, IRow } from 'react-ui-kit-exante';

import { ScriptRunStatuses } from 'services/reconRunner';
import { theme } from 'theme';

import { rowColorByStatus } from '../consts';

export const getRowProps = (row: IRow<ScriptRunStatuses>) => ({
  style: {
    background: rowColorByStatus[row.original.state],
    boxShadow: `0 0 0 ${theme?.color.table.bg.basic2}`,
  },
});
export const getCellProps = (cell: ICellValue<ScriptRunStatuses>) =>
  getRowProps(cell.row);
