import { FC, useContext, useEffect, useMemo } from 'react';
import { Autocomplete, Button, Input, Notification } from 'react-ui-kit-exante';

import {
  CreateMappingReportWithFieldsPayload,
  patchReport,
  postMappingFields,
  postMappingReportWithFields,
} from 'services/recon';

import { FieldMappingItem } from '../FieldMappingItem/FieldMappingItem';
import { ReportFormContext } from '../ReportFormContext';
import { useReportForm } from '../hooks';

import { useListsOptions } from './hooks/useListsOptions';

interface Props {
  onNewReportCreated: (val: number) => void;
}

export const ReportForm: FC<Props> = ({ onNewReportCreated }) => {
  const {
    mappings,
    conditions,
    cleanAllItems,
    onAddMapping,
    getConditionsByMappingId,
    report,
    isUpdate,
    reloadData,
    onUpdateMapping,
    onUpdateCondition,
  } = useContext(ReportFormContext);
  const {
    formData,
    setFormData,
    handleChangeName,
    handleChangeCounterparty,
    handleChangeLe,
    handleChangeMode,
    handleChangeType,
    handleChangeSetups,
    wasUpdated,
  } = useReportForm(report);

  const {
    setupDefaultValue,
    reportTypeOptions,
    mappingFieldOptions,
    cpOptions,
    leOptions,
    modeOptions,
    setupOptions,
  } = useListsOptions(formData.reportType);

  const updateIsDisabled = useMemo(
    () => !wasUpdated && mappings.length === report?.fields?.length,
    [wasUpdated, mappings, report],
  );

  useEffect(() => {
    if (!formData.setups.length && setupDefaultValue) {
      setFormData({ ...formData, setups: [setupDefaultValue] });
    }
  }, [setupDefaultValue]);

  const handleCreateReport = async () => {
    try {
      const {
        name,
        counterpartyName,
        modeName,
        legalEntityName,
        reportType,
        setups,
      } = formData;
      const payloadFieldMappingItems = mappings.map(
        ({ uuid, isNew, ...item }) => ({
          ...item,
          conditions: getConditionsByMappingId(uuid).map(
            ({ uuid: conditionUuid, mappingId, isNew: _, ...condition }) =>
              condition,
          ),
        }),
      );
      const payload: CreateMappingReportWithFieldsPayload = {
        name,
        counterparty: counterpartyName,
        mode: modeName,
        legal_entity: legalEntityName,
        type: reportType,
        setup_ids: setups.map((i) => i.value).join(','),
        field_mappings: payloadFieldMappingItems,
      };
      const response = await postMappingReportWithFields(payload);
      cleanAllItems();
      onNewReportCreated(response.report_id);
      Notification.success({
        title: `Create report with report_id: ${response.report_id}`,
      });
    } catch (e) {
      Notification.error({ title: `Create report error: ${e}` });
    }
  };

  const handleUpdateReport = async () => {
    const { name, counterpartyName, modeName, legalEntityName, reportType } =
      formData;

    const payload = {
      name,
      counterparty: counterpartyName,
      mode: modeName,
      legal_entity: legalEntityName,
      type: reportType,
    };
    await patchReport(report?.id ?? 0, payload);
    const payloadFieldMappingItems = mappings
      .filter((item) => item.isNew)
      .map(({ uuid, isNew: _, ...item }) => ({
        ...item,
        conditions: getConditionsByMappingId(uuid).map(
          ({ uuid: conditionUuid, isNew, mappingId, ...condition }) =>
            condition,
        ),
      }));

    if (payloadFieldMappingItems.length) {
      try {
        await postMappingFields({
          report_id: report?.id.toString() ?? '',
          field_mappings: payloadFieldMappingItems,
        });
        mappings.forEach((item) => {
          onUpdateMapping({ ...item, isNew: false });
        });
        conditions.forEach((item) => {
          onUpdateCondition({ ...item, isNew: false });
        });
        Notification.success({
          title: `Success update fields for report`,
        });
      } catch (error) {
        Notification.error({
          title: 'Update fields for report error',
          description: String(error),
        });
      }
      reloadData();
    } else {
      reloadData();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <Input
            label="Name"
            name="name"
            value={formData.name}
            fullWidth
            onChange={handleChangeName}
          />
        </div>
        <div className="col-md-2">
          <Autocomplete
            placeholder="Type"
            className="w-100"
            defaultValue={[formData.reportType]}
            options={reportTypeOptions}
            onChange={handleChangeType}
          />
        </div>
        <div className="col-md-2 mb-2">
          <Autocomplete
            placeholder="Mode"
            className="w-100"
            defaultValue={[formData.modeName]}
            options={modeOptions}
            onChange={handleChangeMode}
          />
        </div>
        <div className="col-md-3 mb-2">
          <Autocomplete
            placeholder="Counterparty"
            className="w-100"
            defaultValue={[formData.counterpartyName]}
            options={cpOptions}
            onChange={handleChangeCounterparty}
          />
        </div>
        <div className="col-md-3 mb-2">
          <Autocomplete
            placeholder="Legal Entity"
            className="w-100"
            defaultValue={[formData.legalEntityName]}
            options={leOptions}
            onChange={handleChangeLe}
          />
        </div>
        <div className="col-md-4 mb-2">
          {setupDefaultValue ? (
            <Autocomplete
              placeholder="Raw setups"
              className="w-100"
              isMultiple
              defaultValue={[setupDefaultValue]}
              options={setupOptions}
              onChange={handleChangeSetups}
            />
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="m-0 mt-2 text-left">
            <b className="font-size-16">Filed mappings:</b>
          </p>
        </div>
      </div>
      {mappings.map((item) => (
        <FieldMappingItem
          mappingFieldOptions={mappingFieldOptions}
          value={item}
          key={item.uuid}
        />
      ))}
      <div className="row mt-2">
        <div className="col-md-12 d-flex justify-content-left">
          {!isUpdate ? (
            <Button className="mr-2" onClick={handleCreateReport}>
              Create
            </Button>
          ) : (
            <Button
              disabled={updateIsDisabled}
              className="mr-2"
              onClick={handleUpdateReport}
            >
              Update
            </Button>
          )}
          <Button onClick={onAddMapping}>Add Field Mapping</Button>
        </div>
      </div>
    </>
  );
};
