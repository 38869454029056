import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { DATE_FORMAT } from 'constants/date';
import { runScripts, Script } from 'services/reconRunner';

export function useSelectedRows(
  reloadData: VoidFunction,
  selectedRows: Script[],
  onUnselectAll: VoidFunction,
) {
  const [scriptsIsRunning, setScriptsIsRunning] = useState<boolean>(false);
  const [runModalIsVisible, setRunModalIsVisible] = useState<boolean>(false);

  const [dateField, setDateField] = useState<Date | null>(null);
  const handleChangeDate = (date: Date | null) => {
    setDateField(date);
  };

  const handleCloseRunModal = () => setRunModalIsVisible(false);
  const handleOpenRunModal = () => setRunModalIsVisible(true);

  const { handleRunScripts } = useMemo(() => {
    const handle = async () => {
      setScriptsIsRunning(true);
      try {
        await runScripts(
          {
            scripts: selectedRows.map((item) => item.compose_name),
            force_run: true,
            use_bo_drive: false,
            date: dateField ? dayjs(dateField).format(DATE_FORMAT) : '',
          },
          false,
        );
        Notification.success({
          title: 'Scripts were re-run',
        });
        onUnselectAll();
      } catch (error) {
        Notification.error({
          title: 'Run script error',
          description: String(error),
        });
      } finally {
        setScriptsIsRunning(false);
        setRunModalIsVisible(false);
      }
    };
    return { handleRunScripts: handle };
  }, [selectedRows, dateField]);

  return {
    selectedRows,
    scriptsIsRunning,
    handleRunScripts,
    runModalIsVisible,
    handleCloseRunModal,
    handleOpenRunModal,
    dateField,
    handleChangeDate,
  };
}
