import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import {
  getListComparisonType,
  postDeleteMappingField,
  ReportWithFields,
} from 'services/recon';

import { ReportFormContext } from './ReportFormContext';
import { emptyCondition, emptyFieldMapping } from './consts';
import {
  FieldMappingItem as IFieldMappingItem,
  FieldMappingConditionItem,
  FieldMappingItem,
} from './types';
import { getMappingFieldsAndConditions } from './utils';

interface IProps extends PropsWithChildren {
  isUpdate: boolean;
  report: ReportWithFields | null;
  reloadData: VoidFunction;
}

export const ReportFormProvider: FC<IProps> = ({
  children,
  isUpdate,
  report,
  reloadData,
}) => {
  const [comparisonTypes, setComparisonTypes] = useState<{ type: string }[]>(
    [],
  );
  const { initialFieldMappings, initialConditions } = useMemo(() => {
    if (report) {
      const { fields: tempFields, conditions: tempConditions } =
        getMappingFieldsAndConditions(report);
      return {
        initialFieldMappings: tempFields,
        initialConditions: tempConditions,
      };
    }

    return { initialFieldMappings: [], initialConditions: [] };
  }, [report]);

  const [fieldMappingItems, setFieldMappingItems] =
    useState<IFieldMappingItem[]>(initialFieldMappings);
  const [conditions, setConditions] =
    useState<FieldMappingConditionItem[]>(initialConditions);

  const onUpdateMapping = (value: FieldMappingItem) => {
    const foundIndex = fieldMappingItems.findIndex(
      (item) => item.uuid === value.uuid,
    );
    const tempItems = [...fieldMappingItems];
    tempItems[foundIndex] = value;
    setFieldMappingItems(tempItems);
  };
  const onDeleteMapping = (uuid: string) => {
    setFieldMappingItems(
      fieldMappingItems.filter((item) => item.uuid !== uuid),
    );
  };
  const onAddMapping = () => {
    setFieldMappingItems([
      ...fieldMappingItems,
      { ...emptyFieldMapping, uuid: Math.random().toString(), isNew: true },
    ]);
  };

  const onUpdateCondition = (value: FieldMappingConditionItem) => {
    const foundIndex = conditions.findIndex((item) => item.uuid === value.uuid);
    const tempItems = [...conditions];
    tempItems[foundIndex] = value;
    setConditions(tempItems);
  };
  const onDeleteCondition = (uuid: string) => {
    setConditions(conditions.filter((item) => item.uuid !== uuid));
  };
  const onAddCondition = (mappingId: string) => {
    setConditions([
      ...conditions,
      {
        ...emptyCondition,
        uuid: Math.random().toString(),
        mappingId,
        isNew: true,
      },
    ]);
  };
  const getConditionsByMappingId = (mappingId: string) =>
    conditions.filter((item) => item.mappingId === mappingId);
  const cleanAllItems = () => {
    setConditions([]);
    setFieldMappingItems([]);
  };

  const onPermanentlyDeleteMapping = async (uuid: string) => {
    try {
      await postDeleteMappingField(uuid);
      onDeleteMapping(uuid);
      Notification.success({ title: 'Was deleted' });
      reloadData();
    } catch (error) {
      Notification.error({
        title: 'Delete mapping error',
        description: String(error),
      });
    }
  };

  const loadComparisonTypes = async () => {
    try {
      const response = await getListComparisonType();
      setComparisonTypes(response.results);
    } catch (error) {
      Notification.error({ title: 'Load comparison types error' });
    }
  };

  useEffect(() => {
    loadComparisonTypes();
  }, []);

  const value = useMemo(
    () => ({
      isUpdate,
      report,
      comparisonTypes,
      mappings: fieldMappingItems,
      conditions,
      onUpdateMapping,
      onDeleteMapping,
      onUpdateCondition,
      onDeleteCondition,
      onPermanentlyDeleteMapping,
      cleanAllItems,
      onAddMapping,
      onAddCondition,
      getConditionsByMappingId,
      reloadData,
    }),
    [fieldMappingItems, conditions, report, reloadData, comparisonTypes],
  );

  return (
    <ReportFormContext.Provider value={value}>
      {children}
    </ReportFormContext.Provider>
  );
};
