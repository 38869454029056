import { IColumn, ISelectOption } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { TradeReconMonitorItem } from 'services/recon';
import { ColumnsWithFilters } from 'types';

interface GetColumnsPayload extends ColumnsWithFilters {
  modeOptions: ISelectOption[];
}
export const getColumns = ({
  onFilter,
  onRemove,
  modeOptions,
}: GetColumnsPayload): IColumn<TradeReconMonitorItem>[] => [
  {
    Header: 'Report Date',
    accessor: 'report_date',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Counterparty',
    accessor: 'counterparty',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Legal entity',
    accessor: 'legal_entity',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Mode',
    accessor: 'mode',
    minWidth,
    type: 'select',
    filterOptions: modeOptions,
    onFilter,
    onRemove,
  },
  {
    Header: 'Total BO',
    accessor: 'total_bo_trades',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'BO matched',
    accessor: 'bo_reconciled',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'BO not matched',
    accessor: 'bo_not_reconciled',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Total CP',
    accessor: 'total_cp_trades',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Cp Matched',
    accessor: 'cp_reconciled',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Cp Not Matched',
    accessor: 'cp_not_reconciled',
    minWidth,
    disableFilters: true,
  },
];
