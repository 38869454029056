import dayjs from 'dayjs';
import { useCallback, useMemo, useRef } from 'react';
import { IOnFetchArguments } from 'react-ui-kit-exante';

import { DEFAULT_FILTER_VALUES } from 'constants/tables';
import { CpAndCTrade, getCpAndCTrades } from 'services/recon';
import { getDefaultFilterParams } from 'utils';

import { getTradesTableColumns } from './utils';

export const useGetTableData = ({
  isReconciledPage,
  breakCategoryObject,
  counterparty,
  firstLegalEntity,
}: {
  isReconciledPage: boolean;
  breakCategoryObject: Record<string, string>;
  counterparty: string;
  firstLegalEntity: string;
}) => {
  const filtersIsFirstMounted = useRef(false);
  const isFirstRequest = useRef(true);

  const filterKeys = useMemo(
    () =>
      getTradesTableColumns({}, isReconciledPage).map(
        (column) => column.accessor,
      ),
    [isReconciledPage],
  );

  const tableId = isReconciledPage ? 'DivideReconciledTrades' : 'MixedTrades';

  const applyObject = useMemo(() => {
    return {
      date: [dayjs().subtract(7, 'day').toDate(), dayjs().toDate()],
      mode: DEFAULT_FILTER_VALUES.mode,
      counterparty,
    };
  }, []);

  const prepareFiltersForParams = useCallback(
    getDefaultFilterParams({
      tableId,
      filterKeys,
      applyObject,
      isApply: filtersIsFirstMounted,
      shouldApply: !JSON.parse(
        localStorage.getItem(`${tableId}-view-params`) ?? '{}',
      )?.filters,
    }),
    [],
  );

  const getRequestPayload = useCallback((props: IOnFetchArguments) => {
    const { break_report_category: breakReportCategory } = props.filtersParams;
    return {
      ...props,
      filtersParams: {
        ...props.filtersParams,
        break_report_category:
          (breakReportCategory as string[])?.map(
            (name) => breakCategoryObject[name],
          ) ?? undefined,
      },
    };
  }, []);

  const getTrades = useCallback((props: IOnFetchArguments) => {
    if (isFirstRequest.current) {
      isFirstRequest.current = false;
      return Promise.resolve().then(() => ({
        trades: [] as CpAndCTrade[],
        pagination: { total: 0 },
      }));
    }
    return getCpAndCTrades(getRequestPayload(props), isReconciledPage);
  }, []);

  const tableData = useMemo(
    () => ({
      tableId,
      data: { onFetch: getTrades },
      filters: {
        prepareFiltersForParams,
        getDefaultFilters: () => ({
          le: firstLegalEntity,
        }),
      },
      saveViewParamsAfterLeave: true,
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
    }),
    [getTrades],
  );

  return {
    tableId,
    tableData,
    getRequestPayload,
  };
};
