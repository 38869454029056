import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Notification } from 'react-ui-kit-exante';

import { DEFAULT_FILTER_VALUES } from 'constants/tables';
import {
  Report,
  getListMappingReports,
  getListMappingCpTradesFields,
  getListCpTransactionFields,
  getListMappingPositionReconciliationFields,
  getListMappingRowBalanceFields,
} from 'services/recon';
import { getSelectOptions } from 'utils/getSelectOptions';

import { AdvancedValueMappingCondition } from './types';

export function useValueMappingCreateForm() {
  const [counterparty, setCounterparty] = useState<string>('');
  const [legalEntity, setLegalEntity] = useState<string>(
    DEFAULT_FILTER_VALUES.le,
  );
  const [mode, setMode] = useState<string>(DEFAULT_FILTER_VALUES.mode);
  const [type, setType] = useState<string>('');
  const [report, setReport] = useState<Report | null>(null);
  const [reports, setReports] = useState<Report[]>([]);
  const reportOptions = useMemo(
    () =>
      getSelectOptions(
        reports.filter((i) => !!i.name),
        'name',
        'id',
      ),
    [reports],
  );

  const [fields, setFields] = useState<string[]>([]);
  const [results, setResults] = useState<string[]>([]);
  const fieldOptions = useMemo(() => getSelectOptions(fields), [fields]);
  const resultOptions = useMemo(() => getSelectOptions(results), [results]);

  const handleSetCounterparty = (event: SyntheticEvent, newValue: any) => {
    setCounterparty(newValue?.value ?? '');
  };
  const handleSetLegalEntity = (event: ChangeEvent<HTMLInputElement>) => {
    setLegalEntity(event.target.value);
  };
  const handleSetMode = (event: ChangeEvent<HTMLInputElement>) => {
    setMode(event.target.value);
  };
  const handleSetType = (event: ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  };
  const handleSetReport = (event: ChangeEvent<HTMLInputElement>) => {
    const foundReport = reports.find(
      (item) => item.id === Number(event.target.value),
    );
    setReport(foundReport ?? null);
  };

  const [startDate, setStartDate] = useState<Date | null>(null);
  const handleChangeStartDate = (date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const [endDate, setEndDate] = useState<Date | null>(null);
  const handleChangeEndDate = (date: Date | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  const loadReports = async () => {
    try {
      const reportsRes = await getListMappingReports({
        counterparty,
        legal_entity: legalEntity,
        mode,
        type,
      });
      setReports(reportsRes.content);
    } catch (error) {
      Notification.error({ title: `Load report error: ${error}` });
    }
  };

  const loadFields = async () => {
    try {
      if (report?.type === 'trades') {
        const response = await getListMappingCpTradesFields();
        setFields(response.fields);
        setResults(response.results);
      }
      if (report?.type === 'transactions') {
        const response = await getListCpTransactionFields();
        setFields(response.fields);
        setResults(response.results);
      }
      if (report?.type === 'positions') {
        const response = await getListMappingPositionReconciliationFields();
        setFields(response.fields);
        setResults(response.results);
      }
      if (report?.type === 'cash') {
        const response = await getListMappingRowBalanceFields();
        setFields(response.fields);
        setResults(response.results);
      }
    } catch (error) {
      Notification.error({ title: `Load fields error: ${error}` });
    }
  };

  useEffect(() => {
    loadReports();
  }, [counterparty, legalEntity, mode, type]);
  useEffect(() => {
    loadFields();
  }, [report]);

  return {
    type,
    handleSetType,
    counterparty,
    report,
    handleSetCounterparty,
    legalEntity,
    handleSetLegalEntity,
    mode,
    handleSetMode,
    handleSetReport,
    reportOptions,
    fieldOptions,
    resultOptions,
    startDate,
    handleChangeStartDate,
    endDate,
    handleChangeEndDate,
  };
}

export function useMappingConditions() {
  const [conditions, setConditions] = useState<AdvancedValueMappingCondition[]>(
    [],
  );

  const handleRemoveCondition = (uuid: string) => {
    setConditions(conditions.filter((item) => item.uuid !== uuid));
  };
  const handleAddCondition = () => {
    setConditions([
      ...conditions,
      {
        uuid: Math.random().toString(),
        condition_value: '',
        condition_field: '',
        comparison_type: '',
      },
    ]);
  };
  const handleUpdateCondition = (item: AdvancedValueMappingCondition) => {
    const foundConditionIndex = conditions.findIndex(
      (condition) => condition.uuid === item.uuid,
    );
    const tempConditions = [...conditions];
    tempConditions[foundConditionIndex] = item;
    setConditions(tempConditions);
  };

  return {
    conditions,
    handleRemoveCondition,
    handleAddCondition,
    handleUpdateCondition,
  };
}
