import React, { FC, useRef, useState } from 'react';
import {
  Select,
  Input,
  Notification,
  Button,
  Panel,
  InputDatePicker,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { CreateSymbolMappingPayload, postSymbolsMapping } from 'services/recon';
import { getFormPayload } from 'services/utils';

import { ManualMappingFormProps } from '../types';

const AddManualMapping: FC<ManualMappingFormProps> = ({
  cpOptions,
  onRefreshTable,
}) => {
  const [startDate, setStartDate] = useState<Date | null>();
  const form = useRef<HTMLFormElement | null>(null);

  const handleChangeStartDate = (date: Date | null) => {
    setStartDate(date);
  };
  const handleSubmit: any = async (event: FormDataEvent) => {
    event.preventDefault();

    if (!form.current) {
      return;
    }
    const payload = getFormPayload(form.current);
    const {
      cp,
      cp_symbol: cpSymbol,
      exante_symbol: exanteSymbol,
      ccy,
    } = payload;

    if (!ccy) {
      Notification.warning({ title: 'Ccy is not specified' });
      return;
    }
    if (!cp) {
      Notification.warning({ title: 'Counterparty is not specified' });
      return;
    }
    if (!cpSymbol) {
      Notification.warning({ title: 'Counterparty symbol is not specified' });
      return;
    }
    if (!exanteSymbol) {
      Notification.warning({ title: 'Exante symbol is not specified' });
      return;
    }
    if (typeof exanteSymbol === 'string') {
      const split = exanteSymbol.split('.');
      if (split.length < 2) {
        Notification.warning({
          title: 'Looks like Exante symbol is in wrong format',
        });
        return;
      }
    }

    payload.account = payload.cp;
    payload.start_date = startDate ? startDate.toISOString() : undefined;
    try {
      await postSymbolsMapping(
        payload as unknown as CreateSymbolMappingPayload,
      );
      onRefreshTable({
        cpSymbol: cpSymbol as unknown as string,
        exanteSymbol: exanteSymbol as unknown as string,
      });
      Notification.success({
        title: 'Mapping was created',
      });
    } catch (e) {
      if (String(e).includes('unique')) {
        Notification.error({
          title:
            'The combination of such symbols and counterparty already exists.',
        });
      } else {
        Notification.error({ title: 'Create mapping error' });
      }
    }
  };

  const saveActions = (
    <div className="d-flex align-items-center">
      <Button className="mr-2" onClick={handleSubmit}>
        Add
      </Button>
    </div>
  );

  return (
    <Panel title="Add mapping" action={saveActions} maxHeight={800}>
      <form ref={form}>
        <div className="mt-4">
          <Select placeholder="CP" fullWidth name="cp" options={cpOptions} />
        </div>
        <div className="mt-4">
          <Input name="cp_symbol" fullWidth label="CP Symbol" />
        </div>
        <div className="mt-4">
          <Input fullWidth name="exante_symbol" label="Exante Symbol" />
        </div>
        <div className="mt-4">
          <Input fullWidth name="ccy" label="CCY" />
        </div>

        <div className="mt-4">
          <InputDatePicker
            locale={defaultLocale}
            onChange={handleChangeStartDate}
            selected={startDate}
            inputProps={{
              fullWidth: true,
              label: 'Start date',
            }}
          />
        </div>
        <div className="mt-4">
          <Input fullWidth name="cp_type" label="Cp Type" />
        </div>
        <div className="mt-4">
          <Input fullWidth name="isin" label="ISIN" />
        </div>
        <div className="mt-4">
          <Input
            fullWidth
            name="value_multiplier"
            type="number"
            label="Mty Value"
          />
        </div>
        <div className="mt-4">
          <Input
            fullWidth
            name="price_multiplier"
            type="number"
            label="Mty Price"
          />
        </div>
        <div className="mt-4">
          <Input
            fullWidth
            name="qty_multiplier"
            type="number"
            label="Mty Qty"
          />
        </div>
      </form>
    </Panel>
  );
};

export default AddManualMapping;
