export enum SIDE {
  OUR = 'our',
  THEIR = 'their',
}

export interface CpTrade {
  full_id: number;
  report_date: string;
  trade_date: string;
  broker_id: string;
  symbol: string;
  type: string;
  qty: number;
  price: number;
  value: number;
  value_date: string;
  bo_symbol: string;
  bo_trade_number: string;
  cp_id: number;
  exchange_fees: number;
  fee: number;
  id: string;
  timestamp: string;
  valid: number;
  username: string;
  bo_cp: string;
  exchange_order_id: string;
  type_of_trade: string;
  comment: string;
  exchange_fee_currency: string;
  clearing_fee_ccy: string;
  ccy: string;
  recon_account: string;
  fee_2: number;
  fee_3: number;
  interest: number;
  account: string;
  trade_id: string;
  exchange: string;
  rec_status: boolean;
  type_of_recon: string;
  type_of_issue: string;
  request_link: string;
  uid: string;
  source_file: string;
  isin: string;
  legal_entity: number;
  order_pos: number;
  bbg_code: string;
  cp_price: number;
  cp_qty: number;
  cusip: string;
  sedol: string;
  rule_id: string;
  strike: number;
  underlying: string;
  value_rules_applied: string;
  option_type: string;
  parsing_tag: string;
  parsing_uniq_hash: string;
  compare_fields: string;
  report: number;
  raw: number;
  recon_group: number;
}

export interface CpAndCTrade {
  assignee: string;
  bo_symbol: string;
  cp_symbol: string;
  qty: number;
  price: number;
  value: number;
  value_date: string;
  account: string;
  side: SIDE;
  ccy: string;
  date: string;
  type: string;
  trade_type: string;
  id: number;
  legal_entity: number;
  cp: string;
  legal_entity_name: string;
  cpname: string;
  isin: string | null;
  symbol_type: string | null;
  break_report_category: string | null;
  break_comment: string | null;
  recon_group_id: string;
  type_of_recon: string;
  chain_id: string;
  comment: string | null;
  cp_account: string;
  delivery_date: string;
  exchange_order_id: string;
  execution_cp: string;
  internal_comment: string | null;
  is_manual: boolean;
  mty: number;
  mode: string;
  order_id: string;
  order_pos: number;
  pnl: number;
  settlement_cp: string;
  trade_date: string;
  trade_number: number;
  user: string;
}

export interface Transaction {
  id: number;
  account: string;
  assignee: string;
  client_account_id: string;
  amount: number | string;
  break_comment?: string;
  break_report_category?: string;
  ccy: string;
  comment: string;
  category: string | null;
  cp: string;
  cpname: string;
  isin?: string;
  operationtype: string;
  reportdate: string;
  value_date: string;
  side: string;
  symbol?: string;
  symboltype?: string;
  mode: string;
  count: number;
  transaction_id: number;
  recon_group_id?: number;
  reconciliation_type: string;
  user?: string;
  legal_entity_name: string;
}

export interface BreakItemsFilters {
  cpFilter: string;
  leFilter: string;
  sideFilter: string;
  categoryFilter: string;
  modeFilter: string;
}

export interface FileMonitorItem {
  count: number;
  id: number;
  legal_entity: string;
  legal_entity_id: number;
  counterparty_name: string;
  counterparty_id: number;
  file_id: number;
  date: string;
  location: string;
  contains: string;
  extension: string;
  eta: string;
  amount: number;
  actual_amount: number;
  found_files: string;
  status: CpDataMonitorItemStatuses;
  source: string;
  last_check_time: string;
}

export interface ValueMappingCondition {
  id: number;
  condition_field: string;
  condition_value: string;
  comparison_type: string;
}

export interface RawDataModuleConfig {
  param_name: string;
  param_value: string;
  id?: number;
}

export interface MappingReport {
  raw_data_report_id: number;
  report_id: number;
  name: string;
  state: string;
  type: string;
  mode: string;
}

export enum BreakTypes {
  TRADE = 'trades',
  TRANSACTION = 'transactions',
  POSITION = 'positions',
  BALANCE = 'balances',
}

export enum ComparisonType {
  EXACT = 'exact',
  CONTAINS = 'contains',
  ICONTAINS = 'icontains',
  REGEX = 'regex',
  NOTCONTAINS = 'notcontains',
  NOTICONTAINS = 'noticontains',
  ISEMPTY = 'isempty',
  ISNOTEMPTY = 'isnotempty',
}

export enum StandardValueType {
  STRING = 'string',
  INT = 'int',
  NUMERIC = 'numeric',
}

export enum BreakReportCommentPriorityAndSeverity {
  LOW = 'LOW',
  MIDDLE = 'MIDDLE',
  HIGH = 'HIGH',
}
export enum TablesWithBreakReport {
  CPTRANSACTION = 'cptransaction',
  FTBO = 'ftbo',
  CPTRADES = 'cptrades',
  CTRADES = 'ctrades',
}

export interface FieldMappingCondition {
  condition_field: string;
  condition_value: string;
  comparison_type: ComparisonType;
}
export interface FieldMapping {
  cp_field: string;
  mapping_field: string;
  mapping_type: string;
  skip: string;
  date_format: string;
  condition_operations: string;
  conditions: FieldMappingCondition[];
}

export interface FileMonitorAdditionalFilters {
  date: string;
  cpFilter: string;
  leFilter: string;
}

export enum CpDataMonitorItemStatuses {
  FILE_NOT_FOUND = 'File Not Found',
  FILE_NOT_FOUND_NOTIFIED = 'File Not Found: Notified',
  COMPLETED = 'Completed',
  HOLIDAY = 'Holiday',
  NOT_READY = 'Not ready',
  WAITING = 'Waiting',
  IN_PROGRESS = 'In progress',
  // for cp statuses
  // TODO: extract in another enum
  DONE = 'Done',
  FAILED = 'Failed',
  NOT_STARTED = 'Not started',
  HAVE_NOT_STATUS = 'Have not status',
}

export interface CpDataMonitorItem {
  counterparty_name: string;
  legal_entity: string;
  receivedStatus: CpDataMonitorItemStatuses;
  extractedStatus: CpDataMonitorItemStatuses;
  verifiedStatus: CpDataMonitorItemStatuses;
}
