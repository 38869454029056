import dayjs from 'dayjs';
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Select, Button, InputDatePicker } from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import { DEFAULT_FILTER_VALUES } from 'constants/tables';
import { useAppSelector, usePropSelector } from 'hooks/redux';
import { FetchPositionReconUnmappedPayload } from 'services/recon';
import {
  cpListByEntitySelector,
  newLegalEntityNamesForBroker,
} from 'store/reducers/commonReducer';
import { getSelectOptions } from 'utils/getSelectOptions';

interface IProps {
  onSearch: (payload: FetchPositionReconUnmappedPayload) => void;
}

const defaultMode = 'Client-CP';
const defaultLe = 'XNT';
const modeOptions = getSelectOptions([defaultMode]);
const SearchForm: FC<IProps> = ({ onSearch }) => {
  const leNames = useAppSelector(newLegalEntityNamesForBroker);

  const leOptions = useMemo(() => getSelectOptions(leNames), [leNames]);

  const [reportDate, setReportDate] = useState<Date | null>(
    dayjs().subtract(1, 'day').toDate(),
  );
  const [cp, setCp] = useState('');
  const [le, setLe] = useState(defaultLe);

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (le as string) ?? DEFAULT_FILTER_VALUES.le,
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity?.map((item) => item.name) ?? []),
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && cp) {
      setCp(cpOptions[0].value);
    }
  }, [cpOptions]);

  const handleChangeDate = (date: Date | null) => {
    setReportDate(date);
  };
  const handleSetCounterparty = (event: ChangeEvent<HTMLInputElement>) => {
    setCp(event.target.value);
  };
  const handleSetLe = (event: ChangeEvent<HTMLInputElement>) => {
    setLe(event.target.value);
  };

  const handleSearch = () => {
    onSearch({
      counterparty: cp,
      mode: defaultMode,
      legal_entity: le,
      report_date: reportDate ? dayjs(reportDate).format(DATE_FORMAT) : '',
    });
  };

  return (
    <>
      <div className="mui-row text-left">
        <div className="mui-col-md-12">
          <label>Search positions</label>
        </div>
      </div>
      <div className="mui-row">
        <div className="mui-col-md-2">
          <InputDatePicker
            className="mb-3"
            locale={defaultLocale}
            onChange={handleChangeDate}
            inputProps={{
              fullWidth: true,
              label: 'Report date',
            }}
            selected={reportDate}
          />
        </div>
        <div className="mui-col-md-2">
          <Select
            label="Legal Entity"
            className="mb-3"
            fullWidth
            value={le}
            onChange={handleSetLe}
            options={leOptions}
          />
        </div>
        <div className="mui-col-md-2">
          <Select
            label="Counterparty"
            className="mb-3"
            fullWidth
            value={cp}
            onChange={handleSetCounterparty}
            options={cpOptions}
          />
        </div>
        <div className="mui-col-md-2">
          <Select
            label="Mode"
            className="mb-3"
            fullWidth
            options={modeOptions}
            value={defaultMode}
            disabled
          />
        </div>
        <div className="mui-col-md-2">
          <Button fullWidth onClick={handleSearch}>
            Search UnMapped
          </Button>
        </div>
      </div>
    </>
  );
};

export default SearchForm;
