import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import {
  Checkbox,
  InputDatePicker,
  Button,
  Panel,
  Notification,
  CenteredLoader,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import {
  getScripts,
  runScripts,
  RunScriptsResponse,
  RunScriptsPayload,
} from 'services/reconRunner';

import { RunScriptPageProps, AllScriptsProps } from './types';

import './style.css';

export const RunScriptPage: FC<RunScriptPageProps> = (props) => {
  const { isTradeRecon, group, hasDate } = props;

  const [allScripts, setAllScripts] = useState<any[] | []>([]);
  const [selectedScripts, setSelectedScripts] = useState<string[] | []>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [runNotifications, setRunNotifications] = useState<
    RunScriptsResponse[]
  >([]);

  const fetchScripts = () => {
    setIsLoading(true);
    getScripts(group)
      .then((json) => {
        setAllScripts(json);
        setIsLoading(false);
      })
      .catch(() => setIsError(true));
  };

  useEffect(() => {
    fetchScripts();
  }, []);

  useEffect(() => fetchScripts(), [group]);

  const onDateSelect = (dateSelected: Date | null) => {
    if (dateSelected) {
      setSelectedDate(dateSelected);
    }
  };

  const addSelectedScript = (name: string) => {
    setSelectedScripts([...selectedScripts, name]);
  };

  const removeSelectedScript = (name: string) => {
    const newScriptsList = selectedScripts.filter((value) => value !== name);
    setSelectedScripts(newScriptsList);
  };

  const scriptTicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkbox = e.target;
    const scriptName = checkbox.value.slice('runner-'.length);
    let composeName = scriptName;

    // eslint-disable-next-line no-restricted-syntax
    for (const sc of allScripts) {
      if (sc.name === scriptName) {
        composeName = sc.compose_name;
      }
    }
    /* eslint-enable */
    if (checkbox.checked) {
      addSelectedScript(composeName);
      return;
    }
    removeSelectedScript(composeName);
  };

  const makeScriptsTable = () => {
    const rows: JSX.Element[] = [];

    const columnsInRow = 2;
    const onChange = scriptTicked;

    const byLE: Record<string, any> = {};
    const unknown = 'unknown';

    allScripts.forEach((s: AllScriptsProps) => {
      const le = s.related_legal_entity ? s.related_legal_entity : unknown;
      let another = byLE[le];
      if (another === undefined) {
        another = [];
      }
      another.push(s);
      byLE[le] = another;
    });

    const toRowWrapper = () => {
      let columns: JSX.Element[] = [];
      return function toRow(
        value: AllScriptsProps,
        index: number,
        array: AllScriptsProps[],
      ) {
        const elID = `runner-${value.name}`;

        if (columns.length > columnsInRow) {
          rows.push(<tr>{columns}</tr>);
          columns = [];
        }

        let badge = null;

        if (value.current_state === 'failed') {
          badge = <span className="badge badge-danger ml-1">failed</span>;
        }
        if (value.current_state === 'running') {
          badge = <span className="badge badge-primary ml-1">running</span>;
        }
        if (value.current_state === 'success') {
          badge = <span className="badge badge-success ml-1">success</span>;
        }
        if (value.current_state === 'data not found') {
          badge = (
            <span className="badge badge-warning ml-1">data not found</span>
          );
        }

        const checked = !!selectedScripts.find(
          (item) => item === value.compose_name,
        );
        columns.push(
          <td>
            <Checkbox
              value={elID}
              label={value.name}
              checked={checked}
              onChange={onChange}
            />
            {badge}
          </td>,
        );

        if (index === array.length - 1) {
          rows.push(<tr>{columns}</tr>);
        }
      };
    };
    Object.keys(byLE).forEach((k) => {
      if (byLE[k].length === 0) {
        return;
      }
      rows.push(
        <tr>
          <td colSpan={6}>
            <div className="HeadsTable">{k.toUpperCase()}</div>
          </td>
        </tr>,
      );

      const f = toRowWrapper();
      byLE[k].forEach(f);
    });

    return rows;
  };

  const addRunNotification = (respJson: RunScriptsResponse) => {
    const tempRespJson = respJson;
    const rawScriptsNames = respJson.scripts;
    const formattedScriptNames = [];
    /* eslint-disable */
    for (const rn of rawScriptsNames) {
      let formattedName = rn;
      for (const script of allScripts) {
        if (script.name === rn) {
          formattedName = script.name;
          break;
        }
      }
      formattedScriptNames.push(formattedName);
    }
    /* eslint-enable */

    tempRespJson.scripts = formattedScriptNames;
    const notifications = [...runNotifications, tempRespJson];
    setRunNotifications(notifications);
  };

  const fireRunScripts = (useBoDrive: boolean, onlyPost: boolean) => {
    const payload: RunScriptsPayload = {
      scripts: selectedScripts,
      force_run: true,
      use_bo_drive: useBoDrive,
    };

    if (selectedDate) {
      payload.date = dayjs(selectedDate).format(DATE_FORMAT);
    }

    const selectedScriptsString = selectedScripts.join(', ');
    const scriptOrScripts = selectedScripts?.length > 1 ? 'scripts' : 'script';

    runScripts(payload, onlyPost)
      .then((json) => {
        addRunNotification(json);

        Notification.success({
          title: `${selectedScriptsString} - ${scriptOrScripts} run signal has been sent`,
        });
      })
      .catch((e) => {
        addRunNotification({
          status: 'error',
          message: e.message,
          scripts: [...selectedScripts],
        });
        Notification.success({
          title: `${selectedScriptsString} - ${scriptOrScripts} start signal not sent`,
        });
      });
  };

  const onRunReconciliationClick = () => {
    fireRunScripts(false, true);
  };

  const onRunParsingAndReconciliationClick = () => {
    fireRunScripts(false, false);
  };

  const onRunParsingAndReconciliationFromBoDrive = () => {
    fireRunScripts(true, false);
  };

  const onSimpleRunClick = () => {
    fireRunScripts(false, false);
  };

  const getButtonGroup = () => {
    const isDisabledButton = selectedScripts.length === 0 || false;

    if (!isTradeRecon) {
      return (
        <Button disabled={isDisabledButton} onClick={onSimpleRunClick}>
          Run
        </Button>
      );
    }

    return (
      <>
        <Button
          className="CustomButton"
          disabled={isDisabledButton}
          onClick={onRunParsingAndReconciliationClick}
        >
          Run Parsing & Recon
        </Button>
        <Button
          className="CustomButton"
          disabled={isDisabledButton}
          onClick={onRunParsingAndReconciliationFromBoDrive}
        >
          Run Parsing & Recon (BO Drive)
        </Button>
        <Button
          className="CustomButton"
          disabled={isDisabledButton}
          onClick={onRunReconciliationClick}
        >
          Run Recon
        </Button>
      </>
    );
  };

  let container = (
    <form className="container-fluid">
      <Panel className="MarginBottom">
        <table className="table text-left BorderNone">
          <tbody>{makeScriptsTable()}</tbody>
        </table>
      </Panel>
      <div className="d-flex justify-content-center align-items-center">
        {getButtonGroup()}
      </div>
    </form>
  );

  const dateSelector = hasDate ? (
    <InputDatePicker
      locale={defaultLocale}
      onChange={onDateSelect}
      inputProps={{
        label: DATE_FORMAT,
      }}
      selected={selectedDate}
    />
  ) : undefined;

  if (isLoading) {
    container = <CenteredLoader />;
  } else if (isError) {
    container = <h2>Error!</h2>;
  } else if (allScripts.length === 0) {
    container = <h2>No scripts found</h2>;
  }

  return (
    <>
      <div className="container-fluid mt-3 mb-3">
        {hasDate ? (
          <h2 className="TitleRunScript">Run scripts for date</h2>
        ) : (
          <h2>Run scripts</h2>
        )}
        {dateSelector}
      </div>
      {container}
    </>
  );
};
