import dayjs from 'dayjs';
import { useCallback, useRef } from 'react';

import { DEFAULT_FILTER_VALUES } from 'constants/tables';
import { getDefaultFilterParams } from 'utils/getDefaultFilterParams';

import { getColumns } from './utils/getColumns';

export const useManualReconcileUnion = () => {
  const filtersIsFirstMounted = useRef(false);

  const applyObject = {
    date: [dayjs().subtract(7, 'day').toDate(), dayjs().toDate()],
    counterparty: DEFAULT_FILTER_VALUES.cp,
    mode: DEFAULT_FILTER_VALUES.mode,
  };
  const filterKeys = getColumns({}).map((column) => column.accessor);
  const prepareFiltersForParams = useCallback(
    getDefaultFilterParams({
      filterKeys,
      applyObject,
      isApply: filtersIsFirstMounted,
      shouldApply: !window.location.search,
    }),
    [],
  );

  return {
    prepareFiltersForParams,
  };
};
