import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TableProps, Button, ICellValue } from 'react-ui-kit-exante';

import { initialUrl } from 'constants/app';
import { RawDataReportSetup } from 'services/recon';
import { ColumnsWithFilters, SelectOption } from 'types';

export const getRawDataTableColumns = ({
  onFilter,
  onRemove,
  cpOptions,
  leOptions,
}: ColumnsWithFilters & {
  cpOptions: SelectOption[];
  leOptions: SelectOption[];
}): TableProps<RawDataReportSetup>['columns'] => [
  {
    Header: 'ID',
    accessor: 'id',
    width: 70,
    disableFilters: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    Header: 'Module',
    accessor: 'module',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    Header: 'Cp',
    accessor: 'counterparty',
    type: 'select',
    filterOptions: cpOptions,
    onFilter,
    onRemove,
  },
  {
    Header: 'Legal Entity',
    accessor: 'legal_entity',
    type: 'select',
    filterOptions: leOptions,
    onFilter,
    onRemove,
  },
  {
    id: 'module_configs',
    Header: 'Configs',
    disableFilters: true,
    width: 220,
    Cell: ({ row }: ICellValue<RawDataReportSetup>) => {
      const [isShow, setIsShow] = useState(false);
      const handleClick = () => setIsShow(!isShow);

      return row.original.module_configs.length ? (
        <div className="text-left">
          <Button size="small" onClick={handleClick}>
            {isShow ? 'hide' : 'show'} configs
          </Button>
          {isShow && (
            <ul className="list-group">
              {row.original.module_configs?.map((item) => (
                <li key={item.param_name}>
                  <span className="font-weight-bold">{item.param_name}</span>:{' '}
                  {item.param_value}
                </li>
              ))}
              {Object.keys(row.original.file_config ?? {}).map((key) => (
                <li key={key}>
                  <span className="font-weight-bold">{key}</span>:{' '}
                  {(row.original.file_config ?? {})[key] as string}
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : (
        'no configs'
      );
    },
  },
  {
    id: 'related_mapping',
    Header: 'Mapping links',
    disableFilters: true,
    width: 250,
    Cell: ({ row }: ICellValue<RawDataReportSetup>) => {
      const [isShow, setIsShow] = useState(false);
      const history = useHistory();
      const handleMoveInCreateMapping = () => {
        history.push(
          `${initialUrl}/mapping/new-report?setup_id=${row.original.id}`,
        );
      };
      const handleClick = () => setIsShow(!isShow);

      const showButton = row.original.related_mapping.length ? (
        <Button size="small" onClick={handleClick}>
          {isShow ? 'hide' : 'show'} links
        </Button>
      ) : (
        'no links'
      );
      return (
        <div className="text-left">
          <div className="d-flex align-items-center">
            <div className="mr-2">{showButton}</div>
            <Button size="small" onClick={handleMoveInCreateMapping}>
              Create mapping
            </Button>
          </div>
          {isShow && (
            <ul className="list-group">
              {row.original.related_mapping?.map((item) => (
                <li key={item.report_id}>
                  id{`{${item.report_id}}`}:{' '}
                  <Link
                    title={`state: ${item.state}`}
                    to={`${initialUrl}/mapping/report/${item.report_id}`}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    },
  },
];
