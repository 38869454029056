import dayjs from 'dayjs';
import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Notification,
  Autocomplete,
  styled,
  Table,
  ISelectOption,
} from 'react-ui-kit-exante';
import { ISortBy } from 'react-ui-kit-exante/build/Components/Table/types';

import { DatePicker } from 'components';
import { defaultLocale } from 'constants/app';
import { FILTERS_DATE_FORMAT } from 'constants/date';
import { defaultPageSize, pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks/redux';
import { getChecksOfMovements, CheckOfMovement } from 'services/recon';
import { newLegalEntityNames } from 'store/reducers/commonReducer';
import { getSelectOptions } from 'utils/getSelectOptions';

import { columns, operationTypes } from './const';

export const HeadDiv = styled('div')(() => ({
  padding: '0 24px 16px 24px',
  marginTop: '16px',
}));
export const ActionsDiv = styled('div')(() => ({
  width: '250px',
  marginRight: '20px',
}));

export const ChecksOfMovements: FC = () => {
  const [date, setDate] = useState<Date | null>(new Date());
  const [le, setLe] = useState<string[]>([]);
  const [operationType, setOperationType] = useState<string[]>([]);
  const [sorting, setSorting] = useState<ISortBy[]>([]);
  const leList = useAppSelector(newLegalEntityNames);

  const entityOptions = useMemo(() => getSelectOptions(leList), [leList]);
  const operationOptions = useMemo(
    () => getSelectOptions(operationTypes),
    [operationTypes],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<CheckOfMovement[]>([]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getChecksOfMovements({
        date: dayjs(date).format(FILTERS_DATE_FORMAT),
        legalEntities: le,
        operationTypes: operationType,
        sorting,
      });
      setItems(response);
    } catch (error) {
      Notification.error({
        title: 'Get system checks error',
        description: String(error),
      });
      setItems([]);
    } finally {
      setIsLoading(false);
    }
  }, [operationType, le, date, sorting]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangeLeId = (_: SyntheticEvent, values: ISelectOption[]) => {
    setLe(values.map((item) => item.value as string));
  };
  const handleOperationType = (_: SyntheticEvent, values: ISelectOption[]) => {
    setOperationType(values.map((item) => item.value as string));
  };

  return (
    <Table
      tableId="CheckOfMovements"
      title="Integrity of Asset Flow"
      customHeadComponent={
        <HeadDiv className="d-flex">
          <ActionsDiv>
            <Autocomplete
              floating
              isMultiple
              fullWidth
              onChange={handleChangeLeId}
              options={entityOptions}
              placeholder="Legal Entity"
            />
          </ActionsDiv>
          <ActionsDiv>
            <Autocomplete
              floating
              isMultiple
              fullWidth
              options={operationOptions}
              onChange={handleOperationType}
              placeholder="Operation Type"
            />
          </ActionsDiv>
        </HeadDiv>
      }
      showTableInfo
      hasPagination
      hasFilters={false}
      locale={defaultLocale}
      pageSizes={pageSizes}
      pageSize={defaultPageSize}
      manualSortBy
      isFlexLayout
      showScrollbar
      data={items}
      columns={columns}
      isLoading={isLoading}
      onSort={(sortBy: ISortBy[]) => {
        setSorting(sortBy);
      }}
      additionalActions={[
        {
          component: <DatePicker date={date} setDate={setDate} />,
        },
      ]}
      defaultSortBy={[]}
    />
  );
};
