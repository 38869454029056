import dayjs from 'dayjs';
import { ICellValue, IColumn, Tooltip } from 'react-ui-kit-exante';

import { DATE_TIME_FORMAT } from 'constants/date';
// TODO: Replace to RUI icon
import { ReactComponent as TreePalmIcon } from 'icons/TreePalm.svg';
import {
  CpDataMonitorItemStatuses,
  Process,
  StatsRecord,
  SubRow,
  Types,
} from 'services/recon';
import { ColumnsWithFilters, SelectOption } from 'types';

import { typeLabels } from '../constants';

import { getIconByStatus } from './getIconByStatus';

const indicateShowingCondition = (
  cell: ICellValue<SubRow>,
  process: Process,
  showLog?: boolean,
) => {
  const { isExpanded, depth } = cell.row as unknown as Record<string, number>;
  const date = cell.row.original[`${process}_updated_at`];
  const link = cell.row.original[`${process}_jira_link`];
  const linkArray = (link ?? '').split('/');
  const taskNumber = linkArray[linkArray.length - 1];

  const iconStatusInfo = getIconByStatus(
    cell.row.original[process] as CpDataMonitorItemStatuses,
  );
  const getContentBlock = () => {
    if (
      showLog &&
      linkArray.length &&
      taskNumber &&
      taskNumber.includes('-') &&
      iconStatusInfo.status === 'error'
    ) {
      return (
        <a className="blur ml-1 green-link" target="__blank" href={link}>
          {taskNumber}
        </a>
      );
    }
    if (date) {
      return (
        <Tooltip title={dayjs(date).format(DATE_TIME_FORMAT)}>
          <span className="blur ml-1">{dayjs(date).format('HH:mm')}</span>
        </Tooltip>
      );
    }
    return null;
  };
  return (
    <div className="w-100 d-flex align-items-center" style={{ minHeight: 22 }}>
      {isExpanded && depth <= 1 ? null : iconStatusInfo.icon}
      {getContentBlock()}
    </div>
  );
};

const getNameCell = (cell: ICellValue<SubRow & { isHoliday?: boolean }>) => {
  if ((cell.row as unknown as Record<string, number>).depth === 2) {
    return (
      <div>
        <p className="m-0">{typeLabels[cell.row.original.subName as Types]}</p>
        <p className="m-0 blur">{cell.row.original.name}</p>
      </div>
    );
  }

  if (
    [0, 1].includes((cell.row as unknown as Record<string, number>).depth) &&
    cell.row.original.isHoliday
  ) {
    return (
      <div className="d-flex align-items-center">
        <p className="m-0 mr-1">{cell.row.original.name}</p>
        <TreePalmIcon />
      </div>
    );
  }

  return cell.row.original.name;
};
const ftpColumn: IColumn<StatsRecord> = {
  Header: 'Ftp',
  accessor: Process.Ftp,
  minWidth: 40,
  tooltip: 'off',
  Cell: (cell: ICellValue<StatsRecord>) =>
    indicateShowingCondition(cell, Process.Ftp, true),
  disableFilters: true,
  disableSortBy: true,
};
const rawColumn: IColumn<StatsRecord> = {
  Header: 'Raw',
  accessor: Process.Raw,
  minWidth: 40,
  tooltip: 'off',
  Cell: (cell: ICellValue<StatsRecord>) =>
    indicateShowingCondition(cell, Process.Raw, true),
  disableFilters: true,
  disableSortBy: true,
};
const normalizeColumn: IColumn<StatsRecord> = {
  Header: 'Normalize',
  accessor: Process.Normalize,
  minWidth: 40,
  tooltip: 'off',
  Cell: (cell: ICellValue<StatsRecord>) =>
    indicateShowingCondition(cell, Process.Normalize, true),
  disableFilters: true,
  disableSortBy: true,
};
const cashFlowColumn: IColumn<StatsRecord> = {
  Header: 'Cash flow',
  accessor: Process.Cash_flow,
  minWidth: 40,
  tooltip: 'off',
  Cell: (cell: ICellValue<StatsRecord>) =>
    indicateShowingCondition(cell, Process.Cash_flow, true),
  disableFilters: true,
  disableSortBy: true,
};
const operationTypeColumn: IColumn<StatsRecord> = {
  Header: 'Transaction type',
  accessor: Process.Operation_type,
  minWidth: 40,
  tooltip: 'off',
  Cell: (cell: ICellValue<StatsRecord>) =>
    indicateShowingCondition(cell, Process.Operation_type, true),
  disableFilters: true,
  disableSortBy: true,
};
const symbolMappingColumn: IColumn<StatsRecord> = {
  Header: 'Symbol',
  accessor: Process.Symbol_mapping,
  minWidth: 40,
  tooltip: 'off',
  Cell: (cell: ICellValue<StatsRecord>) =>
    indicateShowingCondition(cell, Process.Symbol_mapping, true),
  disableFilters: true,
  disableSortBy: true,
};
const flexColumn: IColumn<StatsRecord> = {
  Header: '',
  accessor: 'flex',
  minWidth: 300,
  Cell: () => '',
  disableFilters: true,
  disableSortBy: true,
};
export const getColumns = (
  isOtherTab: boolean,
  isPositionTab: boolean,
): IColumn<StatsRecord>[] => {
  let processColumns: IColumn<StatsRecord>[] = [];
  if (isOtherTab) {
    processColumns = [ftpColumn, rawColumn, flexColumn];
  }
  if (isPositionTab) {
    processColumns = [
      {
        Header: 'Data',
        columns: [ftpColumn, rawColumn, normalizeColumn],
      },
      {
        Header: 'Quality check',
        columns: [symbolMappingColumn, flexColumn],
      },
    ];
  }
  if (!isOtherTab && !isPositionTab) {
    processColumns = [
      { Header: 'Data', columns: [ftpColumn, rawColumn, normalizeColumn] },
      {
        Header: 'Quality check',
        columns: [cashFlowColumn, operationTypeColumn, symbolMappingColumn],
      },
    ];
  }
  const columns = [
    {
      Header: '',
      accessor: 'name',
      minWidth: 120,
      Cell: getNameCell,
      disableFilters: true,
      disableSortBy: true,
      className: 'pr-2 d-block text-left',
    },
    ...processColumns,
  ];

  return isOtherTab
    ? columns.filter((item) => item.accessor !== Process.Normalize)
    : columns;
};

export const getAdditionalFilters = ({
  onFilter,
  onRemove,
  leTypeOptions,
}: ColumnsWithFilters & { leTypeOptions: SelectOption[] }) => {
  const columns: IColumn<Record<string, unknown>>[] = [
    {
      Header: 'Legal entity type',
      accessor: 'legal_entity_type',
      type: 'multiSelect',
      onFilter,
      onRemove,
      filterOptions: leTypeOptions,
    },
  ];
  return columns;
};
