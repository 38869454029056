import { ResponseWithPagination } from '../../types';
import { getUrlFromPayload } from '../../utils';
import { ReconApiService } from '../api';

import {
  CreateRightUsersPayload,
  GetRightUsersPayload,
  ReconUser,
  ReconUserItemList,
  RightUser,
} from './types';

export async function addRightUser(payload: CreateRightUsersPayload) {
  return ReconApiService.postResource<CreateRightUsersPayload, RightUser>(
    'user/recon-user-rights/',
    payload,
  );
}

export async function getRightUsers(payload: GetRightUsersPayload) {
  return ReconApiService.getResource<RightUser[]>(
    getUrlFromPayload('user/recon-user-rights/?', {
      report_date: `${payload.start_date}:${payload.end_date}`,
      role: 'recon',
    }),
  );
}

export async function getCurrentUser() {
  return ReconApiService.getResource<ReconUser>('user/get-username/');
}

export async function getReconUserList(params: { all?: boolean }) {
  return ReconApiService.getResource<ResponseWithPagination<ReconUserItemList>>(
    getUrlFromPayload('user/recon-user/?limit=1000&', params),
  );
}
