import cn from 'classnames';
import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import {
  Notification,
  Table,
  Modal,
  Loader,
  IRow,
  ICellValue,
  Button,
  InputDatePicker,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import { useSelectRows } from 'hooks';
import {
  getScriptOutput,
  getScriptsStatus,
  Script,
} from 'services/reconRunner';
import { getSelectOptions } from 'utils';

import { theme } from '../../theme';

import classes from './ScriptsStatuses.module.css';
import { useSelectedRows } from './hooks';
import { ModalParams } from './types';
import { getColumns } from './utils/getColumns';

export const ScriptsStatusesPage: FC = () => {
  const [scriptStatuses, setScriptStatuses] = useState<Script[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [modalParams, setModalParams] = useState<ModalParams>({
    title: '',
    isShow: false,
    scriptName: '',
    std: '',
  });
  const [scriptResult, setScriptResult] = useState('');
  const [scriptResultIsLoading, setScriptResultIsLoading] = useState(false);

  const handleChangeModalParams = useCallback(
    (params: ModalParams) => {
      setModalParams(params);
    },
    [setModalParams],
  );
  const handleClose = useCallback(() => {
    setModalParams({ ...modalParams, isShow: false });
  }, [modalParams, setModalParams]);

  const getStatuses = async () => {
    setIsLoading(true);
    try {
      const response = await getScriptsStatus();
      setScriptStatuses(
        response.map((item, index) => ({ ...item, id: index + 1 })),
      );
    } catch (error) {
      Notification.error({
        title: 'Fetch script statuses error',
        description: String(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { selectedRows, selectedIds, handleSelectRow, handleUnSelectAllRows } =
    useSelectRows(scriptStatuses, 5);

  const {
    scriptsIsRunning,
    handleRunScripts,
    handleCloseRunModal,
    runModalIsVisible,
    handleOpenRunModal,
    dateField,
    handleChangeDate,
  } = useSelectedRows(getStatuses, selectedRows, handleUnSelectAllRows);

  const handleFetchModalData = async () => {
    setScriptResultIsLoading(true);
    try {
      const response = await getScriptOutput(
        modalParams.scriptName,
        modalParams.std,
      );
      setScriptResult(response.result);
    } catch (error) {
      Notification.error({
        title: `Load text for scriptID: ${modalParams.scriptName} error`,
        description: String(error),
      });
    } finally {
      setScriptResultIsLoading(false);
    }
  };

  useEffect(() => {
    if (modalParams.std && modalParams.scriptName) {
      handleFetchModalData();
    }
  }, [modalParams]);

  const columns = useMemo(
    () =>
      getColumns({
        rows: scriptStatuses,
        selectedIds,
        onSelectRow: handleSelectRow,
        onChangeModalParams: handleChangeModalParams,
        groups: getSelectOptions(
          scriptStatuses.reduce(
            (acc: string[], item) =>
              acc.includes(item.group) ? acc : [...acc, item.group ?? ''],
            [],
          ),
        ),
        scriptTypes: getSelectOptions(
          scriptStatuses.reduce(
            (acc: string[], item) =>
              acc.includes(item.script_type)
                ? acc
                : [...acc, item.script_type ?? ''],
            [],
          ),
        ),
      }),
    [handleChangeModalParams, scriptStatuses, selectedIds, handleSelectRow],
  );

  useEffect(() => {
    getStatuses();
  }, []);

  const getRowProps = useCallback((row: IRow<Script>) => {
    let background = theme?.color.table.bg.basic1;
    if (row.original.current_state === 'success') {
      background = theme?.color.table.bg.source;
    } else if (row.original.current_state === 'failed') {
      background = theme?.color.table.bg.radical;
    }
    return {
      style: {
        background,
      },
    };
  }, []);
  const getCellProps = useCallback(
    (cell: ICellValue<Script>) =>
      cell.column.id === 'actions'
        ? {
            style: {
              ...getRowProps(cell.row).style,
              boxShadow: `0 0 0 ${theme?.color.table.bg.basic2}`,
            },
          }
        : {},
    [getRowProps],
  );

  const additionalActions = [
    {
      children: scriptsIsRunning ? (
        <Loader />
      ) : (
        <Button size="small" color="primary" disabled={!selectedRows.length}>
          Run
        </Button>
      ),
      onClick: handleOpenRunModal,
      title: 'open',
    },
  ];

  return (
    <div className={cn('mui-container-fluid', classes.Container)}>
      <div className="mui-row">
        <div className="mui-col-md-12">
          <Table
            tableId="ScriptsStatuses"
            title="Scripts statuses"
            showTableInfo
            isFlexLayout
            hasPagination
            showScrollbar
            hasFilters
            locale={defaultLocale}
            columns={columns}
            isLoading={isLoading}
            data={scriptStatuses}
            pageSizes={pageSizes}
            defaultSortBy={[{ id: 'last_run_time', desc: true }]}
            additionalActions={additionalActions}
            pageSize={30}
            getRowProps={getRowProps}
            getCellProps={getCellProps}
          />
          <Modal
            title={modalParams.title}
            onClose={handleClose}
            isOpened={modalParams.isShow}
          >
            <div className="d-flex justify-content-center w-100">
              {scriptResultIsLoading ? <Loader size="l" /> : scriptResult}
            </div>
          </Modal>
          <Modal
            title={`Run ${selectedRows.length} scripts`}
            onClose={handleCloseRunModal}
            isOpened={runModalIsVisible}
          >
            <InputDatePicker
              locale={defaultLocale}
              onChange={handleChangeDate}
              inputProps={{
                fullWidth: true,
                label: 'Date',
              }}
              selected={dateField}
            />
            <Button onClick={handleRunScripts}>Run scripts</Button>
          </Modal>
        </div>
      </div>
    </div>
  );
};
