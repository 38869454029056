import dayjs from 'dayjs';
import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Notification,
  Autocomplete,
  styled,
  Table,
  ISelectOption,
  ButtonDatePicker,
  IRow,
  ICellValue,
  IOnFetchArguments,
  useTableData,
} from 'react-ui-kit-exante';
import { ISortBy } from 'react-ui-kit-exante/build/Components/Table/types';

import { defaultLocale } from 'constants/app';
import { FILTERS_DATE_FORMAT } from 'constants/date';
import { defaultPageSize, pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks/redux';
import { getTradeMonitor, TradeReconMonitorItem } from 'services/recon';
import {
  newCounterPartyNames,
  newLegalEntityNames,
} from 'store/reducers/commonReducer';
import { theme } from 'theme';
import { getSelectOptions } from 'utils/getSelectOptions';

import { getWorkYesterday } from '../../utils';

import { getColumns } from './utils';

export const HeadDiv = styled('div')(() => ({
  padding: '0 24px 16px 24px',
  marginTop: '16px',
}));
export const ActionsDiv = styled('div')(() => ({
  width: '250px',
  marginRight: '20px',
}));

const workYesterday = new Date(getWorkYesterday());
export const TradeReconMonitor: FC = () => {
  const [dates, setDates] = useState<(Date | null)[]>([
    workYesterday,
    workYesterday,
  ]);
  const [le, setLe] = useState<string[]>([]);
  const [cp, setCp] = useState<string[]>([]);
  const [sorting, setSorting] = useState<ISortBy[]>([]);
  const modeList = useAppSelector((state) => state.modeList);
  const leList = useAppSelector(newLegalEntityNames);
  const cpList = useAppSelector(newCounterPartyNames);

  const entityOptions = useMemo(() => getSelectOptions(leList), [leList]);
  const cpOptions = useMemo(() => getSelectOptions(cpList), [cpList]);
  const modeOptions = useMemo(
    () => getSelectOptions(modeList, 'name', 'name'),
    [modeList],
  );

  const fetchItems = useCallback(
    async (payload: IOnFetchArguments) => {
      if (dates.filter((item) => !!item).length !== 2) {
        return [];
      }
      try {
        return await getTradeMonitor({
          dates: [
            dayjs(dates[0]).format(FILTERS_DATE_FORMAT),
            dayjs(dates[1]).format(FILTERS_DATE_FORMAT),
          ],
          legalEntities: le,
          counterparties: cp,
          sorting,
          mode: payload.filtersParams?.mode as string,
        });
      } catch (error) {
        Notification.error({
          title: 'Get system checks error',
          description: String(error),
        });
        return [];
      }
    },
    [cp, le, dates, sorting],
  );

  const tableData = useMemo(
    () => ({
      tableId: 'TradeReconMonitor',
      data: { onFetch: fetchItems },
      filters: {
        getDefaultFilters: () => ({
          mode: 'Client-CP',
        }),
      },
    }),
    [fetchItems],
  );

  const { data, setFilter, removeFilter, resetFilters, filters, isLoading } =
    useTableData<TradeReconMonitorItem[]>(tableData);

  const handleChangeLeId = (_: SyntheticEvent, values: ISelectOption[]) => {
    setLe(values.map((item) => item.value as string));
  };
  const handleChangeCp = (_: SyntheticEvent, values: ISelectOption[]) => {
    setCp(values.map((item) => item.value as string));
  };

  const datesBlock = useMemo(() => {
    const firstDate = dayjs(dates[0]);
    const secondDate = dayjs(dates[1]);
    return `${firstDate.format(FILTERS_DATE_FORMAT)} - ${
      secondDate.isValid()
        ? secondDate.format(FILTERS_DATE_FORMAT)
        : firstDate.format(FILTERS_DATE_FORMAT)
    }`;
  }, [dates]);

  const onChangeDate = (value: Date | [Date | null, Date | null] | null) => {
    if (Array.isArray(value)) {
      setDates(value);
    }
  };

  const getRowProps = useCallback((row: IRow<TradeReconMonitorItem>) => {
    let background = theme?.color.table.bg.radical;

    const {
      bo_not_reconciled: boNotReconciled,
      cp_not_reconciled: cpNotReconciled,
    } = row.original;
    if (!boNotReconciled && !cpNotReconciled) {
      background = theme?.color.table.bg.source;
    } else if (!boNotReconciled || !cpNotReconciled) {
      background = theme?.color.bg.warning;
    }
    return {
      style: {
        background,
      },
    };
  }, []);

  const getCellProps = useCallback(
    (cell: ICellValue<TradeReconMonitorItem>) => getRowProps(cell.row),
    [],
  );

  const columns = useMemo(
    () => getColumns({ modeOptions, onFilter: setFilter, onRemove: () => {} }),
    [modeOptions, setFilter, removeFilter],
  );
  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  return (
    <Table
      tableId="TradeReconMonitor"
      title="Trade Recon Monitor"
      data={data ?? []}
      customHeadComponent={
        <HeadDiv className="d-flex">
          <ActionsDiv>
            <Autocomplete
              floating
              isMultiple
              fullWidth
              onChange={handleChangeLeId}
              options={entityOptions}
              placeholder="Legal Entity"
            />
          </ActionsDiv>
          <ActionsDiv>
            <Autocomplete
              floating
              isMultiple
              fullWidth
              options={cpOptions}
              onChange={handleChangeCp}
              placeholder="Counterparty"
            />
          </ActionsDiv>
        </HeadDiv>
      }
      getRowProps={getRowProps}
      getCellProps={getCellProps}
      showTableInfo
      hasPagination
      hasFilters
      filteringProps={filteringProps}
      filtersExpanded={false}
      locale={defaultLocale}
      pageSizes={pageSizes}
      pageSize={defaultPageSize}
      manualSortBy
      isFlexLayout
      showScrollbar
      columns={columns}
      isLoading={isLoading}
      onSort={(sortBy: ISortBy[]) => {
        setSorting(sortBy);
      }}
      additionalActions={[
        {
          component: (
            <div className="d-flex align-items-end">
              <ButtonDatePicker
                selectsRange
                onChange={onChangeDate}
                startDate={dates[0]}
                endDate={dates[1]}
              />
              <div className="ml-1">{datesBlock}</div>
            </div>
          ),
        },
      ]}
      defaultSortBy={[]}
    />
  );
};
