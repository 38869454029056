import React, { useCallback, useState } from 'react';

import { DEFAULT_LOCALE } from '../../../../../consts';
import { DefaultThemeProvider } from '../../../../../theme';
import { Skeleton } from '../../../../Skeleton';
import { DatePicker } from '../DatePicker';
import { BUTTON_DATE_PICKER_TEST_ID, DEFAULT_MIN_DATE } from '../constants';

import { DatePickerButton } from './DatePickerButton';
import { IButtonDatePickerProps } from './types';

export const ButtonDatePicker = <WithRange extends boolean = false>({
  onCalendarOpen,
  onCalendarClose,
  onToday,
  onClear,
  minDate = DEFAULT_MIN_DATE,
  locale = DEFAULT_LOCALE,
  iconClassName,
  disabled,
  showSkeleton = false,
  skeletonSize = 48,
  ...rest
}: IButtonDatePickerProps<WithRange>) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    onCalendarOpen?.();
    setOpen(true);
  }, [onCalendarOpen]);

  const handleClose = useCallback(() => {
    onCalendarClose?.();
    setOpen(false);
  }, [onCalendarClose]);

  const handleToday = () => {
    setOpen(false);
    onToday?.();
  };

  const handleClear = () => {
    setOpen(false);
    onClear?.();
  };

  const id = open ? BUTTON_DATE_PICKER_TEST_ID : undefined;

  return (
    <DefaultThemeProvider>
      {showSkeleton ? (
        <Skeleton height={skeletonSize} width={skeletonSize} />
      ) : (
        <DatePicker<WithRange>
          open={open}
          locale={locale}
          minDate={minDate}
          onCalendarOpen={handleOpen}
          onCalendarClose={handleClose}
          customInput={
            <DatePickerButton
              active={open}
              iconClassName={iconClassName || ''}
              id={id}
            />
          }
          onInputClick={handleOpen}
          onToday={handleToday}
          onClear={handleClear}
          disabled={disabled}
          {...rest}
        />
      )}
    </DefaultThemeProvider>
  );
};
