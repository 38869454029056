import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { editColumnWidth, minColumnWidth as minWidth } from 'constants/tables';
import { CpAndCTrade } from 'services/recon';
import { ColumnsWithFilters, SelectOption } from 'types';
import { formatNumber } from 'utils';

type ColumnsPayloadType = ColumnsWithFilters & {
  entityOptions: SelectOption[];
  cpOptions: SelectOption[];
};

export const getColumns = ({
  onFilter,
  onRemove,
  entityOptions,
  cpOptions,
}: ColumnsPayloadType): IColumn<CpAndCTrade>[] => [
  {
    Header: 'Cp',
    accessor: 'cpname',
    minWidth,
    type: 'select',
    filterOptions: cpOptions,
    onFilter,
    onRemove,
  },
  {
    Header: 'Entity',
    accessor: 'legal_entity_name',
    minWidth,
    type: 'select',
    filterOptions: entityOptions,
    onFilter,
    onRemove,
  },
  {
    Header: 'Side',
    accessor: 'side',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Date',
    accessor: 'date',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Bo symbol',
    accessor: 'bo_symbol',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Cp symbol',
    accessor: 'cp_symbol',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Price',
    accessor: 'price',
    minWidth,
    Cell: (cell: ICellValue<CpAndCTrade>) => (
      <div>{formatNumber(cell.row.original.price)}</div>
    ),
    className: 'justify-content-end',
    disableFilters: true,
  },
  {
    Header: 'Qty',
    accessor: 'qty',
    formatting: 'number',
    minWidth,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    disableFilters: true,
  },
  {
    Header: 'Value in EUR',
    accessor: 'value',
    minWidth,
    Cell: (cell: ICellValue<CpAndCTrade>) => (
      <div>{formatNumber(cell.row.original.value)}</div>
    ),
    className: 'justify-content-end',
    disableFilters: true,
  },
  {
    Header: 'Ccy',
    accessor: 'ccy',
    minWidth,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    disableFilters: true,
  },
  {
    Header: 'Assignee',
    accessor: 'assignee',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Category',
    accessor: 'break_report_category',
    minWidth,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    disableFilters: true,
  },
  {
    Header: 'Value date',
    accessor: 'value_date',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Isin',
    accessor: 'isin',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Account',
    accessor: 'account',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Comment',
    accessor: 'break_comment',
    width: editColumnWidth,
    minWidth,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    disableFilters: true,
  },
];
