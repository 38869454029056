import dayjs from 'dayjs';
import { Button, IColumn, Notification, ICellValue } from 'react-ui-kit-exante';

import { FILTERS_DATE_FORMAT } from 'constants/date';
import {
  editColumnWidth,
  minColumnWidth as minWidth,
  unknownCpOption,
} from 'constants/tables';
import { SIDE, Transaction, deleteReconcileTransactions } from 'services/recon';
import { ColumnsWithFilters, SelectOption } from 'types';
import {
  getSelectOptions,
  getCellCheckbox,
  getGeneralCheckbox,
  getNumberWithCommas,
} from 'utils';

import { GetColumnsPayload } from './types';

const minWidths = {
  id: 60,
  side: 70,
  ccy: 80,
};
export const getTransactionsTableColumns = (
  payload: (GetColumnsPayload & ColumnsWithFilters) | Record<string, any>,
  isReconciled = false,
) => {
  const {
    transactions,
    selectedTransactionsIds,
    onCheckTransaction,
    onCheckAllTransactionOnPage,
    onUnCheckAllTransactionOnPage,
    breakCategoryOptions,
    operationTypes,
    ccyList,
    cpOptions,
    userNames,
    modeNames,
    onFilter,
    onRemove,
    reloadData,
    categories,
    categoriesByTypes,
    updateData,
  } = payload;
  const cpOptionsCompleted = [unknownCpOption, ...(cpOptions ?? [])];
  const modeOptions = getSelectOptions(modeNames);
  const ccyOptions = getSelectOptions(ccyList);
  const userOptions = getSelectOptions(userNames);
  const categoryOptions = getSelectOptions(categories, 'name', 'name');
  const operationOptions = getSelectOptions(operationTypes, 'name', 'name');

  const sideOptions = [
    { label: 'Our', value: 'our' },
    { label: 'Their', value: 'their' },
  ];

  const columns: IColumn<Transaction>[] = [
    {
      accessor: 'id',
      width: 70,
      minWidth: minWidths.id,
      Header: () =>
        Array.isArray(transactions)
          ? getGeneralCheckbox(
              transactions,
              selectedTransactionsIds,
              onCheckAllTransactionOnPage,
              onUnCheckAllTransactionOnPage,
              (transaction) => transaction.id,
            )
          : '',

      Cell: ({ row }: ICellValue<Transaction>) =>
        Array.isArray(transactions)
          ? getCellCheckbox(
              row.values.id,
              transactions,
              selectedTransactionsIds,
              onCheckTransaction,
              (transaction) => transaction.id,
            )
          : '',
      disableSortBy: true,
      disableFilters: true,
      className: 'd-block',
    },
    {
      Header: 'Recon group',
      accessor: 'recon_group_id',
      minWidth,
      Cell: ({ row }: ICellValue<Transaction>) => {
        const groupId = row.original.recon_group_id;
        if (!groupId) {
          return null;
        }
        const handleUngroup = async () => {
          try {
            await deleteReconcileTransactions({ group_id: groupId });
            Notification.success({
              title: `Group:${groupId} success unreconciled`,
            });
            reloadData();
          } catch (_) {
            Notification.error({ title: 'Unreconcile error' });
          }
        };
        return (
          <div>
            <div>
              <span>{groupId}</span>
            </div>
            <Button color="red" size="small" onClick={handleUngroup}>
              Unrecon
            </Button>
          </div>
        );
      },
      disableSortBy: true,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Reconciliation Type',
      accessor: 'reconciliation_type',
      minWidth: 250,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Report date',
      accessor: 'reportdate',
      width: editColumnWidth,
      minWidth,
      editParams: {
        editable: true,
        inputType: 'date',
        onChange: (value: string, values: Transaction) => {
          updateData(
            'reportdate',
            dayjs(value).format(FILTERS_DATE_FORMAT),
            values,
          );
        },
      },
      useLocale: false,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Value date',
      accessor: 'value_date',
      width: editColumnWidth,
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Side',
      accessor: 'side',
      width: 90,
      minWidth: minWidths.side,
      type: 'select',
      filterOptions: sideOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'Assigned',
      accessor: 'assignee',
      minWidth,
      type: 'select',
      filterOptions: userOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (cell: ICellValue<Transaction>) => (
        <div>
          {getNumberWithCommas(
            cell.row.original.amount ? Number(cell.row.original.amount) : 0,
          )}
        </div>
      ),
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'CCY',
      accessor: 'ccy',
      width: 90,
      minWidth: minWidths.ccy,
      type: 'multiSelect',
      filterOptions: ccyOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'Transaction id',
      accessor: 'transaction_id',
      width: 120,
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Operation type',
      accessor: 'operationtype',
      width: editColumnWidth,
      minWidth,
      editParams: {
        editable: true,
        inputType: 'select',
        options: operationOptions,
        onChange: (value: string, values: Transaction) => {
          updateData('operationtype', value, values);
        },
      },
      filterOptions: operationOptions,
      type: 'multiSelect',
      onFilter,
      onRemove,
    },
    {
      Header: 'Сlient id',
      accessor: 'client_account_id',
      width: 100,
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Category',
      accessor: 'category',
      width: editColumnWidth,
      minWidth,
      editParams: {
        editable: (cell: ICellValue<Transaction>) =>
          cell.row.original.side === SIDE.THEIR,
        inputType: 'select',
        options: (value: Transaction) =>
          categoriesByTypes[value.operationtype] ?? [],
        onChange: (value: string, values: Transaction) => {
          updateData('category', value, values);
        },
      },
      filterOptions: categoryOptions,
      type: 'multiSelect',
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
      width: editColumnWidth,
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Symbol type',
      accessor: 'symboltype',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Legal Entity',
      accessor: 'legal_entity_name',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'CP',
      accessor: 'cpname',
      width: editColumnWidth,
      minWidth,
      editParams: {
        editable: (cell: ICellValue<Transaction>) =>
          cell.row.original.side === SIDE.OUR,
        onChange: (value: string, values: Transaction) => {
          updateData('cpname', value, values);
        },
        inputType: 'select',
        options: cpOptionsCompleted,
      },
      disableFilters: true,
    },
    {
      Header: 'Mode',
      accessor: 'mode',
      minWidth,
      disableSortBy: true,
      filterOptions: modeOptions,
      type: 'select',
      onFilter,
      onRemove,
    },
    {
      Header: 'ISIN',
      accessor: 'isin',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Account',
      accessor: 'account',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Break category',
      accessor: 'break_report_category',
      minWidth,
      filterOptions: breakCategoryOptions,
      type: 'multiSelect',
      onFilter,
      onRemove,
    },
    {
      Header: 'Break comment',
      accessor: 'break_comment',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Comment',
      accessor: 'comment',
      minWidth,
      width: 500,
      disableFilters: true,
    },
  ];

  const forReconciled = columns.filter(
    (item) =>
      !(
        item.accessor === 'break_report_category' ||
        item.accessor === 'break_comment' ||
        item.accessor === 'id'
      ),
  );
  const forUnreconciled = columns.filter(
    (item) =>
      !(
        item.accessor === 'recon_group_id' ||
        item.accessor === 'reconciliation_type'
      ),
  );
  return isReconciled ? forReconciled : forUnreconciled;
};

export const getAdditionalColumns = ({
  onFilter,
  onRemove,
  entityOptions,
  counterPartyOptions,
  isReconciled,
}: ColumnsWithFilters & {
  entityOptions: SelectOption[];
  counterPartyOptions: SelectOption[];
  isReconciled: boolean;
}) => {
  const columns: IColumn<Record<string, unknown>>[] = [
    {
      Header: 'Legal entity',
      accessor: 'le',
      type: 'select',
      onFilter,
      onRemove,
      filterOptions: entityOptions,
    },
    {
      Header: 'Cp',
      accessor: 'counterparty',
      type: 'select',
      onFilter,
      onRemove,
      filterOptions: [unknownCpOption, ...counterPartyOptions],
    },
  ];
  return isReconciled
    ? columns
    : [
        ...columns,
        {
          Header: 'Break report filter',
          accessor: 'break_report_filter',
          type: 'checkbox',
          onFilter,
          onRemove,
        },
      ];
};
