import { FC } from 'react';

interface IProps {
  label: string;
  value: string;
}
export const InfoBlock: FC<IProps> = ({ label, value }) => {
  return (
    <div>
      <div>
        <b>{label}: </b>
      </div>
      <div>
        <p>{value || '-'}</p>
      </div>
    </div>
  );
};
