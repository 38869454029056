import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  ICellValue,
  IOnFetchArguments,
  IRow,
  ISortBy,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import {
  Transaction,
  TransactionOperationType,
  getCpBoTransactions,
} from 'services/recon';
import { calculateCountOfPages } from 'utils';

import { TransactionsTable } from '../types';
import { getTransactionsTableColumns } from '../utils';

interface Props {
  operationTypes: TransactionOperationType[];
  filters: IOnFetchArguments['filtersParams'];
  reloadUnreconciledTable: VoidFunction;
  reconciledUpdateCount: number;
  getRowProps: (val: IRow<Transaction>) => Record<string, unknown>;
  getCellProps: (val: ICellValue<Transaction>) => Record<string, unknown>;
}
export const ReconciledTable: FC<Props> = ({
  operationTypes,
  filters,
  reloadUnreconciledTable,
  reconciledUpdateCount,
  getRowProps,
  getCellProps,
}) => {
  const getReconciledTransactions = useCallback(
    (props: IOnFetchArguments) =>
      getCpBoTransactions(
        {
          ...props,
          filtersParams: { ...filters, type_of_recon: 'MANUAL' },
        },
        true,
      ),
    [filters],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getReconciledTransactions },
      pagination: { getDefaultPagination: () => ({ limit: 5, skip: 0 }) },
    }),
    [getReconciledTransactions],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    setSorting,
    fetchData,
  } = useTableData<TransactionsTable>(tableData);

  useEffect(() => {
    if (reconciledUpdateCount > 0) {
      fetchData();
    }
  }, [reconciledUpdateCount]);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );

  const reloadData = async () => {
    await fetchData();
    await reloadUnreconciledTable();
  };

  const transactionsTableColumns = useMemo(
    () =>
      getTransactionsTableColumns(
        {
          transactions: data?.transactions,
          breakCategories: [],
          operationTypes,
          cpList: [],
          ccyList: [],
          onFilter: setFilter,
          onRemove: removeFilter,
          reloadData,
        },
        true,
      ),
    [data, setFilter, removeFilter, operationTypes, filters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  return (
    <Table
      tableId="ReconciledTransactions"
      title="Reconciled transactions"
      showTableInfo
      hasPagination
      manualSortBy
      isFlexLayout
      showScrollbar
      saveColumnOrder
      data={data?.transactions ?? []}
      columns={transactionsTableColumns}
      isLoading={isLoading}
      pageSizes={pageSizes}
      locale={defaultLocale}
      hasFilters={false}
      onSort={handleSorting}
      getRowProps={getRowProps}
      getCellProps={getCellProps}
      defaultSortBy={[{ id: 'id', desc: true }]}
      serverPaginationProps={{
        pageIndex: page,
        setPage,
        pageSize: limit,
        setPageSize: setLimit,
        total: data?.pagination?.total ?? 0,
        pageCount,
      }}
    />
  );
};
