import {
  CpDataMonitorItemStatuses,
  Process,
  StatsRecord,
} from 'services/recon';

import { ByFieldNames } from '../models';

export const getConfirmIndicatingCondition =
  (byFieldName: ByFieldNames) => (row: number | string, values: StatsRecord) =>
    (byFieldName === 'counterparty' &&
      String(row).split('.').length === 1 &&
      (
        [
          ...(values.subRows?.map((item) => (item ? item[Process.Ftp] : '')) ??
            []),
          ...(values.subRows?.map((item) =>
            item ? item[Process.Normalize] : '',
          ) ?? []),
          ...(values.subRows?.map((item) => (item ? item[Process.Raw] : '')) ??
            []),
        ] as CpDataMonitorItemStatuses[]
      ).every(
        (status) =>
          ![
            CpDataMonitorItemStatuses.COMPLETED,
            CpDataMonitorItemStatuses.DONE,
            CpDataMonitorItemStatuses.HOLIDAY,
          ].includes(status),
      )) ||
    (byFieldName === 'legal_entity' &&
      String(row).split('.').length === 2 &&
      (
        [
          values[Process.Ftp],
          values[Process.Normalize],
          values[Process.Raw],
        ] as CpDataMonitorItemStatuses[]
      ).every(
        (status) =>
          ![
            CpDataMonitorItemStatuses.COMPLETED,
            CpDataMonitorItemStatuses.DONE,
            CpDataMonitorItemStatuses.HOLIDAY,
          ].includes(status),
      ));
