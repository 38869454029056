import { useState } from 'react';
import { ICellValue, Button } from 'react-ui-kit-exante';

import { ValueMappingRule } from 'services/recon';

export const GetConditionCell = ({ row }: ICellValue<ValueMappingRule>) => {
  const conditions = row.original.conditions;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [show, setShow] = useState(false);
  const handleSwitchShow = () => {
    setShow(!show);
  };
  return conditions.length ? (
    <div className="d-flex flex-column align-items-start">
      <Button size="small" onClick={handleSwitchShow}>
        {show ? 'Hide' : 'Show'} conditions
      </Button>
      {show && (
        <div>
          {conditions.map((item) => (
            <ul key={item.id} className="text-left mt-3">
              <li>condition_field: {item.condition_field}</li>
              <li>condition_value: {item.condition_value}</li>
              <li>comparison_type: {item.comparison_type}</li>
            </ul>
          ))}
        </div>
      )}
    </div>
  ) : (
    'No conditions'
  );
};
