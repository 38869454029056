import dayjs from 'dayjs';
import { sha1 } from 'object-hash';
import { useMemo, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { initialUrl } from 'constants/app';
import { createReport } from 'services/rawData';
import {
  getSetupIds,
  GetSetupIdsPayload,
  RawDataReportSetup,
} from 'services/recon';

const nowTime = dayjs().toISOString();
export function useRawDataSetups() {
  const [getSetupError, setGetSetupError] = useState<string | null>(null);
  const [foundSetup, setFoundSetup] = useState<null | RawDataReportSetup>(null);

  const fetchSetupIds = async (payload: GetSetupIdsPayload) => {
    try {
      const response = await getSetupIds(payload);

      if (response.content.length !== 1) {
        setFoundSetup(null);
        setGetSetupError('No raw_data_setup for this CP and LE. Please setup');
      } else {
        setFoundSetup(response.content[0]);
        setGetSetupError(null);
      }
    } catch (_) {
      setGetSetupError('Fetch setup ids error');
    }
  };

  const postReportRowData = async (payload: {
    cp: string;
    le: string;
    reportDate: string;
    module: GetSetupIdsPayload['module'];
    data: Record<string, unknown>;
  }) => {
    const value = {
      setup_id: foundSetup?.id ?? 0,
      cp: payload.cp,
      legal_entity: payload.le,
      report_date: payload.reportDate,
      module: payload.module,
      data_hash: sha1(payload.data),
      file_last_change: nowTime,
      source: 'added via interface',
      row_count: 1,
      created_at: nowTime,
      json_data: [{ row_number: 1, row: payload.data }],
    };
    try {
      const response = await createReport(value);
      return response['raw-metadata-id'];
    } catch (_) {
      Notification.error({ title: 'Post report in raw data error' });
    }
    return null;
  };

  const linkToRawData = useMemo(
    () => `${initialUrl}/raw-data/?name=${foundSetup?.name}`,
    [foundSetup],
  );

  return {
    foundSetup,
    getSetupError,
    linkToRawData,
    postReportRowData,
    getSetupIds: fetchSetupIds,
  };
}
