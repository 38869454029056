import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { BreakMonitorInfoCounterpartySide } from 'services/recon';
import { formatNumber } from 'utils';

export const getColumns = (): IColumn<
  BreakMonitorInfoCounterpartySide | any
>[] => [
  {
    Header: 'Entity',
    accessor: 'name',
    width: 200,
    minWidth,
    disableFilters: true,
    disableSortBy: true,
    className: 'pr-2 d-block text-left',
  },
  {
    Header: 'Count',
    accessor: 'qty',
    formatting: 'number',
    width: 135,
    minWidth,
    className: 'pointer',
    disableFilters: true,
    disableSortBy: true,
    shouldExpand: false,
  },
  {
    Header: 'Value in eur',
    accessor: 'value',
    width: 135,
    minWidth,
    Cell: (cell: ICellValue<BreakMonitorInfoCounterpartySide>) => (
      <div>{formatNumber(cell.row.original.value)}</div>
    ),
    className: 'pointer justify-content-end',
    disableFilters: true,
    disableSortBy: true,
    shouldExpand: false,
  },
];
