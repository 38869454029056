import { createContext } from 'react';

import { ReportWithFields } from 'services/recon';

import { FieldMappingConditionItem, FieldMappingItem } from './types';

interface IProps {
  isUpdate: boolean;
  report: ReportWithFields | null;
  reloadData: VoidFunction;
  mappings: FieldMappingItem[];
  conditions: FieldMappingConditionItem[];
  comparisonTypes: { type: string }[];
  onUpdateMapping: (value: FieldMappingItem) => void;
  onDeleteMapping: (uuid: string) => void;
  onPermanentlyDeleteMapping: (uuid: string) => void;
  onUpdateCondition: (value: FieldMappingConditionItem) => void;
  onDeleteCondition: (uuid: string) => void;
  cleanAllItems: VoidFunction;
  onAddMapping: VoidFunction;
  onAddCondition: (mappingId: string) => void;
  getConditionsByMappingId: (mappingId: string) => FieldMappingConditionItem[];
}
export const ReportFormContext = createContext<IProps>({
  isUpdate: false,
  report: null,
  reloadData: () => undefined,
  mappings: [],
  conditions: [],
  comparisonTypes: [],
  /* eslint @typescript-eslint/no-unused-vars: 0 */
  onUpdateMapping: (value: FieldMappingItem) => {},
  onDeleteMapping: (uuid: string) => {},
  onPermanentlyDeleteMapping: (uuid: string) => {},
  onUpdateCondition: (value: FieldMappingConditionItem) => {},
  onDeleteCondition: (uuid: string) => {},
  onAddCondition: (mappingId: string) => {},
  getConditionsByMappingId: (mappingId: string) => [],
  cleanAllItems: () => {},
  onAddMapping: () => {},
});
