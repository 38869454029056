import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { ButtonDatePicker, IconButton } from 'react-ui-kit-exante';

import { DATE_FORMAT } from '../../constants/date';

export const DatePicker: FC<{
  date: Date | null;
  setDate: (value: Date | null) => void;
  className?: string;
}> = ({ date, setDate, className }) => {
  const handleSetNextDate = () => {
    if (!dayjs().isSame(date, 'day')) {
      setDate(new Date(dayjs(date).add(1, 'day').format(DATE_FORMAT)));
    }
  };
  const handleSetPrevDate = () => {
    setDate(new Date(dayjs(date).subtract(1, 'day').format(DATE_FORMAT)));
  };
  const handleChangeDate = (value: Date | null) => {
    setDate(value);
  };
  return (
    <div
      className={classNames(
        'd-flex justify-content-end align-items-center',
        className,
      )}
    >
      <p className="m-0 mr-3">
        <IconButton
          iconColor="action"
          iconName="ArrowLeftSmallIcon"
          label={dayjs(date).format(DATE_FORMAT)}
          onClick={handleSetPrevDate}
        />
        <IconButton
          iconColor="action"
          iconName="ArrowRightSmallIcon"
          onClick={handleSetNextDate}
        />
      </p>
      <ButtonDatePicker
        onChange={handleChangeDate}
        selected={date}
        maxDate={new Date()}
      />
    </div>
  );
};
