import { configureStore } from '@reduxjs/toolkit';

import commonReducer from './reducers/commonReducer';

export const setupStore = () =>
  configureStore({
    reducer: commonReducer,
    devTools: true,
  });

export type RootState = ReturnType<typeof commonReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
