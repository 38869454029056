import dayjs from 'dayjs';
import { IColumn, ICellValue } from 'react-ui-kit-exante';

import { DATE_FORMAT } from 'constants/date';
import { minColumnWidth as minWidth } from 'constants/tables';
import { SymbolsMapping } from 'services/recon';
import { ColumnsWithFilters, SelectOption } from 'types';

export function getColumns({
  onFilter,
  onRemove,
  cpOptions,
}:
  | (ColumnsWithFilters & {
      cpOptions: SelectOption[];
    })
  | Record<string, any>): IColumn<SymbolsMapping>[] {
  return [
    {
      Header: 'ID',
      accessor: 'id',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Counterparty',
      accessor: 'cp',
      minWidth,
      type: 'select',
      filterOptions: cpOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'CP Symbol',
      accessor: 'cp_symbol',
      editParams: {
        editable: true,
        inputType: 'text',
      },
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Exante Symbol',
      accessor: 'exante_symbol',
      editParams: {
        editable: true,
        inputType: 'text',
      },
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Mty Value',
      accessor: 'value_multiplier',
      editParams: {
        editable: true,
        inputType: 'text',
      },
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Mty Price',
      accessor: 'price_multiplier',
      editParams: {
        editable: true,
        inputType: 'text',
      },
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Mty Qty',
      accessor: 'qty_multiplier',
      editParams: {
        editable: true,
        inputType: 'text',
      },
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'CCY',
      accessor: 'ccy',
      editParams: {
        editable: true,
        inputType: 'text',
      },
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'ISIN',
      accessor: 'isin',
      editParams: {
        editable: true,
        inputType: 'text',
      },
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'CP Type',
      accessor: 'cp_type',
      editParams: {
        editable: true,
        inputType: 'text',
      },
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Start Date',
      accessor: 'start_date',
      editParams: {
        editable: true,
        inputType: 'date',
      },
      Cell: (cell: ICellValue<SymbolsMapping>) =>
        cell.row.original.start_date
          ? dayjs(cell.row.original.start_date).format(DATE_FORMAT)
          : 'no date',
      disableFilters: true,
      minWidth: 120,
    },
    {
      Header: 'End Date',
      accessor: 'end_date',
      editParams: {
        editable: true,
        inputType: 'date',
      },
      Cell: (cell: ICellValue<SymbolsMapping>) =>
        cell.row.original.end_date
          ? dayjs(cell.row.original.end_date).format(DATE_FORMAT)
          : 'no date',
      disableFilters: true,
      minWidth,
    },
    {
      Header: 'Added',
      accessor: 'timestamp',
      Cell: (cell: ICellValue<SymbolsMapping>) =>
        cell.row.original.timestamp
          ? dayjs(cell.row.original.timestamp).format(DATE_FORMAT)
          : 'no date',
      disableFilters: true,
      minWidth,
    },
  ];
}

export const getAdditionalFilters = ({
  onFilter,
  onRemove,
}: ColumnsWithFilters) => {
  const columns: IColumn<Record<string, unknown>>[] = [
    {
      Header: 'Date',
      accessor: 'date',
      type: 'dateRange',
      useLocale: false,
      onFilter,
      onRemove,
    },
  ];
  return columns;
};
