import { FieldMapping, FieldMappingCondition } from 'services/recon';

export enum ReportTypes {
  TRADES = 'trades',
  POSITIONS = 'positions',
  CASH = 'cash',
  COUPON = 'coupon',
  TRANSACTIONS = 'transactions',
}

export type FieldMappingItem = Omit<
  FieldMapping,
  'conditions' | 'mapping_type'
> & {
  uuid: string;
  isNew: boolean;
};
export type FieldMappingConditionItem = FieldMappingCondition & {
  mappingId: string;
  uuid: string;
  isNew: boolean;
};
