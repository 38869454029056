import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import { ResponseWithPagination } from '../../types';
import { getMultiSortingParams, getUrlFromPayload } from '../../utils';
import { ReconApiService } from '../api';
import { FileMonitorItem, FileMonitorAdditionalFilters } from '../types';

import { CreateFileMonitorItem, UpdateFileRulePayload } from './types';

export async function createFileMonitorItem(payload: CreateFileMonitorItem) {
  return ReconApiService.postResource<
    CreateFileMonitorItem,
    CreateFileMonitorItem
  >('ftp-monitoring/file-monitor/', payload);
}

export async function createFileRule(payload: UpdateFileRulePayload) {
  return ReconApiService.postResource<
    UpdateFileRulePayload,
    UpdateFileRulePayload & { id: number }
  >('ftp-monitoring/file-rules/', payload);
}

export async function updateFileRule(
  id: number,
  payload: UpdateFileRulePayload,
) {
  return ReconApiService.patch<UpdateFileRulePayload>(
    `ftp-monitoring/file-rules/${id}/`,
    payload,
  );
}

export async function getFileMonitorItems(
  { params }: IOnFetchArguments,
  { date, cpFilter, leFilter }: FileMonitorAdditionalFilters,
) {
  try {
    const payload = {
      page: params.page + 1,
      limit: params.limit,
      date,
      counterparty_name: cpFilter,
      legal_entity: leFilter,
      active: true,
    };
    const response = await ReconApiService.getResource<
      ResponseWithPagination<FileMonitorItem>
    >(
      `${getUrlFromPayload(
        'ftp-monitoring/?',
        payload,
      )}order=${getMultiSortingParams(params.sorting)}`,
    );
    return {
      items: response.content,
      pagination: { total: response.pagination.total },
    };
  } catch (e) {
    Notification.error({ title: `Get file monitoring error: ${e}` });
  }
  return {
    items: [],
    pagination: { total: 0 },
  };
}
