import React, { ChangeEvent, SyntheticEvent, useContext, useMemo } from 'react';
import { Autocomplete, Button, Input } from 'react-ui-kit-exante';

import { getSelectOptions } from 'utils';

import { ReportFormContext } from '../ReportFormContext';
import { InfoBlock } from '../components';
import { FieldMappingConditionItem } from '../types';

interface Props {
  value: FieldMappingConditionItem;
}

export const ConditionItem: React.FC<Props> = ({ value }) => {
  const { onUpdateCondition, onDeleteCondition, comparisonTypes } =
    useContext(ReportFormContext);

  const handleChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    onUpdateCondition({ ...value, condition_field: e.target.value });
  };
  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    onUpdateCondition({ ...value, condition_value: e.target.value });
  };
  const handleChangeComparisonType = (e: SyntheticEvent, newValue: any) => {
    onUpdateCondition({ ...value, comparison_type: newValue?.value ?? '' });
  };

  const handleDeleteCondition = () => {
    onDeleteCondition(value.uuid);
  };

  const comparisonTypeOptions = useMemo(
    () => getSelectOptions(comparisonTypes.map((item) => item.type)),
    [comparisonTypes],
  );

  return (
    <div className="row my-2 ">
      <div className="col-md-2" />
      <div className="col-md-2">
        {value.isNew ? (
          <Input
            label="Condition Field"
            fullWidth
            onChange={handleChangeField}
            value={value.condition_field}
          />
        ) : (
          <InfoBlock value={value.condition_field} label="Condition Field" />
        )}
      </div>
      <div className="col-md-2">
        {value.isNew ? (
          <Input
            label="Condition Value"
            fullWidth
            onChange={handleChangeValue}
            value={value.condition_value}
          />
        ) : (
          <InfoBlock value={value.condition_value} label="Condition Value" />
        )}
      </div>
      <div className="col-md-2">
        {value.isNew ? (
          <Autocomplete
            placeholder="Comparison Type"
            className="w-100"
            options={comparisonTypeOptions}
            onChange={handleChangeComparisonType}
          />
        ) : (
          <InfoBlock value={value.comparison_type} label="Comparison Type" />
        )}
      </div>
      {value.isNew && (
        <div className="col-md-2">
          <Button fullWidth color="red" onClick={handleDeleteCondition}>
            Delete condition
          </Button>
        </div>
      )}
    </div>
  );
};
