import { FC, useEffect, useState } from 'react';
import {
  Select,
  Button,
  Panel,
  Input,
  Notification,
  CenteredLoader,
  DatePicker,
} from 'react-ui-kit-exante';

import {
  FormDataCpProps,
  getListPositionRecFields,
  postPositionReconciliation,
} from 'services/recon';
import { getFormPayload } from 'services/utils';

import { bannedFieldsList, optionsBoolean } from '../constants';
import { PositionRecAddFormProps, FieldsProps } from '../types';

import PositionRecAddFormStyles from './PositionRecAddForm.module.css';

const PositionRecAddForm: FC<PositionRecAddFormProps> = ({ cpOptions }) => {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<FieldsProps[] | []>([]);

  useEffect(() => {
    getListPositionRecFields()
      .then((e) => {
        setFields(
          e?.filter((i: FieldsProps) => !bannedFieldsList.includes(i.name)),
        );
      })
      .catch(() =>
        Notification.error({
          title: 'Failed to get metadata for position rec',
        }),
      );
  }, []);

  const onAddSubmit = (event: any) => {
    const payload: FormDataCpProps = getFormPayload(event.target);

    setLoading(true);
    event.preventDefault();

    postPositionReconciliation(payload)
      .then(() => {
        Notification.success({ title: 'Saved' });
        setLoading(false);
      })
      .catch((e) => {
        Notification.error(e.message);
        setLoading(false);
      });
  };

  const getForm = () => {
    const rows = [];
    let currRow = [];
    // eslint-disable-next-line
    for (const field of fields) {
      const { type, name } = field;
      if (currRow.length > 3) {
        rows.push(currRow);
        currRow = [];
      }

      let input = null;
      switch (type) {
        case 'date':
          input = (
            <DatePicker
              name={name}
              value={new Date()}
              onChange={() => {}}
              className="custom-select mr-sm-2"
            />
          );
          break;
        case 'bool':
          input = (
            <Select
              name={name}
              placeholder={name}
              options={optionsBoolean}
              className={PositionRecAddFormStyles.Width}
            />
          );
          break;
        default:
          if (name === 'cp') {
            input = (
              <Select
                name={name}
                placeholder={name}
                options={cpOptions}
                className={PositionRecAddFormStyles.Width}
              />
            );
          } else {
            input = (
              <Input
                type={type}
                name={name}
                placeholder={name}
                className={PositionRecAddFormStyles.Width}
              />
            );
          }
      }
      currRow.push(input);
    }
    rows.push(currRow);

    const saveActions = (
      <div className="d-flex align-items-center">
        <Button className="mr-2" type="submit">
          Create
        </Button>
      </div>
    );

    return (
      <form onSubmit={onAddSubmit} name="AddPositionRecForm">
        <Panel
          title="Add position"
          action={saveActions}
          className={PositionRecAddFormStyles.TitleMargin}
        >
          <div className={PositionRecAddFormStyles.ScrollForm}>
            {rows.map((row, index) => {
              const key = `index-${index}`;
              return <div key={key}>{row}</div>;
            })}
          </div>
        </Panel>
      </form>
    );
  };

  return <div>{loading ? <CenteredLoader /> : getForm()}</div>;
};

export default PositionRecAddForm;
