import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import { useAppSelector } from 'hooks';
import {
  getAllRawDataSetups,
  getCouponFields,
  getListCpTransactionFields,
  getListMappingCpTradesFields,
  getListMappingPositionReconciliationFields,
  getListMappingRowBalanceFields,
  RawDataReportSetup,
} from 'services/recon';
import {
  modeNamesSelector,
  newCounterPartyNames,
  newLegalEntityNames,
} from 'store/reducers/commonReducer';
import { SelectOption } from 'types';
import { getObjectFromSearchString, getSelectOptions } from 'utils';

import { ReportTypes } from '../../types';

export function useListsOptions(reportType: string) {
  const reportTypes = useAppSelector((state) => state.reportTypes);
  const cpNames = useAppSelector(newCounterPartyNames);
  const leNames = useAppSelector(newLegalEntityNames);
  const modeNameList = useAppSelector(modeNamesSelector);

  const location = useLocation();
  const searchObject = useMemo(
    () => getObjectFromSearchString(location.search),
    [location],
  );
  const [setupList, setSetupList] = useState<RawDataReportSetup[]>([]);
  const [fieldOptionsValue, setFieldOptionsValue] = useState<
    Record<string, SelectOption[]>
  >({});

  const loadFieldOptionsValue = async () => {
    try {
      const tradesRes = await getListMappingCpTradesFields();
      const positionsRes = await getListMappingPositionReconciliationFields();
      const cashRes = await getListMappingRowBalanceFields();
      const couponRes = await getCouponFields();
      const transactionRes = await getListCpTransactionFields();
      setFieldOptionsValue({
        [ReportTypes.TRADES]: getSelectOptions(tradesRes.results),
        [ReportTypes.POSITIONS]: getSelectOptions(positionsRes.results),
        [ReportTypes.CASH]: getSelectOptions(cashRes.results),
        [ReportTypes.COUPON]: getSelectOptions(couponRes.results),
        [ReportTypes.TRANSACTIONS]: getSelectOptions(transactionRes.results),
      });

      const setupsResponse = await getAllRawDataSetups();
      setSetupList(setupsResponse);
    } catch (e) {
      Notification.error({
        title: `Not loaded lists`,
      });
    }
  };

  useEffect(() => {
    loadFieldOptionsValue();
  }, []);

  const reportTypeOptions = useMemo(
    () =>
      reportTypes.map(({ type }) => ({
        label: type,
        value: type,
      })),
    [reportTypes],
  );
  const mappingFieldOptions = useMemo(
    () =>
      fieldOptionsValue[reportType] ?? fieldOptionsValue[ReportTypes.TRADES],
    [reportType, fieldOptionsValue],
  );
  const cpOptions = useMemo(() => getSelectOptions(cpNames), [cpNames]);
  const leOptions = useMemo(() => getSelectOptions(leNames), [leNames]);
  const modeOptions = useMemo(
    () => getSelectOptions(modeNameList),
    [modeNameList],
  );
  const setupOptions = useMemo(
    () =>
      setupList.map((i) => ({
        label: i.name.toUpperCase(),
        value: i.id,
      })),
    [setupList],
  );
  const setupDefaultValue = useMemo(
    () => setupOptions.find((i) => i.value === Number(searchObject.setup_id)),
    [searchObject, setupOptions],
  );
  return {
    setupDefaultValue,
    reportTypeOptions,
    mappingFieldOptions,
    cpOptions,
    leOptions,
    modeOptions,
    setupOptions,
  };
}
