import cn from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  calculateCountOfPages,
  IOnFetchArguments,
  ISortBy,
  Notification,
  OnSaveEditableRow,
  Table,
  useTableData,
  IconButton,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks/redux';
import { ReactComponent as Trash } from 'icons/trash.svg';
import {
  SymbolsMapping,
  getListSymbolsMapping,
  patchSymbolsMapping,
  deleteSymbolsMapping,
} from 'services/recon';
import { newCounterPartyNames } from 'store/reducers/commonReducer';
import { getDefaultFilterParams } from 'utils/getDefaultFilterParams';
import { getSelectOptions } from 'utils/getSelectOptions';

import { AddManualMapping } from './AddManualMapping';
import { ManualMappingFormProps, MappedSymbolsTable } from './types';
import { getAdditionalFilters, getColumns } from './utils';

const predefinedValues = {
  cp_symbol: 'AAPL',
  exante_symbol: 'AAPL.NASDAQ',
};

export const MappingPage = () => {
  const filtersIsFirstMounted = useRef(false);
  const [formHidden, setFormHidden] = useState(true);
  const counterPartyNames = useAppSelector(newCounterPartyNames);

  const cpOptions = useMemo(
    () => getSelectOptions(counterPartyNames),
    [counterPartyNames],
  );

  const getMappingSymbols = useCallback(
    (params: IOnFetchArguments) => getListSymbolsMapping(params),
    [],
  );
  const filterKeys = getColumns({}).map((i) => i.accessor);
  const prepareFiltersForParams = useCallback(
    getDefaultFilterParams({
      filterKeys,
      applyObject: predefinedValues,
      isApply: filtersIsFirstMounted,
      shouldApply: !window.location.search,
    }),
    [],
  );
  const tableData = useMemo(
    () => ({
      data: { onFetch: getMappingSymbols },
      filters: {
        prepareFiltersForParams,
      },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
    }),
    [getMappingSymbols],
  );
  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setSorting,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData,
  } = useTableData<MappedSymbolsTable>(tableData as any);

  const total = data?.pagination?.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );
  const serverPaginationProps = {
    pageIndex: page,
    pageCount,
    pageSize: limit,
    total,
    setPage,
    setPageSize: setLimit,
  };
  const additionalFilters = useMemo(
    () => getAdditionalFilters({ onFilter: setFilter, onRemove: removeFilter }),
    [setFilter, removeFilter],
  );
  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters,
    }),
    [filters, resetFilters, additionalFilters],
  );
  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        cpOptions,
      }),
    [setFilter, removeFilter, cpOptions],
  );

  const handleSaveEditableRow: OnSaveEditableRow<SymbolsMapping> = async (
    previousValues,
    updatedValues,
  ) => {
    const {
      isin,
      value_multiplier: valueMultiplier,
      qty_multiplier: qtyMultiplier,
      price_multiplier: priceMultiplier,
      ccy,
      cp_type: cpType,
      cp_symbol: cpSymbol,
      exante_symbol: exanteSymbol,
      start_date: startDate,
      end_date: endDate,
    } = updatedValues;
    try {
      await patchSymbolsMapping(previousValues.id, {
        isin,
        value_multiplier: valueMultiplier,
        qty_multiplier: qtyMultiplier,
        price_multiplier: priceMultiplier,
        cp_symbol: cpSymbol,
        ccy,
        cp_type: cpType,
        exante_symbol: exanteSymbol,
        start_date: startDate,
        end_date: endDate,
      });
      fetchData();
    } catch (e) {
      if (String(e).includes('unique')) {
        Notification.error({
          title:
            'The combination of such symbols and counterparty already exists.',
        });
      } else {
        Notification.error({ title: 'Update mapping error' });
      }
    }
  };

  const handleRefreshTable: ManualMappingFormProps['onRefreshTable'] = ({
    cpSymbol,
    exanteSymbol,
  }) => {
    if (
      cpSymbol !== filters.cp_symbol ||
      exanteSymbol !== filters.exante_symbol
    ) {
      setFilter('cp_symbol', cpSymbol as string);
      setFilter('exante_symbol', exanteSymbol as string);
    } else {
      fetchData();
    }
  };
  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const handleChangeShowForm = () => {
    setFormHidden(!formHidden);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteSymbolsMapping(id);
      Notification.success({ title: 'Was deleted' });
      fetchData();
    } catch (error) {
      Notification.error({ title: 'Can`t remove' });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className={cn({ 'col-8': !formHidden, 'col-12': formHidden })}>
          <Table
            title="Mapped Symbols"
            tableId="MappedSymbolsTable"
            pageSize={limit}
            pageSizes={pageSizes}
            additionalActions={[
              {
                title: 'Add',
                component: (
                  <IconButton
                    iconColor="action"
                    iconName="AddIcon"
                    label="Add mapping"
                    onClick={handleChangeShowForm}
                  />
                ),
              },
            ]}
            showScrollbar
            manualSortBy
            onSort={handleSorting}
            showTableInfo
            hasPagination
            data={data?.items ?? []}
            columns={columns}
            defaultSortBy={[{ id: 'id', desc: true }]}
            rowActions={{
              show: true,
              onSave: handleSaveEditableRow,
              additionalActions: [
                {
                  label: <Trash />,
                  onClick: (value) => {
                    handleDelete(value.id);
                  },
                  title: 'Delete',
                },
              ],
            }}
            hasFilters
            locale={defaultLocale}
            isFlexLayout
            isLoading={isLoading}
            filteringProps={filteringProps}
            serverPaginationProps={serverPaginationProps}
          />
        </div>
        <div className={cn({ 'col-4': !formHidden, 'd-none': formHidden })}>
          <AddManualMapping
            cpOptions={cpOptions}
            onRefreshTable={handleRefreshTable}
            onClose={() => setFormHidden(true)}
          />
        </div>
      </div>
    </div>
  );
};
