import { FC, useMemo } from 'react';
import { Modal, Table } from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { RawDataModuleConfig } from 'services/recon';

interface IProps {
  onClose: VoidFunction;
  rawDataName: string;
  items: RawDataModuleConfig[];
}

const columns = [
  {
    Header: 'Name',
    accessor: 'param_name',
  },
  {
    Header: 'Value',
    accessor: 'param_value',
  },
];
export const ModuleConfigsModal: FC<IProps> = ({
  onClose,
  rawDataName,
  items,
}) => {
  const title = useMemo(() => `Module configs in ${rawDataName}`, [onClose]);
  return (
    <Modal isOpened title={title} onClose={onClose}>
      <Table
        tableId="assignmentScheduleTable"
        locale={defaultLocale}
        data={items}
        columns={columns}
        isFlexLayout
      />
    </Modal>
  );
};
