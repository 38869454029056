import React, { forwardRef } from 'react';

import { CalendarIcon } from '../../../../../Icons';
import { BUTTON_DATE_PICKER_TEST_ID } from '../../constants';
import { CalendarButton } from '../ButtonDatePicker.styled';

import { TDatePickerButtonProps } from './types';

export const DatePickerButton = forwardRef<
  HTMLButtonElement,
  TDatePickerButtonProps
>(({ id, iconClassName, active, disabled, ...rest }, ref) => {
  return (
    <CalendarButton
      {...rest}
      type="button"
      disabled={disabled}
      aria-describedby={id}
      ref={ref}
    >
      <CalendarIcon
        className={iconClassName}
        active={active}
        disabled={disabled}
        dataTestId={BUTTON_DATE_PICKER_TEST_ID}
      />
    </CalendarButton>
  );
});
DatePickerButton.displayName = 'DatePickerButton';
