import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Notification,
  OnSaveEditableRow,
  Table,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import { ReactComponent as Trash } from 'icons/trash.svg';
import {
  CutOffTimeBySymbol,
  deleteSymbolCutOffTime,
  getListSymbolCutOffTime,
  patchSymbolCutOffTime,
} from 'services/recon';

import AddSymbolCOTForm from './components/AddForm';
import { columns } from './consts';

export const SymbolCOTPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<CutOffTimeBySymbol[]>([]);
  const [addIsVisible, setAddIsVisible] = useState(false);

  const fetchData = async () => {
    setIsLoading(false);
    setItems([]);
    try {
      const response = await getListSymbolCutOffTime();
      setItems(response.results);
    } catch (error) {
      Notification.error({
        title: 'get symbol cot list error',
        description: String(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditRow: OnSaveEditableRow<CutOffTimeBySymbol> = async (
    previousValues,
    updatedValues,
  ) => {
    try {
      await patchSymbolCutOffTime(previousValues.id, {
        ...previousValues,
        time: updatedValues.time,
      });
      Notification.success({
        title: 'Was updated',
      });
      fetchData();
    } catch (error) {
      Notification.error({
        title: 'Update Symbol error',
        description: String(error),
      });
    }
  };

  const handleDeleteRow = async (id: number) => {
    try {
      await deleteSymbolCutOffTime(id);
      Notification.success({
        title: 'Was deleted',
      });
      fetchData();
    } catch (error) {
      Notification.error({
        title: 'Delete Symbol error',
        description: String(error),
      });
    }
  };

  const additionalActions = [
    {
      children: <Button size="small">Add symbol cot</Button>,
      onClick: () => setAddIsVisible(true),
      title: 'open',
    },
  ];

  const rowActions = {
    show: true,
    onSave: handleEditRow,
    additionalActions: [
      {
        label: <Trash />,
        onClick: (value: CutOffTimeBySymbol) => {
          handleDeleteRow(value.id);
        },
        title: 'Delete',
      },
    ],
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Table
            tableId="symbols"
            title="Symbol cots"
            showTableInfo
            isFlexLayout
            hasPagination
            locale={defaultLocale}
            columns={columns}
            isLoading={isLoading}
            data={items}
            pageSizes={pageSizes}
            pageSize={30}
            additionalActions={additionalActions}
            rowActions={rowActions}
          />
          {addIsVisible && (
            <AddSymbolCOTForm
              reloadData={fetchData}
              onClose={() => setAddIsVisible(false)}
              isOpened
            />
          )}
        </div>
      </div>
    </div>
  );
};
