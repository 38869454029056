import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { CpAndCTrade } from 'services/recon';
import { ColumnsWithFilters, SelectOption } from 'types';
import { getSelectOptions } from 'utils/getSelectOptions';

export interface GetColumnsPayload extends ColumnsWithFilters {
  modeNames: string[];
}

const symbolOptions = getSelectOptions(['empty']);

export const getColumns = ({
  modeNames,
  onRemove,
  onFilter,
}: GetColumnsPayload | Record<string, any>) => {
  const modeOptions = getSelectOptions(modeNames);
  const columns: IColumn<CpAndCTrade>[] = [
    {
      Header: 'Date',
      accessor: 'date',
      minWidth,
      editParams: {
        inputType: 'date',
      },
      useLocale: false,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Legal entity',
      accessor: 'legal_entity_name',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Qty',
      accessor: 'qty',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Price',
      accessor: 'price',
      minWidth,
      formatting: 'number',
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Value',
      accessor: 'value',
      minWidth,
      formatting: 'number',
      disableFilters: true,
    },
    {
      Header: 'Value date',
      accessor: 'value_date',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Type',
      accessor: 'type',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Mode',
      accessor: 'mode',
      minWidth,
      type: 'select',
      filterOptions: modeOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'CP',
      accessor: 'cpname',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Bo symbol',
      accessor: 'bo_symbol',
      Cell: ({ row }: ICellValue<CpAndCTrade>) => (
        <span style={{ whiteSpace: 'pre-wrap' }}>{row.values.bo_symbol}</span>
      ),
      minWidth,
      type: 'multiSelect',
      freeSolo: true,
      filterOptions: symbolOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'Cp symbol',
      accessor: 'cp_symbol',
      Cell: ({ row }: ICellValue<CpAndCTrade>) => (
        <span style={{ whiteSpace: 'pre-wrap' }}>{row.values.cp_symbol}</span>
      ),
      minWidth,
      type: 'multiSelect',
      freeSolo: true,
      filterOptions: symbolOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'CCY',
      accessor: 'ccy',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'ISIN',
      accessor: 'isin',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Account',
      accessor: 'account',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Exchange order id',
      accessor: 'exchange_order_id',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
      disableSortBy: true,
    },
    {
      Header: 'Order id',
      accessor: 'order_id',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
      disableSortBy: true,
    },
    {
      Header: 'Break category',
      accessor: 'break_report_category',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Comment',
      accessor: 'break_comment',
      minWidth,
      disableFilters: true,
    },
  ];
  return columns;
};

export const getAdditionalColumns = ({
  onFilter,
  onRemove,
  entityOptions,
  cpOptions,
}: ColumnsWithFilters & {
  entityOptions: SelectOption[];
  cpOptions: SelectOption[];
}) => {
  const columns: IColumn<Record<string, unknown>>[] = [
    {
      Header: 'Legal entity',
      accessor: 'le',
      type: 'select',
      onFilter,
      onRemove,
      filterOptions: entityOptions,
    },
    {
      Header: 'Cp',
      accessor: 'counterparty',
      type: 'select',
      onFilter,
      onRemove,
      filterOptions: cpOptions,
    },
  ];
  return columns;
};

export const getPairColumns = () => {
  const columns: IColumn<CpAndCTrade>[] = [
    {
      Header: 'Trade Date',
      accessor: 'trade_date',
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Cp ',
      accessor: 'cpname',
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Account ',
      accessor: 'account',
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Bo symbol',
      accessor: 'bo_symbol',
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Ccy',
      accessor: 'ccy',
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Qty',
      accessor: 'qty',
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Price',
      accessor: 'price',
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Value',
      accessor: 'value',
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Type',
      accessor: 'type',
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Isin',
      accessor: 'isin',
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
  ];
  return columns;
};
