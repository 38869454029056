import dayjs from 'dayjs';

import { DATE_FORMAT } from 'constants/date';

const sunday = 6;
const saturday = 0;
export const getWorkYesterday = (format = DATE_FORMAT) => {
  const today = dayjs();
  const yesterday = today.subtract(1, 'day');
  if ([sunday, saturday].includes(today.day())) {
    return today.day(5).format(format);
  }
  if ([sunday, saturday].includes(yesterday.day())) {
    return yesterday.subtract(1, 'day').day(5).format(format);
  }
  return yesterday.format(format);
};

export const getMondayOfCurrentWeek = () => {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;

  return new Date(today.setDate(first));
};

export const getDateForRequest = (
  date: string | Date,
  format = DATE_FORMAT,
) => {
  const dateInstance = dayjs(date);
  return date && dateInstance.isValid() ? dateInstance.format(format) : '';
};
