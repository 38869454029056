import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styled from './styles.module.css';

const navItems = [
  { name: 'Scripts status', link: '/' },
  { name: 'Run info', link: '/run-info' },
  {
    name: 'Monitors',
    items: [
      { name: 'CP Monitor', link: '/cp-monitor' },
      { name: 'Checks of Movements', link: '/checks-of-movements' },
      { name: 'Trade Recon Monitor', link: '/trade-monitor' },
    ],
  },
  {
    name: 'Run script',
    items: [
      { name: 'Trade Recon', link: '/run/trade-recon' },
      { name: 'Load Trades', link: '/run/load-trades' },
      { name: 'SQL Scripts', link: '/run/views' },
      { name: 'Misc', link: '/run/misc' },
      { name: 'Reporting', link: '/run/reporting' },
      { name: 'Order Status', link: '/order-status' },
    ],
  },
  {
    name: 'Cash recon',
    items: [
      { name: 'Transactions recon', link: '/transactions-reconcile-union' },
      { name: 'Reconciled transactions', link: '/transactions-reconciled' },
      {
        name: 'New Transactions Recon',
        link: '/new-transactions-reconcile-union',
      },
    ],
  },
  {
    name: 'Trade reconciliation',
    items: [
      { name: 'Edit table (ctrades)', link: '/edit/tables' },
      { name: 'Edit table (cptrades)', link: '/edit/cptrades' },
      { name: 'Simple Mapping', link: '/edit/mapping' },
      { name: 'Find symbol mapping', link: '/mapping/find-symbol-mapping' },
      { name: 'Manual Unreconcile', link: '/trades-reconciled' },
      { name: 'Manual Reconcile', link: '/manual-reconcile-union' },
      { name: 'Reconciled Trades', link: '/trades-reconciled' },
      { name: 'Auto Reconcile', link: '/auto-reconcile' },
    ],
  },
  {
    name: 'Positions',
    items: [
      { name: 'Counterparty positions', link: '/edit/positionrec' },
      { name: 'Position Mapping', link: '/position-mapping' },
      { name: 'Reconciliation', link: '/position-recon' },
    ],
  },
  {
    name: 'Manual Add',
    items: [{ name: 'Counterparty balances', link: '/row-balance/add' }],
  },
  { name: 'Settings', items: [{ name: 'COT by Symbol', link: '/symbol-cot' }] },
  {
    name: 'Mapping',
    items: [
      { name: 'Value Mapping Rules', link: '/mapping/value-mapping-rules' },
      { name: 'New Report', link: '/mapping/new-report' },
      { name: 'Reports', link: '/mapping/reports' },
    ],
  },
  { name: 'Raw data', link: '/raw-data' },
  { name: 'Setup ETL', link: '/setup-etl' },
  { name: 'Cp Data Monitor', link: '/cp-data-monitor' },
  { name: 'Break Dashboard', link: '/break-dashboard' },
];
export const Navbar = () => (
  <nav className="navbar navbar-expand-sm bg-dark navbar-dark bg-nva-black">
    {' '}
    <ul className="navbar-nav">
      {' '}
      {navItems.map((item) => (
        <li
          key={item.name}
          className={classNames('nav-item', {
            [styled.Dropdown]: !!item?.items?.length,
          })}
        >
          {' '}
          {item.link ? (
            <Link to={item.link} className="nav-link pointer">
              {' '}
              {item.name}{' '}
            </Link>
          ) : (
            <span className="nav-link dropdown-toggle">{item.name}</span>
          )}{' '}
          {!!item?.items?.length && (
            <div
              className={classNames('dropdown-menu', styled.DropdownMenu)}
              aria-labelledby="navbarDropdownMenuLink"
            >
              {' '}
              {item.items.map((subItem) => (
                <Link
                  key={subItem.name}
                  to={subItem.link}
                  className="dropdown-item pointer"
                >
                  {' '}
                  {subItem.name}{' '}
                </Link>
              ))}{' '}
            </div>
          )}{' '}
        </li>
      ))}{' '}
      <li>
        {' '}
        <p className="nav-link">
          {' '}
          Recon version: <b> {process.env.REACT_APP_VERSION}</b>{' '}
        </p>{' '}
      </li>{' '}
    </ul>{' '}
  </nav>
);
