import { styled, ToggleButtonGroup } from 'react-ui-kit-exante';
import { Table as UiKitTable } from 'react-ui-kit-exante/build/Components/Table/Table';

export const HeadDiv = styled('div')(() => ({
  padding: '24px 24px 0',
}));
export const CheckboxesBlock = styled('div')(() => ({
  margin: '32px 0',
  display: 'flex',
  minHeight: '16px',
}));

export const StylesToggleButtonGroup = styled(ToggleButtonGroup)`
  &:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:nth-child(2) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const Table = styled(UiKitTable)`
  .FilterRowContainer {
    margin-top: 20px;
  }
`;
