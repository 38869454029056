import { FC } from 'react';
import { Table, Modal, IColumn } from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { minColumnWidth as minWidth } from 'constants/tables';
import { ReportField } from 'services/recon';

interface IProps {
  fields: ReportField[];
  reportId: number;
  onClose: VoidFunction;
}

const columns: IColumn<ReportField>[] = [
  {
    Header: 'Cp field',
    accessor: 'cp_field',
    minWidth,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Mapping field',
    accessor: 'mapping_field',
    minWidth,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Condition operations',
    accessor: 'condition_operations',
    minWidth,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Date format',
    accessor: 'date_format',
    minWidth,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Skip',
    accessor: 'skip',
    minWidth,
    disableSortBy: true,
    disableFilters: true,
  },
];

export const ModalWithFieldsList: FC<IProps> = ({
  fields,
  onClose,
  reportId,
}) => {
  return (
    <Modal isOpened onClose={onClose} title={`Report ${reportId}`}>
      <Table
        locale={defaultLocale}
        data={fields}
        columns={columns}
        tableId="reportFieldsTable"
      />
    </Modal>
  );
};
