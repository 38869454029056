import {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
} from 'react';
import { styled } from 'react-ui-kit-exante';

interface IProps extends PropsWithChildren {
  containerRef: MutableRefObject<null | HTMLDivElement>;
  className?: string;
}

const Div = styled('div')<{ isPinned: boolean }>(({ theme, isPinned }) => ({
  backgroundColor: theme.color.table.bg.basic2,
  position: 'relative',
  width: '100%',
  top: '0',
  zIndex: '1',
  transition: 'top 0.3s',
  padding: '10px',
  borderRadius: '10px',
  ...(isPinned ? { position: 'fixed', zIndex: 2, top: '10px' } : {}),
}));

export const PinnedBlock: FC<IProps> = ({
  containerRef,
  children,
  className,
}) => {
  const [isPinned, setIsPinned] = useState(false);
  const blockRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    const scroll = window.scrollY;
    const containerOffset =
      document.body.scrollHeight - (containerRef.current?.offsetHeight ?? 0);
    if (scroll > containerOffset + (blockRef.current?.clientHeight ?? 0)) {
      setIsPinned(true);
    }
    if (scroll < containerOffset) {
      setIsPinned(false);
    }
  };

  const getContainerWidth = () => {
    let width = containerRef.current?.clientWidth ?? 0;
    const computedStyles = containerRef.current
      ? window.getComputedStyle(containerRef.current as Element)
      : ({} as CSSStyleDeclaration);
    if (computedStyles.paddingLeft) {
      width -= parseFloat(computedStyles.paddingLeft);
    }
    if (computedStyles.paddingRight) {
      width -= parseFloat(computedStyles.paddingRight);
    }

    return width;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Div
      ref={blockRef}
      isPinned={isPinned}
      style={{ width: getContainerWidth() }}
      className={className}
    >
      {children}
    </Div>
  );
};
