import React, { ChangeEvent, FC } from 'react';
import { Select, Button, Autocomplete } from 'react-ui-kit-exante';

import { Report } from 'services/recon';
import { SelectOption } from 'types';

import { comparisonTypeOptions } from '../../consts';
import { AdvancedValueMappingCondition } from '../../types';

import classes from './ConditionItem.module.css';

interface IProps {
  report: Report | null;
  fieldOptions: SelectOption[];
  resultOptions: SelectOption[];
  condition: AdvancedValueMappingCondition;
  updateCondition: (condition: AdvancedValueMappingCondition) => void;
  removeCondition: (uuid: string) => void;
}

const ConditionItem: FC<IProps> = ({
  report,
  fieldOptions,
  resultOptions,
  condition,
  updateCondition,
  removeCondition,
}) => {
  const handleRemove = () => {
    removeCondition(condition.uuid);
  };
  const handleUpdateComparisonType = (event: ChangeEvent<HTMLInputElement>) => {
    updateCondition({ ...condition, comparison_type: event.target.value });
  };
  const handleUpdateConditionField = (event: ChangeEvent<HTMLInputElement>) => {
    updateCondition({ ...condition, condition_field: event.target.value });
  };

  const handleSelectConditionValue = (
    event: React.SyntheticEvent,
    value: any,
  ) => {
    if (value) {
      updateCondition({ ...condition, condition_value: value });
    } else {
      updateCondition({ ...condition, condition_value: '' });
    }
  };
  const handleInputConditionValue = (event: ChangeEvent<HTMLInputElement>) => {
    updateCondition({ ...condition, condition_value: event.target.value });
  };
  return (
    <div className="d-flex">
      <div className={classes.fieldBlock}>
        <Select
          label="Comparison Type"
          fullWidth
          options={comparisonTypeOptions}
          value={condition.comparison_type}
          onChange={handleUpdateComparisonType}
        />
      </div>
      <div className={classes.fieldBlock}>
        <Select
          label="Condition Field"
          fullWidth
          disabled={!report}
          options={fieldOptions}
          value={condition.condition_field}
          onChange={handleUpdateConditionField}
        />
      </div>
      <div className={classes.fieldBlock}>
        <Autocomplete
          title="Condition value"
          placeholder="Condition value"
          className="mb-3"
          fullWidth
          freeSolo
          options={resultOptions.map((i) => i.value)}
          onChange={handleSelectConditionValue}
          onInput={handleInputConditionValue}
        />
      </div>
      <Button color="red" onClick={handleRemove}>
        Remove
      </Button>
    </div>
  );
};
export default ConditionItem;
