import cn from 'classnames';
import React, {
  useMemo,
  useState,
  useCallback,
  FC,
  useRef,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  Notification,
  ISortBy,
  useTableData,
  IRow,
  ICellValue,
  IconButton,
  Checkbox,
} from 'react-ui-kit-exante';

import { DownloadIcon } from 'components';
import { defaultLocale } from 'constants/app';
import {
  DEFAULT_FILTER_VALUES,
  defaultOrdering,
  pageSizes,
} from 'constants/tables';
import { useAppSelector, usePropSelector, useCheckingItems } from 'hooks';
import {
  Transaction,
  SIDE,
  TransactionOperationType,
  getTransactionOperationTypeListForPage,
  getCpBoTransactionsUrl,
} from 'services/recon';
import {
  categoriesByTypesSelector,
  cpListByEntitySelector,
  currentUserSelector,
  modeNamesSelector,
  newLegalEntityNames,
  userNamesSelector,
} from 'store/reducers/commonReducer';
import { theme } from 'theme';
import { calculateCountOfPages, clickToLinkForFetchingData } from 'utils';
import { getSelectOptions } from 'utils/getSelectOptions';

import { ReconApiService } from '../../services/recon/api';

import { ActionsBlock } from './ActionsBlock/ActionsBlock';
import { ManualAddTransaction } from './ManualAddTransaction/ManualAddTransaction';
import { ReconciledTable } from './ReconciledTable/ReconciledTable';
import { useGetTableData, useInlineEdit } from './hooks';
import { TransactionsTable } from './types';
import { getTransactionsTableColumns, getAdditionalColumns } from './utils';

export const UnionTransactionsReconcilePage: FC<{
  isReconciledPage: boolean;
}> = ({ isReconciledPage }) => {
  const legalEntityNames = useAppSelector(newLegalEntityNames);
  const modeNamesList = useAppSelector(modeNamesSelector);
  const userNames = useAppSelector(userNamesSelector);
  const currentUser = useSelector(currentUserSelector);
  const categoriesByTypes = useAppSelector(categoriesByTypesSelector);
  const cpListsByEntities = useAppSelector((state) => state.cpListsByEntities);
  const breakCategories = useAppSelector((state) => state.breakCategories);
  const categories = useAppSelector((state) => state.categories);
  const ccyList = useAppSelector((state) => state.currencies);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [isShowReconciled, setIsShowReconciled] = useState(false);
  const [operationTypes, setOperationTypes] = useState<
    TransactionOperationType[]
  >([]);
  const [reconciledUpdateCount, setReconciledUpdateCount] = useState(0);
  const [selectedTransactions, setSelectedTransactions] = useState<
    Transaction[]
  >([]);
  const [addIsVisible, setAddIsVisible] = useState(false);
  const [excelIsDownloading, setExcelIsDownloading] = useState(false);

  const reloadReconciledTable = useCallback(() => {
    setReconciledUpdateCount(reconciledUpdateCount + 1);
  }, [setReconciledUpdateCount, reconciledUpdateCount]);

  const breakCategoryOptions = useMemo(
    () => [...getSelectOptions(breakCategories, 'name', 'name')],
    [breakCategories],
  );
  const breakCategoryObject = useMemo(
    () =>
      breakCategories.reduce(
        (acc: Record<string, string>, item) => ({
          ...acc,
          [item.name]: String(item.id),
        }),
        {},
      ),
    [breakCategories],
  );
  const entityOptions = useMemo(
    () => getSelectOptions(legalEntityNames),
    [legalEntityNames],
  );

  useEffect(() => {
    if (currentUser?.name) {
      getTransactionOperationTypeListForPage('transactions-reconcile')
        .then((response) => {
          if (response.results) {
            setOperationTypes(response.results);
          }
        })
        .catch(() => {
          Notification.error({
            title: 'Fetch TransactionTypes error',
          });
        });
    }
  }, []);

  const defaultCounterparty = useMemo(() => {
    if (Object.values(cpListsByEntities).length && legalEntityNames.length) {
      return cpListsByEntities[legalEntityNames[0]][0]?.name;
    }
    return '';
  }, [cpListsByEntities, legalEntityNames]);

  const { tableData, tableId, getRequestPayload } = useGetTableData(
    isReconciledPage,
    defaultCounterparty,
    legalEntityNames[0],
    breakCategoryObject,
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading: isLoadingCpBo,
    fetchData,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    sorting,
    params,
    setSorting,
  } = useTableData<TransactionsTable>(tableData);
  const [items, setItems] = useState(data?.transactions ?? []);
  useEffect(() => {
    setItems(data?.transactions ?? []);
  }, [data]);

  const {
    handleCheckItem: handleCheckTransaction,
    handleUnCheckAllOnPage,
    handleCheckAllOnPage,
  } = useCheckingItems({
    selectedItems: selectedTransactions,
    setSelectedItems: setSelectedTransactions,
    items,
  });

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (filters.le as string) ?? DEFAULT_FILTER_VALUES.le,
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity?.map((item) => item.name) ?? []),
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && filters.counterparty) {
      const foundCp = cpOptions.find(
        (item) => item.value === filters.counterparty,
      );
      if (!foundCp) {
        setFilter('counterparty', cpOptions[0].value);
      }
    }
  }, [filters.le]);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total ?? 0, limit),
    [limit, data?.pagination.total],
  );
  const additionalFilters = useMemo(
    () =>
      getAdditionalColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        entityOptions,
        counterPartyOptions: cpOptions,
        isReconciled: isReconciledPage,
      }),
    [setFilter, removeFilter, entityOptions, cpOptions, isReconciledPage],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters,
    }),
    [filters, resetFilters, additionalFilters],
  );

  const updateData = useCallback(
    (key: keyof Transaction, value: unknown, values: Transaction) => {
      const comparedItems = [...items];
      const foundIndex = items.findIndex((item) => item.id === values.id);
      if (key === 'operationtype') {
        const firstCategoryElement = categoriesByTypes[value as string][0];
        comparedItems[foundIndex] = {
          ...items[foundIndex],
          operationtype: value as string,
          category: firstCategoryElement ?? null,
        };
      } else {
        comparedItems[foundIndex] = { ...items[foundIndex], [key]: value };
      }
      setItems(comparedItems);
    },
    [setItems, items, categoriesByTypes],
  );

  const transactionsTableColumns = useMemo(
    () =>
      getTransactionsTableColumns(
        {
          transactions: items,
          selectedTransactionsIds: selectedTransactions.map((i) => i.id),
          onCheckTransaction: handleCheckTransaction,
          onCheckAllTransactionOnPage: handleCheckAllOnPage,
          onUnCheckAllTransactionOnPage: handleUnCheckAllOnPage,
          breakCategoryOptions,
          operationTypes,
          cpOptions,
          modeNames: modeNamesList,
          ccyList,
          userNames,
          categories,
          categoriesByTypes,
          onFilter: setFilter,
          onRemove: removeFilter,
          reloadData: fetchData,
          updateData,
        },
        isReconciledPage,
      ),
    [
      items,
      categoriesByTypes,
      breakCategoryOptions,
      selectedTransactions,
      operationTypes,
      ccyList,
      setFilter,
      userNames,
      removeFilter,
      entityOptions,
      cpOptions,
      modeNamesList,
      filters,
      updateData,
    ],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const { handleCancelEditableRow, handleSaveEditableRow } = useInlineEdit({
    items,
    setItems,
    dataTransactions: data?.transactions,
    selectedTransactions,
    setSelectedTransactions,
    fetchData,
  });
  const handleChangeIsVisible = () => {
    setAddIsVisible(!addIsVisible);
  };
  const reloadData = () => {
    setAddIsVisible(false);
    fetchData();
  };
  const getRowProps = useCallback(
    (row: IRow<Transaction>) => {
      let background = theme?.color.table.bg.basic2;
      const isSelectElement = !!selectedTransactions.find(
        (i) => i.id === row.original.id,
      );
      if (isSelectElement) {
        background = theme?.color.table.bg.source;
      } else if (row.original.side === SIDE.OUR) {
        background = theme?.color.bg.secondary;
      }
      return {
        style: {
          background,
        },
      };
    },
    [selectedTransactions, items],
  );
  const getCellProps = useCallback(
    (cell: ICellValue<Transaction>) =>
      cell.column.id === 'actions'
        ? {
            style: {
              ...getRowProps(cell.row).style,
              boxShadow: `0 0 0 ${theme?.color.table.bg.basic2}`,
            },
          }
        : {},
    [getRowProps],
  );

  const fetchExcelFile = useCallback(async () => {
    try {
      const url = getCpBoTransactionsUrl(
        getRequestPayload({
          params,
          filtersParams: { ...filters, generate_report: true },
          sortingParams: sorting,
          paginationParams: {
            page: params.page,
            skip: params.skip,
            limit: params.limit,
          },
        }),
        isReconciledPage,
      );
      setExcelIsDownloading(true);
      const response = await fetch(ReconApiService.apiBase + url.url, {
        headers: ReconApiService.getBaseHeaders(),
      });
      if (!response.ok) {
        const error = await response.json();
        throw new Error(JSON.stringify(error));
      }
      setExcelIsDownloading(false);
      await clickToLinkForFetchingData(
        response,
        `${filters.le}_transactions_report_${url.dates.fromDate}_${url.dates.toDate}`,
      );
    } catch (error) {
      Notification.error({
        title: 'Loading file error',
        description: String(error),
      });
    } finally {
      setExcelIsDownloading(false);
    }
  }, [isReconciledPage, filters, params]);

  const additionalActions = [
    {
      title: 'Add',
      component: !isReconciledPage ? (
        <IconButton
          iconColor="action"
          iconName="AddIcon"
          label="Add transaction"
          onClick={handleChangeIsVisible}
        />
      ) : undefined,
    },
    {
      title: 'Load Excel',
      component: (
        <DownloadIcon isLoading={excelIsDownloading} onClick={fetchExcelFile} />
      ),
    },
  ];

  const tableTitle = isReconciledPage
    ? 'Reconciled Transactions'
    : 'Transactions';
  return (
    <div className="mui-container-fluid" ref={containerRef}>
      {!isReconciledPage && (
        <ActionsBlock
          reloadReconciledTable={reloadReconciledTable}
          reloadData={fetchData}
          transactions={items || []}
          selectedTransactions={selectedTransactions}
          onUpdateSelectedTransactions={setSelectedTransactions}
          containerRef={containerRef}
        />
      )}
      <div className="mui-row mt-1">
        <div
          className={cn({
            'mui-col-md-12': !addIsVisible,
            'mui-col-md-8': addIsVisible,
          })}
        >
          <Table
            tableId={tableId}
            title={tableTitle}
            showTableInfo
            hasPagination
            hasFilters
            locale={defaultLocale}
            manualSortBy
            isFlexLayout
            showScrollbar
            saveColumnOrder
            saveViewParamsAfterLeave
            additionalActions={
              isReconciledPage
                ? additionalActions
                : [
                    ...additionalActions,
                    {
                      title: 'Show/Hide Reconciled',
                      component: (
                        <Checkbox
                          checked={isShowReconciled}
                          color="primary"
                          label="Show/Hide Reconciled"
                          onChange={(_, value) => setIsShowReconciled(value)}
                        />
                      ),
                    },
                  ]
            }
            data={items}
            columns={transactionsTableColumns}
            isLoading={isLoadingCpBo}
            pageSizes={pageSizes}
            filteringProps={filteringProps}
            onSort={handleSorting}
            getRowProps={getRowProps}
            getCellProps={getCellProps}
            defaultSortBy={
              isReconciledPage ? [{ id: 'id', desc: true }] : defaultOrdering
            }
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total: data?.pagination.total ?? 0,
              setPage,
              setPageSize: setLimit,
            }}
            rowActions={{
              show: !isReconciledPage,
              onSave: handleSaveEditableRow,
              onCancel: handleCancelEditableRow,
            }}
          />
        </div>
        {!isReconciledPage && (
          <div
            className={cn({
              'd-none': !addIsVisible,
              'mui-col-md-4': addIsVisible,
            })}
          >
            <ManualAddTransaction
              operationTypes={operationTypes}
              reloadData={reloadData}
            />
          </div>
        )}
      </div>
      {!isReconciledPage && isShowReconciled && (
        <div className="row">
          <div className="col-12">
            <ReconciledTable
              getCellProps={getCellProps}
              getRowProps={getRowProps}
              operationTypes={operationTypes}
              filters={filters}
              reloadUnreconciledTable={fetchData}
              reconciledUpdateCount={reconciledUpdateCount}
            />
          </div>
        </div>
      )}
    </div>
  );
};
