import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { RowBalance } from 'services/recon';
import { ColumnsWithFilters, SelectOption } from 'types';

import { manualSourceFile } from '../consts';

type ColumnsType = ColumnsWithFilters & {
  cpOptions: SelectOption[];
  typeOptions: SelectOption[];
  entityOptions: SelectOption[];
};

export function getColumns({
  onFilter,
  onRemove,
  cpOptions,
  typeOptions,
  entityOptions,
}: ColumnsType): IColumn<RowBalance>[] {
  const getCellEditable = (cell: ICellValue<RowBalance>) =>
    cell.row.original.source_file === manualSourceFile;
  return [
    {
      Header: 'Counterparty',
      accessor: 'counterparty_id',
      minWidth,
      editParams: {
        editable: getCellEditable,
        inputType: 'select',
        options: cpOptions,
      },
      Cell: (cell: ICellValue<RowBalance>) =>
        cell.row.original.counterparty_name,
      type: 'select',
      filterOptions: cpOptions,
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Legal Entity',
      accessor: 'legal_entity_id',
      minWidth,
      editParams: {
        editable: getCellEditable,
        inputType: 'select',
        options: entityOptions,
      },
      Cell: (cell: ICellValue<RowBalance>) =>
        cell.row.original.legal_entity_name,
      type: 'select',
      filterOptions: entityOptions,
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Report Date',
      accessor: 'report_date',
      minWidth,
      editParams: {
        editable: getCellEditable,
        inputType: 'date',
      },
      type: 'dateInput',
      useLocale: false,
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Type',
      accessor: 'type',
      minWidth,
      editParams: {
        editable: getCellEditable,
        inputType: 'text',
      },
      type: 'select',
      filterOptions: typeOptions,
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'CCY',
      accessor: 'ccy',
      editParams: {
        editable: getCellEditable,
        inputType: 'text',
      },
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Account',
      accessor: 'account',
      editParams: {
        editable: getCellEditable,
        inputType: 'text',
      },
      minWidth,
      type: 'textInput',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Value',
      accessor: 'value',
      editParams: {
        editable: getCellEditable,
        inputType: 'text',
      },
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Category',
      accessor: 'category',
      editParams: {
        editable: getCellEditable,
        inputType: 'text',
      },
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Timestamp',
      accessor: 'timestamp',
      editParams: {
        editable: getCellEditable,
        inputType: 'text',
      },
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Source File',
      accessor: 'source_file',
      editParams: {
        editable: getCellEditable,
        inputType: 'text',
      },
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Rate',
      accessor: 'rate',
      editParams: {
        editable: getCellEditable,
        inputType: 'text',
      },
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Comment',
      accessor: 'comment',
      editParams: {
        editable: getCellEditable,
        inputType: 'text',
      },
      minWidth,
      disableFilters: true,
      disableSortBy: true,
    },
  ];
}
