import dayjs from 'dayjs';
import React, {
  useCallback,
  useMemo,
  useRef,
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Table,
  useTableData,
  ISortBy,
  OnSaveEditableRow,
  Notification,
  IRow,
  ICellValue,
  Checkbox,
} from 'react-ui-kit-exante';

import { DownloadIcon } from 'components';
import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import {
  DEFAULT_FILTER_VALUES,
  defaultOrdering,
  pageSizes,
} from 'constants/tables';
import { useCheckingItems } from 'hooks';
import { useAppSelector, usePropSelector } from 'hooks/redux';
import {
  CpAndCTrade,
  PatchCpAndCTradePayload,
  patchTradeError,
  SIDE,
  patchCpAndCTrade,
  getCpAndCTradesUrl,
} from 'services/recon';
import { ReconApiService } from 'services/recon/api';
import {
  cpListByEntitySelector,
  modeNamesSelector,
  newCounterPartyNames,
  newLegalEntityNamesForBroker,
  userNamesSelector,
} from 'store/reducers/commonReducer';
import { theme } from 'theme';
import {
  calculateCountOfPages,
  clickToLinkForFetchingData,
  getChangedValues,
  getSelectOptions,
} from 'utils';

import { ActionsBlock } from './ActionsBlock/ActionsBlock';
import { ReconciledTable } from './ReconciledTable/ReconciledTable';
import { useGetTableData } from './hooks';
import { TradesTable } from './types';
import { getAdditionalColumns, getTradesTableColumns } from './utils';

export const UnionTradesReconcilePage: FC<{ isReconciledPage: boolean }> = ({
  isReconciledPage,
}) => {
  const legalEntityNames = useAppSelector(newLegalEntityNamesForBroker);
  const counterPartyNames = useAppSelector(newCounterPartyNames);
  const modeNamesList = useAppSelector(modeNamesSelector);
  const userNames = useAppSelector(userNamesSelector);
  const cpListsByEntities = useAppSelector((state) => state.cpListsByEntities);
  const ccyList = useAppSelector((state) => state.currencies);
  const breakCategories = useAppSelector((state) => state.breakCategories);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [excelIsDownloading, setExcelIsDownloading] = useState(false);

  const [isShowReconciled, setIsShowReconciled] = useState(false);
  const [selectedTrades, setSelectedTrades] = useState<CpAndCTrade[]>([]);
  const [reconciledUpdateCount, setReconciledUpdateCount] = useState(0);

  const ccyOptions = useMemo(() => getSelectOptions(ccyList), [ccyList]);
  const breakCategoryOptions = useMemo(
    () => getSelectOptions(breakCategories, 'name', 'name'),
    [breakCategories],
  );
  const breakCategoryObject = useMemo(
    () =>
      breakCategories.reduce(
        (acc: Record<string, string>, item) => ({
          ...acc,
          [item.name]: String(item.id),
        }),
        {},
      ),
    [breakCategories],
  );

  const defaultCounterparty = useMemo(() => {
    if (Object.values(cpListsByEntities).length && legalEntityNames.length) {
      return cpListsByEntities[legalEntityNames[0]][0]?.name;
    }
    return '';
  }, [cpListsByEntities, legalEntityNames]);

  const { tableData, tableId, getRequestPayload } = useGetTableData({
    isReconciledPage,
    breakCategoryObject,
    counterparty: defaultCounterparty,
    firstLegalEntity: legalEntityNames[0],
  });

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading: isLoadingCpBo,
    fetchData,
    setFilter,
    removeFilter,
    resetFilters,
    sorting,
    params,
    filters,
    setSorting,
  } = useTableData<TradesTable>(tableData);

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (filters.le as string) ?? DEFAULT_FILTER_VALUES.le,
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity?.map((item) => item.name) ?? []),
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && filters.counterparty) {
      const foundCp = cpOptions.find(
        (item) => item.value === filters.counterparty,
      );
      if (!foundCp) {
        setFilter('counterparty', cpOptions[0].value);
      }
    }
  }, [cpOptions]);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total ?? 0, limit),
    [limit, data?.pagination.total],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        entityOptions: getSelectOptions(legalEntityNames),
        cpOptions,
        isReconciled: isReconciledPage,
      }),
    [setFilter, removeFilter, cpOptions, legalEntityNames, isReconciledPage],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters,
    }),
    [filters, resetFilters, additionalFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const {
    handleCheckItem: handleCheckTrade,
    handleUnCheckAllOnPage,
    handleCheckAllOnPage,
  } = useCheckingItems({
    selectedItems: selectedTrades,
    setSelectedItems: setSelectedTrades,
    items: data?.trades ?? [],
  });

  const columns = useMemo(
    () =>
      getTradesTableColumns(
        {
          trades: data?.trades,
          selectedTradesIds: selectedTrades.map((i) => i.id),
          onCheckTrade: handleCheckTrade,
          onCheckAllTradesOnPage: handleCheckAllOnPage,
          onUnCheckAllTradesOnPage: handleUnCheckAllOnPage,
          breakCategoryOptions,
          cpOptions,
          ccyOptions,
          userNames,
          modeNames: modeNamesList,
          onFilter: setFilter,
          onRemove: removeFilter,
          reloadData: fetchData,
        },
        isReconciledPage,
      ),
    [
      data,
      fetchData,
      selectedTrades,
      breakCategoryOptions,
      modeNamesList,
      userNames,
      counterPartyNames,
      ccyOptions,
      cpOptions,
    ],
  );

  const reloadReconciledTable = useCallback(() => {
    setReconciledUpdateCount(reconciledUpdateCount + 1);
  }, [setReconciledUpdateCount, reconciledUpdateCount]);

  const handleSaveEditableRow: OnSaveEditableRow<CpAndCTrade> = async (
    previousValues,
    updatedValues,
  ) => {
    if (previousValues.side === SIDE.THEIR) {
      Notification.error({ title: 'You can`t edit "their" trades' });
      return;
    }
    try {
      const changedValues = getChangedValues(previousValues, updatedValues);
      const payload: PatchCpAndCTradePayload = {
        id:
          selectedTrades.length > 1
            ? selectedTrades.map((item) => item.id).join(',')
            : previousValues.id,
        side: previousValues.side,
      };
      if (changedValues.date) {
        payload.date = dayjs(changedValues.date).format(DATE_FORMAT);
      }
      if (changedValues.cpname) {
        payload.cp = changedValues.cpname;
      }
      await patchCpAndCTrade(payload);
      Notification.success({ title: 'Trade updated success' });
      setSelectedTrades([]);
      await fetchData();
    } catch (e) {
      if (String(e).includes(patchTradeError.sourceFileIsNotRecon)) {
        Notification.error({
          title:
            'You cannot change any field for this record (field "source" not equal "UI")',
        });
      } else {
        Notification.error({ title: 'Update trade error' });
      }
    }
  };

  const fetchExcelFile = useCallback(async () => {
    try {
      const url = getCpAndCTradesUrl(
        getRequestPayload({
          params,
          filtersParams: { ...filters, generate_report: true },
          sortingParams: sorting,
          paginationParams: {
            page: params.page,
            skip: params.skip,
            limit: params.limit,
          },
        }),
        isReconciledPage,
      );
      setExcelIsDownloading(true);
      const response = await fetch(ReconApiService.apiBase + url.url, {
        headers: ReconApiService.getBaseHeaders(),
      });
      if (!response.ok) {
        const error = await response.json();
        throw new Error(JSON.stringify(error));
      }
      setExcelIsDownloading(false);
      await clickToLinkForFetchingData(
        response,
        `${filters.le}_trades_report_${url.dates.fromDate}_${url.dates.toDate}`,
      );
    } catch (error) {
      Notification.error({
        title: 'Loading file error',
        description: String(error),
      });
    } finally {
      setExcelIsDownloading(false);
    }
  }, [isReconciledPage, filters, params]);

  const getRowProps = useCallback(
    (row: IRow<CpAndCTrade>) => {
      let background = theme?.color.table.bg.basic2;
      const isSelectElement = !!selectedTrades.find(
        (i) => i.id === row.original.id,
      );
      if (isSelectElement) {
        background = theme?.color.table.bg.source;
      } else if (row.original.side === SIDE.OUR) {
        background = theme?.color.bg.secondary;
      }
      return {
        style: {
          background,
        },
      };
    },
    [selectedTrades, data],
  );
  const getCellProps = useCallback(
    (cell: ICellValue<CpAndCTrade>) =>
      cell.column.id === 'actions'
        ? {
            style: {
              ...getRowProps(cell.row).style,
              boxShadow: `0 0 0 ${theme?.color.table.bg.basic2}`,
            },
          }
        : {},
    [getRowProps],
  );

  const tableTitle = isReconciledPage ? 'Reconciled Trades' : 'Trades';
  return (
    <div className="container-fluid" ref={containerRef}>
      {!isReconciledPage && (
        <ActionsBlock
          reloadReconciledTable={reloadReconciledTable}
          reloadData={fetchData}
          trades={data?.trades || []}
          selectedTrades={selectedTrades}
          onUpdateSelectedTrades={setSelectedTrades}
          containerRef={containerRef}
        />
      )}
      <div className="row mt-1">
        <div className="col-12">
          <Table
            tableId={tableId}
            title={tableTitle}
            showTableInfo
            hasPagination
            hasFilters
            locale={defaultLocale}
            manualSortBy
            isFlexLayout
            showScrollbar
            saveColumnOrder
            saveViewParamsAfterLeave
            data={data?.trades ?? []}
            columns={columns}
            additionalActions={
              isReconciledPage
                ? [
                    {
                      title: 'Load Excel',
                      component: (
                        <DownloadIcon
                          isLoading={excelIsDownloading}
                          onClick={fetchExcelFile}
                        />
                      ),
                    },
                  ]
                : [
                    {
                      title: 'Show/Hide Reconciled',
                      component: (
                        <Checkbox
                          checked={isShowReconciled}
                          color="primary"
                          label="Show/Hide Reconciled"
                          onChange={(_, value) => setIsShowReconciled(value)}
                        />
                      ),
                    },
                    {
                      title: 'Load Excel',
                      component: (
                        <DownloadIcon
                          isLoading={excelIsDownloading}
                          onClick={fetchExcelFile}
                        />
                      ),
                    },
                  ]
            }
            isLoading={isLoadingCpBo}
            pageSizes={pageSizes}
            filteringProps={filteringProps}
            onSort={handleSorting}
            getRowProps={getRowProps}
            getCellProps={getCellProps}
            defaultSortBy={
              isReconciledPage ? [{ id: 'id', desc: true }] : defaultOrdering
            }
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total: data?.pagination?.total ?? 0,
              setPage,
              setPageSize: setLimit,
            }}
            rowActions={{
              show: !isReconciledPage,
              onSave: handleSaveEditableRow,
              isEditedRow: (row) => row.side === SIDE.OUR,
            }}
          />
        </div>
      </div>
      {!isReconciledPage && isShowReconciled && (
        <div className="row">
          <div className="col-12">
            <ReconciledTable
              getCellProps={getCellProps}
              getRowProps={getRowProps}
              filters={filters}
              reloadUnreconciledTable={fetchData}
              reconciledUpdateCount={reconciledUpdateCount}
            />
          </div>
        </div>
      )}
    </div>
  );
};
