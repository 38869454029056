import { SelectOption } from 'types';

export const getColumns = (
  stateOptions: SelectOption[],
  modeOptions: SelectOption[],
) => [
  {
    Header: 'ID',
    accessor: 'report_id',
    width: 60,
  },
  {
    Header: 'Name',
    accessor: 'name',
    minWidth: 100,
    width: 100,
    editParams: {
      editable: true,
      inputType: 'text',
    },
  },
  {
    Header: 'Mode',
    accessor: 'mode',
    minWidth: 100,
    width: 100,
    editParams: {
      editable: true,
      inputType: 'select',
      options: modeOptions,
    },
  },
  {
    Header: 'isActive',
    accessor: 'isActive',
    minWidth: 80,
    width: 80,
  },
  {
    Header: 'State',
    accessor: 'state',
    minWidth: 100,
    width: 100,
    editParams: {
      editable: true,
      inputType: 'select',
      options: stateOptions,
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    minWidth: 100,
    width: 100,
  },
];
