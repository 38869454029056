export const getChangedValues = (
  initialObject: Record<string, any>,
  changedObject: Record<string, any>,
): Record<string, any> =>
  Object.keys(initialObject).reduce((acc, key) => {
    const initialVal = initialObject[key];
    const changedVal = changedObject[key];
    return changedVal !== undefined && initialVal !== changedVal
      ? { ...acc, [key]: changedVal }
      : acc;
  }, {});
