export const numberInstance = new Intl.NumberFormat('ru-RU', {
  minimumFractionDigits: 2,
});

export const formatNumber = (value: number) => numberInstance.format(value);

export function getNumberWithCommas(value: number) {
  return value
    ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    : value;
}
