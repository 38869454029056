import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  IOnFetchArguments,
  useTableData,
  ISortBy,
  Table,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import { useAppSelector, usePropSelector } from 'hooks/redux';
import { getRawDataReportConfigs, RawDataReportSetup } from 'services/recon';
import { ResponseForTable } from 'services/types';
import {
  cpListByEntitySelector,
  newLegalEntityNames,
} from 'store/reducers/commonReducer';
import { calculateCountOfPages } from 'utils';
import { getSelectOptions } from 'utils/getSelectOptions';

import { NewRawData } from './NewRawData/NewRawData';
import { getRawDataTableColumns } from './RawDataUtils';

const defaultOrdering = [{ id: 'id', desc: true }];

export const RawDataPage: FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const legalEntityNames = useAppSelector(newLegalEntityNames);
  const leOptions = useMemo(
    () => getSelectOptions(legalEntityNames),
    [legalEntityNames],
  );

  const getReportConfigs = useCallback(
    (props: IOnFetchArguments) => getRawDataReportConfigs(props),
    [],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getReportConfigs },
      sorting: {
        getDefaultSorting: () => defaultOrdering,
      },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
    }),
    [getReportConfigs],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
    fetchData,
  } = useTableData<ResponseForTable<RawDataReportSetup>>(tableData);

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (filters.legal_entity as string) ?? '',
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity?.map((item) => item.name) ?? []),
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && filters.counterparty) {
      const foundCp = cpOptions.find(
        (item) => item.value === filters.counterparty,
      );
      if (!foundCp) {
        setFilter('counterparty', cpOptions[0].value);
      }
    }
  }, [cpOptions]);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const rawDataTableColumns = useMemo(
    () =>
      getRawDataTableColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        cpOptions,
        leOptions,
      }),
    [setFilter, removeFilter, cpOptions, leOptions],
  );

  const handleChangeCreateVisible = () => {
    setIsVisible(!isVisible);
  };
  const handleCloseCreating = () => {
    setIsVisible(false);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className={classNames({ 'col-12': !isVisible, 'col-8': isVisible })}
        >
          <Table
            tableId="rawDataTable"
            title="Raw Data"
            data={data?.data ?? []}
            columns={rawDataTableColumns}
            filteringProps={filteringProps}
            isLoading={isLoading}
            onAdd={handleChangeCreateVisible}
            hasFilters
            locale={defaultLocale}
            hasPagination
            showTableInfo
            manualSortBy
            onSort={handleSorting}
            isFlexLayout
            defaultSortBy={defaultOrdering}
            pageSizes={pageSizes}
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total,
              setPage,
              setPageSize: setLimit,
            }}
          />
        </div>
        {isVisible && (
          <div className="col-4">
            <NewRawData reloadData={fetchData} onClose={handleCloseCreating} />
          </div>
        )}
      </div>
    </div>
  );
};
