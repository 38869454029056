import { useMemo, useState } from 'react';

export function useSelectRows<T extends { id: number }>(
  rows: T[],
  selectLimit?: number,
) {
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  const { handleSelectRow, handleSelectAllRows, handleUnSelectAllRows } =
    useMemo(
      () => ({
        handleSelectRow: (row: T) => {
          if (selectedRows.find((i) => i.id === row.id)) {
            setSelectedRows([...selectedRows.filter((i) => i.id !== row.id)]);
          } else {
            setSelectedRows(
              selectLimit && selectedRows.length === selectLimit
                ? selectedRows
                : [...selectedRows, row],
            );
          }
        },
        handleSelectAllRows: () => {
          setSelectedRows(selectLimit ? [...rows].slice(0, selectLimit) : rows);
        },
        handleUnSelectAllRows: () => {
          setSelectedRows([]);
        },
      }),
      [setSelectedRows, selectedRows, rows],
    );

  const selectedIds = useMemo(
    () => selectedRows.map((item) => item.id),
    [selectedRows],
  );

  return {
    selectedRows,
    selectedIds,
    handleSelectRow,
    handleSelectAllRows,
    handleUnSelectAllRows,
  };
}
