import { TableProps, ICellValue } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { RunByCPAndTypePayload } from 'services/reconRunner';
import { ColumnsWithFilters, SelectOption } from 'types';

import { ActionCell } from '../ActionCell/ActionCell';
import { ModifyCounterparty } from '../types';

export const getColumns = ({
  onFilter,
  onRemove,
  modeOptions,
  entityOptions,
  typeOptions,
  runIsDisabled,
  onAction,
}: ColumnsWithFilters & {
  modeOptions: SelectOption[];
  entityOptions: SelectOption[];
  typeOptions: SelectOption[];
  runIsDisabled: boolean;
  onAction: (payload: RunByCPAndTypePayload) => void;
}) => {
  const columns: TableProps<ModifyCounterparty>['columns'] = [
    {
      Header: 'Report date',
      accessor: 'report_date',
      minWidth,
      useLocale: false,
      type: 'dateInput',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Cp',
      accessor: 'name',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Cp type',
      accessor: 'type',
      minWidth,
      type: 'select',
      filterOptions: typeOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'Legal entity',
      accessor: 'legal_entity',
      minWidth,
      type: 'select',
      filterOptions: entityOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'Mode',
      accessor: 'mode',
      minWidth,
      type: 'select',
      filterOptions: modeOptions,
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Action',
      accessor: 'description',
      disableSortBy: true,
      disableFilters: true,
      minWidth: 300,
      Cell: ({ row }: ICellValue<ModifyCounterparty>) => (
        <ActionCell
          value={row.original}
          onAction={onAction}
          buttonIsDisabled={runIsDisabled}
        />
      ),
    },
  ];
  return columns;
};
