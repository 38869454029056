import cn from 'classnames';
import React, {
  useMemo,
  useState,
  FC,
  ChangeEvent,
  MutableRefObject,
} from 'react';
import {
  Button,
  Notification,
  Input,
  useModal,
  Select,
  Modal,
  Autocomplete,
} from 'react-ui-kit-exante';

import { PinnedBlock } from 'components/PinnedBlock';
import { BooleanSelectOptions } from 'constants/forms';
import { useAppSelector } from 'hooks/redux';
import {
  TablesWithBreakReport,
  SIDE,
  CpAndCTrade,
  ReconcileTradesPayload,
  UpdateBreaksPayload,
  updateBreaks,
  reconcileTrades,
} from 'services/recon';
import { personalUserNamesSelector } from 'store/reducers/commonReducer';
import { getSumFromArray } from 'utils/array';
import { getSelectOptions } from 'utils/getSelectOptions';

import classes from './ActionsBlock.module.css';

interface Props {
  reloadReconciledTable: VoidFunction;
  reloadData: (firstPage?: boolean) => void;
  trades: CpAndCTrade[];
  selectedTrades: CpAndCTrade[];
  onUpdateSelectedTrades: (trades: CpAndCTrade[]) => void;
  containerRef: MutableRefObject<null | HTMLDivElement>;
}

const fixNumber = 6;
const selectedMinCount = 2;

export const ActionsBlock: FC<Props> = ({
  reloadData,
  reloadReconciledTable,
  trades,
  selectedTrades,
  onUpdateSelectedTrades,
  containerRef,
}) => {
  const cpWithInfoList = useAppSelector((state) => state.cpList);
  const newCpList = useAppSelector((state) => state.new_counterparty_list);
  const breakCategories = useAppSelector((state) => state.breakCategories);
  const resolutionCategories = useAppSelector(
    (state) => state.resolutionCategories,
  );
  const userNames = useAppSelector(personalUserNamesSelector);

  const {
    isOpened,
    onClose: closeModal,
    onOpen: openModal,
  } = useModal({ defaultOpen: false });
  const [reconcileComment, setReconcileComment] = useState('');
  const [reconcileCategoryId, setReconcileCategoryId] = useState('');
  const [isShowReconcileComment, setIsShowReconcileComment] = useState(false);
  const [breakCategory, setBreakCategory] = useState(0);
  const [breakComment, setBreakComment] = useState('');
  const [assignee, setAssignee] = useState('');
  const [cashRecon, setCashRecon] = useState('');
  const [positionRecon, setPositionRecon] = useState('');
  const [updateBreakIsLoading, setUpdateBreakIsLoading] = useState(false);

  const selectedTheirTrades = useMemo(
    () => selectedTrades.filter((i) => i.side === SIDE.THEIR),
    [selectedTrades],
  );
  const selectedOurTrades = useMemo(
    () => selectedTrades.filter((i) => i.side === SIDE.OUR),
    [selectedTrades],
  );
  const breakCategoryOptions = useMemo(
    () => getSelectOptions(breakCategories, 'name', 'id'),
    [breakCategories],
  );
  const userNamesOptions = useMemo(
    () => getSelectOptions(userNames),
    [userNames],
  );
  const resolutionCategoryOptions = useMemo(
    () => getSelectOptions(resolutionCategories, 'name', 'id'),
    [resolutionCategories],
  );
  const selectedElementsSum = useMemo(
    () =>
      parseFloat(
        Number(
          getSumFromArray(selectedOurTrades, 'value') -
            getSumFromArray(selectedTheirTrades, 'value'),
        ).toFixed(fixNumber),
      ),
    [selectedTrades],
  );

  const selectedTradesQty = useMemo(
    () =>
      parseFloat(
        Number(
          getSumFromArray(selectedOurTrades, 'qty') -
            getSumFromArray(selectedTheirTrades, 'qty'),
        ).toFixed(fixNumber),
      ),
    [selectedTrades],
  );

  const {
    selectedTradesCurrencyIsSame,
    selectedTradesCpIsSame,
    reconcileEnabled,
  } = useMemo(() => {
    const everyIsOneCcy = selectedTrades.every(
      (i) => i.ccy === selectedTrades[0].ccy,
    );
    const everyIsOneCp = selectedTrades.every(
      (i) => i.cpname === selectedTrades[0].cpname,
    );
    const allCpsIgnoreCcy = selectedTrades.reduce((acc: any, item) => {
      const cp = newCpList.find((i) => i.name === item.cpname);
      const cpFromTrade = cpWithInfoList.find(
        (i) => cp?.id === i?.counterparty_id,
      );
      return acc === null
        ? cpFromTrade?.ignore_ccy
        : cpFromTrade?.ignore_ccy && acc;
    }, null);

    return {
      selectedTradesCurrencyIsSame: everyIsOneCcy,
      selectedTradesCpIsSame: everyIsOneCp,
      reconcileEnabled:
        selectedTrades.length >= selectedMinCount &&
        (everyIsOneCcy || allCpsIgnoreCcy),
    };
  }, [selectedTrades, cpWithInfoList, newCpList]);

  const handleCreateBreakCategoryComment = async () => {
    if (!selectedTrades.length) {
      Notification.error({ title: 'Select trades' });
      return;
    }
    setUpdateBreakIsLoading(true);
    const cpTradeIds = selectedTheirTrades.map((i) => i.id);
    const cTradeIds = selectedOurTrades.map((i) => i.id);
    const payload: UpdateBreaksPayload = {
      category: breakCategory,
      comment: breakComment,
      assignee,
      record_types_mapping: {
        [TablesWithBreakReport.CPTRADES]: cpTradeIds,
        [TablesWithBreakReport.CTRADES]: cTradeIds,
      },
    };
    if (cashRecon) {
      payload.cash_recon_affected = cashRecon;
    }
    if (positionRecon) {
      payload.position_recon_affected = positionRecon;
    }
    try {
      const response = await updateBreaks(payload);
      if (!response.skipped_breaks.length) {
        Notification.success({ title: 'Success updated break categories' });
        onUpdateSelectedTrades([]);
      } else {
        Notification.error({
          title: 'Please set a break category for selected records',
        });
        const skippedTrades = response.skipped_breaks.map((id) =>
          trades.find((item) => item.id === id),
        );
        onUpdateSelectedTrades(
          skippedTrades.filter((item) => !!item) as CpAndCTrade[],
        );
      }
      await reloadData();
    } catch (e) {
      Notification.error({ title: 'Error in change break categories process' });
    } finally {
      setUpdateBreakIsLoading(false);
    }
  };

  const handleChangeCategory = (event: React.SyntheticEvent, val: any) => {
    if (val?.value) {
      setBreakCategory(Number(val.value));
    } else {
      setBreakCategory(0);
    }
  };
  const handleChangeComment = (event: ChangeEvent<HTMLInputElement>) => {
    setBreakComment(event.target.value);
  };
  const handleChangeAssignee = (event: React.SyntheticEvent, val: any) => {
    if (val?.value) {
      setAssignee(val.value);
    } else {
      setAssignee('');
    }
  };
  const handleChangeCashRecon = (event: React.SyntheticEvent, val: any) => {
    if (val?.value) {
      setCashRecon(val?.value);
    } else {
      setCashRecon('');
    }
  };
  const handleChangePositionRecon = (event: React.SyntheticEvent, val: any) => {
    if (val?.value) {
      setPositionRecon(val?.value);
    } else {
      setPositionRecon('');
    }
  };
  const handleChangeReconcileComment = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setReconcileComment(event.target.value);
  };
  const handleChangeReconcileCategory = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setReconcileCategoryId(event.target.value);
  };
  const handleReconcile = async () => {
    closeModal();
    try {
      const payload: ReconcileTradesPayload = {
        ctrade_ids: selectedOurTrades.map((i) => i.id),
        cptrade_ids: selectedTheirTrades.map((i) => i.id),
      };
      if (reconcileComment.length) {
        payload.comment = reconcileComment;
      }
      if (reconcileCategoryId.length) {
        payload.resolution_category_id = reconcileCategoryId;
      }
      await reconcileTrades(payload);
      Notification.success({ title: 'Trades reconciled' });
      await reloadData(true);
      await reloadReconciledTable();
      onUpdateSelectedTrades([]);
    } catch (e) {
      Notification.error({
        title: 'Reconcile error',
      });
    } finally {
      setReconcileComment('');
      setReconcileCategoryId('');
    }
  };
  const handleOpenReconcileConfirm = async () => {
    setIsShowReconcileComment(true);
    openModal();
  };
  const handleCloseModal = () => {
    closeModal();
    setIsShowReconcileComment(false);
  };
  const confirmReconcileIsDisabled = useMemo(
    () => isShowReconcileComment && !reconcileCategoryId,
    [isShowReconcileComment, reconcileCategoryId],
  );
  const updateBreakIsDisabled = useMemo(
    () =>
      !selectedTrades.length ||
      (!assignee &&
        !breakCategory &&
        !breakComment &&
        !cashRecon &&
        !positionRecon) ||
      updateBreakIsLoading,
    [
      selectedTrades,
      assignee,
      breakCategory,
      breakComment,
      cashRecon,
      positionRecon,
      updateBreakIsLoading,
    ],
  );

  return (
    <PinnedBlock containerRef={containerRef}>
      <p className="m-0 mt-2 text-left">Update break on selected: </p>
      <div>
        <div className="my-2">
          <div className={classes.actionBlockItems}>
            <div className={classes.actionBlockItem}>
              <Autocomplete
                placeholder="Break Category"
                className="w-100"
                options={breakCategoryOptions}
                onChange={handleChangeCategory}
              />
            </div>
            <div className={classes.actionBlockItem}>
              <Input
                label="Comment"
                fullWidth
                value={breakComment}
                onChange={handleChangeComment}
              />
            </div>
            <div className={classes.actionBlockItem}>
              <Autocomplete
                placeholder="Assignee"
                className="w-100"
                options={userNamesOptions}
                onChange={handleChangeAssignee}
              />
            </div>
            <div className={classes.actionBlockItem}>
              <Autocomplete
                placeholder="Cash affected"
                className="w-100"
                options={BooleanSelectOptions}
                onChange={handleChangeCashRecon}
              />
            </div>
            <div className={classes.actionBlockItem}>
              <Autocomplete
                placeholder="Position affected"
                className="w-100"
                options={BooleanSelectOptions}
                onChange={handleChangePositionRecon}
              />
            </div>
            <div className={classes.actionBlockItem}>
              <Button
                fullWidth
                disabled={updateBreakIsDisabled}
                onClick={handleCreateBreakCategoryComment}
                color="red"
              >
                Update Break
              </Button>
            </div>
            <div className={classes.actionBlockItem}>
              <Button
                fullWidth
                disabled={!reconcileEnabled}
                onClick={handleOpenReconcileConfirm}
              >
                Reconcile
              </Button>
            </div>
          </div>
        </div>
        {selectedTrades.length ? (
          <div className="row">
            <div className="col-12">
              <div
                className={cn({
                  'my-2': true,
                  alert: true,
                  'w-100': true,
                  'alert-danger': Number(selectedElementsSum) !== 0,
                  'alert-primary': Number(selectedElementsSum) === 0,
                })}
              >
                Value difference between selected trades is{' '}
                <b>{selectedElementsSum}</b>
              </div>
              <div className="mb-2 w-100 alert alert-primary">
                Quantity difference <b>{selectedTradesQty}</b>
              </div>
              {!selectedTradesCurrencyIsSame ? (
                <div className="alert alert-danger my-2 w-100">
                  CCY is different!
                </div>
              ) : null}
              {!selectedTradesCpIsSame ? (
                <div className="alert alert-danger my-2 w-100">
                  CP is different!
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      <Modal
        isOpened={isOpened}
        title="Resolution"
        onClose={handleCloseModal}
        confirmButton={{
          confirmButtonName: 'Confirm',
          handleConfirm: handleReconcile,
          confirmButtonIsDisabled: confirmReconcileIsDisabled,
        }}
      >
        {isShowReconcileComment && (
          <>
            {selectedTradesQty !== 0 && (
              <p className="my-0">Quantity difference is {selectedTradesQty}</p>
            )}
            {selectedElementsSum !== 0 && (
              <p className="my-0">Value difference is {selectedElementsSum}</p>
            )}
            <p className="my-0">
              If you still want to reconcile this objects, please provide
              comment in field below and press Confirm button
            </p>
            <Input
              label="Comment"
              fullWidth
              value={reconcileComment}
              onChange={handleChangeReconcileComment}
            />
            <Select
              label="Category"
              className="mt-3 w-100"
              value={reconcileCategoryId}
              options={resolutionCategoryOptions}
              onChange={handleChangeReconcileCategory}
            />
          </>
        )}
        <p>Do you want to reconcile them?</p>
      </Modal>
    </PinnedBlock>
  );
};
