import { TCommonParams } from 'react-ui-kit-exante';

import { SortingTableObject } from '../../types';

export const getSortingParams = (sorting: TCommonParams['sorting']) =>
  (sorting as SortingTableObject[]).reduce((acc, item) => {
    const noEmpty = !!Object.values(item).length;
    const direction = item.desc ? 'desc' : 'asc';
    return noEmpty ? `${acc}order=${direction}&order_by=${item.id}&` : acc;
  }, '');

export const getMultiSortingParams = (sorting: TCommonParams['sorting']) =>
  (sorting as SortingTableObject[]).reduce((acc, item) => {
    const noEmpty = !!Object.values(item).length;
    const direction = item.desc ? 'desc' : 'asc';
    const prevValue = !acc ? '' : ',';
    return noEmpty ? `${acc}${prevValue}${item.id}_${direction}` : acc;
  }, '');
