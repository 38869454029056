import { getUrlFromPayload } from '../../utils';
import { ReconApiService } from '../api';

import { GetOrderStatus, OrderStatusResponse } from './types';

export async function getOrderStatus(payload: GetOrderStatus) {
  return ReconApiService.getResource<OrderStatusResponse>(
    getUrlFromPayload('order-status/?', payload),
  );
}
