import React, { FC, useMemo, useState } from 'react';
import { Checkbox, IconButton, Table } from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Eye } from 'icons/eye.svg';
import { RawDataReportSetup } from 'services/recon';

import { ModuleConfigsModal } from './components';
import { columns } from './consts';
import { mapRawData } from './utils';

interface IProps {
  items: RawDataReportSetup[];
  cp: string;
  le: string;
  onShowMappingReports: (name: string) => void;
  onOpenCreating: VoidFunction;
  onOpenUpdating: (setupName: string) => void;
}

const displayedColumnKeys = [
  'id',
  'name',
  'active_from',
  'active_to',
  'module',
  'module_configs',
];
export const RawDataTable: FC<IProps> = ({
  items,
  cp,
  le,
  onOpenCreating,
  onOpenUpdating,
  onShowMappingReports,
}) => {
  const [selectedRawDataForShowing, setSelectedRawDataForShowing] =
    useState<null | RawDataReportSetup>(null);
  const title = useMemo(() => `${le} - ${cp} - Raw data `, [cp, le]);

  const handleCloseShowingModuleConfigs = () => {
    setSelectedRawDataForShowing(null);
  };
  const handleRowClick = (setup: RawDataReportSetup) => {
    onShowMappingReports(setup.name);
  };
  const handleOpenShowingConfigs = (setup: RawDataReportSetup) => {
    setSelectedRawDataForShowing(
      items.find((item) => item.id === setup.id) ?? null,
    );
  };

  const [isShowInActive, setIsShowInActive] = useState(false);
  const additionalActions = [
    {
      component: (
        <Checkbox
          className="ml-3"
          label="Show inActive"
          checked={isShowInActive}
          onChange={(_, checked) => {
            setIsShowInActive(checked);
          }}
        />
      ),
      onClick: () => {},
      title: 'Show inActive',
    },
    {
      component: (
        <IconButton
          iconColor="action"
          iconName="AddIcon"
          label="Add raw data"
          onClick={onOpenCreating}
        />
      ),
      onClick: () => {},
      title: 'Add raw data',
    },
  ];

  const mappedItems = useMemo(
    () =>
      items
        .map(mapRawData)
        .filter((item) => (isShowInActive ? true : item.isActive)),
    [items, isShowInActive],
  );

  return (
    <>
      <Table
        tableId="rawDataTableForSetup"
        displayedColumnKeys={displayedColumnKeys}
        title={title}
        locale={defaultLocale}
        columns={columns}
        data={mappedItems}
        pageSizes={pageSizes}
        pageSize={5}
        handleRowClick={handleRowClick}
        additionalActions={additionalActions}
        rowActions={{
          show: true,
          isEditedRow: () => false,
          additionalActions: [
            {
              label: <Edit />,
              title: 'Update',
              onClick: (raw) => onOpenUpdating(raw.name),
            },
            {
              label: <Eye />,
              title: 'Show configs',
              onClick: handleOpenShowingConfigs,
            },
          ],
        }}
        showTableInfo
        isFlexLayout
        hasPagination
      />
      {!!selectedRawDataForShowing && (
        <ModuleConfigsModal
          onClose={handleCloseShowingModuleConfigs}
          items={selectedRawDataForShowing.module_configs}
          rawDataName={selectedRawDataForShowing.name}
        />
      )}
    </>
  );
};
