export const types = [
  'Total Cash',
  'Total Equity',
  'Used Margin',
  'Net Free Equity',
  'Other payables and liabilities',
  'Other assets from other sources',
  'Commissions Receivable Amount',
  'Cash Reconciliation Adjustment',
  'Money in segregated accounts',
  'Accounts receivable from group companies',
  'Paid-up share capital',
  'Retained profits',
];
export const categoryValues = [
  { label: 'CLIENT', value: 'CLIENT' },
  { label: 'CORPORATE', value: 'CORPORATE' },
];
export const manualSourceFile = 'recon-ui';
