import * as cookies from 'js-cookie';

import {
  SESSION_ID_RUN_UI_RECON_KEY,
  ACCESS_TOKEN_COOKIE_KEY,
  AUTHORIZATION_HEADER_KEY,
  SESSION_ID_HEADER_KEY,
} from 'constants/session';

import { TAccessToken } from './types';

class BaseApiService {
  public apiBase = '';

  getApiBase = (domain: string, remoteEndpointPrefix?: string) => {
    const isLocal = window.location.hostname.includes('localhost');
    const proto = isLocal ? 'https:' : window.location.protocol;
    return `${proto}//${domain}${
      remoteEndpointPrefix ? `/${remoteEndpointPrefix}/` : '/'
    }api/v1/`;
  };

  getBaseHeaders = () => {
    const headers: Record<string, string> = {};
    const reconSessionId = cookies.get(SESSION_ID_RUN_UI_RECON_KEY) ?? '';
    let authToken = '';

    const googleAuthCookie = cookies.get(ACCESS_TOKEN_COOKIE_KEY);
    if (googleAuthCookie) {
      try {
        const { access_token: token }: TAccessToken = JSON.parse(
          cookies.get(ACCESS_TOKEN_COOKIE_KEY) ?? '',
        );
        authToken = token;
      } catch (error) {
        console.error(`Access token error: ${error}`);
      }
    }

    if (reconSessionId) {
      headers[SESSION_ID_HEADER_KEY] = reconSessionId;
    }
    if (authToken) {
      headers[AUTHORIZATION_HEADER_KEY] = `Bearer ${authToken}`;
    }
    return headers;
  };

  async getResource<R>(url: string): Promise<R> {
    const options = {
      method: 'GET',
      headers: this.getBaseHeaders(),
    };
    const res = await fetch(`${this.apiBase}${url}`, options);
    if (!res.ok) {
      const errorObject = await res.json();

      throw errorObject;
    }

    return res.json();
  }

  async postResource<T, R>(url: string, payload?: T): Promise<R> {
    const res = await fetch(`${this.apiBase}${url}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        ...this.getBaseHeaders(),
      },
    });

    const responseJson = await res.json();
    if (!res.ok) {
      throw new Error(JSON.stringify(responseJson));
    }

    return responseJson;
  }

  async patch<T>(url: string, payload: T) {
    const res = await fetch(`${this.apiBase}${url}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        ...this.getBaseHeaders(),
      },
    });

    const responseJson = await res.json();
    if (!res.ok) {
      throw new Error(JSON.stringify(responseJson));
    }

    return responseJson;
  }

  async delete<T>(url: string, payload?: T) {
    const res = await fetch(`${this.apiBase}${url}`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        ...this.getBaseHeaders(),
      },
    });

    if (!res.ok) {
      throw new Error(`Update failed: ${res.status}`);
    }

    return res;
  }
}

export default BaseApiService;
