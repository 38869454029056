import { FC, useEffect, useState } from 'react';
import { Notification, Table } from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import { getScriptsExecutionsInfo } from 'services/reconRunner';

import { columns } from './consts';
import { getRowProps, getCellProps } from './utils';

export const RunInfoPage: FC = () => {
  const [items, setItems] = useState<any[]>([]);
  const [itemsIsLoading, setItemsIsLoading] = useState(false);

  const getInfos = async () => {
    try {
      setItemsIsLoading(true);
      const response = await getScriptsExecutionsInfo();
      setItems(response);
    } catch (error) {
      Notification.error({ title: 'Error', description: String(error) });
    } finally {
      setItemsIsLoading(false);
    }
  };
  useEffect(() => {
    getInfos();
  }, []);

  return (
    <div className="mui-container-fluid">
      <div className="mui-row">
        <div className="mui-col-md-12">
          <Table
            title="Run info"
            tableId="run_info_table"
            getRowProps={getRowProps}
            getCellProps={getCellProps}
            showTableInfo
            hasPagination
            hasFilters
            isFlexLayout
            showScrollbar
            locale={defaultLocale}
            defaultSortBy={[]}
            pageSize={10}
            data={items}
            columns={columns}
            isLoading={itemsIsLoading}
            pageSizes={pageSizes}
          />
        </div>
      </div>
    </div>
  );
};
