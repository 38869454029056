import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  IOnFetchArguments,
  ISortBy,
  Table,
  Panel,
  useTableData,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks/redux';
import { CpAndCTrade, getTradeBreakItems } from 'services/recon';
import { TableDataWithPagination } from 'types';
import { calculateCountOfPages } from 'utils';
import { getSelectOptions } from 'utils/getSelectOptions';

import { DetailedTableType } from '../types';
import { getTableTitle } from '../utils/getTableTitle';

import { getColumns } from './utils/getColumns';

export const TradesTable: FC<DetailedTableType> = ({
  cpFilter,
  leFilter,
  sideFilter,
  categoryFilter,
  modeFilter,
}) => {
  const counterParties = useAppSelector((state) => state.new_counterparty_list);
  const entities = useAppSelector(
    (state) => state.new_legal_entity_lists.broker,
  );

  const entityOptions = useMemo(() => getSelectOptions(entities), [entities]);
  const cpOptions = useMemo(
    () => getSelectOptions(counterParties.map((i) => i.name)),
    [counterParties],
  );

  const breakCategories = useAppSelector((state) => state.breakCategories);
  const categoryId = useMemo(
    () =>
      breakCategories
        .find((item) => item.name === categoryFilter)
        ?.id.toString(),
    [breakCategories, categoryFilter],
  );

  const getTableData = useCallback(
    (props: IOnFetchArguments) =>
      getTradeBreakItems(props, {
        cpFilter,
        leFilter,
        sideFilter,
        categoryFilter: categoryId ?? '',
        modeFilter,
      }),
    [cpFilter, leFilter, modeFilter, sideFilter, categoryFilter],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTableData },
      pagination: { getDefaultPagination: () => ({ limit: 5, skip: 0 }) },
    }),
    [getTableData],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    setSorting,
  } = useTableData<TableDataWithPagination<CpAndCTrade>>(tableData);

  useEffect(() => {
    setPage(0);
  }, [sideFilter, cpFilter, leFilter]);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );
  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        entityOptions,
        cpOptions,
      }),
    [setFilter, removeFilter, entityOptions, cpOptions],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const tableTile = useMemo(
    () => getTableTitle({ leFilter, sideFilter, categoryFilter, cpFilter }),
    [leFilter, categoryFilter, sideFilter, cpFilter],
  );
  return (
    <div className="mui-row">
      <div className="mui-col-md-12">
        <Panel title={tableTile}>
          <Table
            tableId="tradeBreaksTable"
            showTableInfo
            hasPagination
            manualSortBy
            isFlexLayout
            showScrollbar
            locale={defaultLocale}
            data={data?.items ?? []}
            columns={columns}
            isLoading={isLoading}
            pageSizes={pageSizes}
            onSort={handleSorting}
            defaultSortBy={[]}
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total: data?.pagination.total || 0,
              setPage,
              setPageSize: setLimit,
            }}
          />
        </Panel>
      </div>
    </div>
  );
};
