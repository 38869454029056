import dayjs from 'dayjs';
import { IColumn, styled } from 'react-ui-kit-exante';

import { SelectOption } from 'types';

import { ScheduleItem } from '../types';

interface ColumnPayload {
  dates: Omit<ScheduleItem, 'le'>;
  userOptions: SelectOption[];
}
const minWidth = 80;
const today = dayjs()
  .set('hour', 0)
  .set('minutes', 0)
  .set('second', 0)
  .set('ms', 0);

const getCellIsEditable = (date: string) =>
  dayjs(date).isAfter(today) || dayjs(date).isSame(today);

const HeaderWeekendLabel = styled('div')(({ theme }) => ({
  color: theme.color.typo.radical,
}));
export function getColumns({ dates, userOptions }: ColumnPayload) {
  const columns: IColumn<ScheduleItem>[] = [
    {
      Header: 'Legal Entity',
      accessor: 'le',
      width: 100,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: (
        <div>
          <div>{dates.firstDay}</div>
          <div>Mon</div>
        </div>
      ),
      accessor: 'firstDay',
      minWidth,
      editParams: {
        inputType: 'select',
        options: userOptions,
        editable: () => getCellIsEditable(dates.firstDay),
      },
      disableFilters: true,
      disableSortBy: true,
      className: 'justify-content-center',
    },
    {
      Header: (
        <div>
          <div>{dates.secondDay}</div>
          <div>Tue</div>
        </div>
      ),
      accessor: 'secondDay',
      minWidth,
      editParams: {
        inputType: 'select',
        options: userOptions,
        editable: () => getCellIsEditable(dates.secondDay),
      },
      disableFilters: true,
      disableSortBy: true,
      className: 'justify-content-center',
    },
    {
      Header: (
        <div>
          <div>{dates.thirdDay}</div>
          <div>Wed</div>
        </div>
      ),
      accessor: 'thirdDay',
      minWidth,
      editParams: {
        inputType: 'select',
        options: userOptions,
        editable: () => getCellIsEditable(dates.thirdDay),
      },
      disableFilters: true,
      disableSortBy: true,
      className: 'justify-content-center',
    },
    {
      Header: () => (
        <div>
          <div>{dates.fourthDay}</div>
          <div>Thu</div>
        </div>
      ),
      accessor: 'fourthDay',
      minWidth,
      editParams: {
        inputType: 'select',
        options: userOptions,
        editable: () => getCellIsEditable(dates.fourthDay),
      },
      disableFilters: true,
      disableSortBy: true,
      className: 'justify-content-center',
    },
    {
      Header: () => (
        <div>
          <div>{dates.fifthDay}</div>
          <div>Fri</div>
        </div>
      ),
      accessor: 'fifthDay',
      minWidth,
      editParams: {
        inputType: 'select',
        options: userOptions,
        editable: () => getCellIsEditable(dates.fifthDay),
      },
      disableFilters: true,
      disableSortBy: true,
      className: 'justify-content-center',
    },
    {
      Header: () => (
        <HeaderWeekendLabel>
          <div>{dates.sixthDay}</div>
          <div>Sat</div>
        </HeaderWeekendLabel>
      ),
      accessor: 'sixthDay',
      minWidth,
      editParams: {
        inputType: 'select',
        options: userOptions,
        editable: () => getCellIsEditable(dates.sixthDay),
      },
      disableFilters: true,
      disableSortBy: true,
      className: 'justify-content-center',
    },
    {
      Header: (
        <HeaderWeekendLabel>
          <div>{dates.seventhDay}</div>
          <div>Sun</div>
        </HeaderWeekendLabel>
      ),
      accessor: 'seventhDay',
      minWidth,
      editParams: {
        inputType: 'select',
        options: userOptions,
        editable: () => getCellIsEditable(dates.seventhDay),
      },
      disableFilters: true,
      disableSortBy: true,
      className: 'justify-content-center',
    },
  ];
  return columns;
}
