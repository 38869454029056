import { ComparisonType } from 'services/recon';

import { FieldMappingItem, FieldMappingConditionItem } from './types';

export const emptyCondition: Omit<
  FieldMappingConditionItem,
  'uuid' | 'mappingId'
> = {
  condition_field: '',
  condition_value: '',
  comparison_type: '' as ComparisonType,
  isNew: false,
};

export const emptyFieldMapping: Omit<FieldMappingItem, 'uuid'> = {
  cp_field: '',
  mapping_field: '',
  skip: '',
  date_format: '',
  condition_operations: '',
  isNew: false,
};

export interface FormDataValue {
  name: string;
  reportType: string;
  counterpartyName: string;
  legalEntityName: string;
  modeName: string;
  setups: { label: string; value: number }[];
}
export const formDataInitialValue: FormDataValue = {
  name: '',
  reportType: '',
  counterpartyName: '',
  legalEntityName: '',
  modeName: '',
  setups: [],
};
