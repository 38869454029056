import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Notification } from 'react-ui-kit-exante';

import {
  getCategoryList,
  getListMappingReportType,
  getListOperationType,
  getBreakCommentCategories,
  getListCurrency,
  getListCPMapping,
  getResolutionCategories,
  getCurrentUser,
  getModeList,
  getCounterpartyList,
  getLegalEntityList,
  getReconUserList,
  getCpByEntity,
  getListReportState,
  getTransactionCategoriesByOperationTypes,
  getCpTypes,
} from 'services/recon';
import { commonSlice } from 'store/reducers/commonReducer';

export const MetaDataFetcher: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const isFirstRequest = useRef(true);

  const loadStoreData = async () => {
    try {
      dispatch(
        commonSlice.actions.setCurrentUser({ isLoading: true, value: null }),
      );
      const reconUserResp = await getCurrentUser();
      dispatch(
        commonSlice.actions.setCurrentUser({
          isLoading: false,
          value: reconUserResp,
        }),
      );
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch recon user. Please reload.',
      });
    }

    try {
      const modeListResponse = await getModeList();
      dispatch(commonSlice.actions.setModeList(modeListResponse.results));
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch mode list. Please reload.',
      });
    }

    try {
      const categoriesByTypesResponse =
        await getTransactionCategoriesByOperationTypes();
      dispatch(
        commonSlice.actions.setTransactionCategoriesByOperationTypes(
          categoriesByTypesResponse,
        ),
      );
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch transaction categories. Please reload.',
      });
    }

    try {
      dispatch(commonSlice.actions.setCpListIsLoading(true));
      const newCounterpartiesResp = await getCounterpartyList();
      dispatch(commonSlice.actions.setNewCpList(newCounterpartiesResp.results));

      const cpByEntityResp = await getCpByEntity();
      dispatch(commonSlice.actions.setCpListsByEntities(cpByEntityResp));
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch counterparties. Please reload.',
      });
    } finally {
      dispatch(commonSlice.actions.setCpListIsLoading(false));
    }

    try {
      dispatch(commonSlice.actions.setLEListIsLoading(true));
      const leForBrokersAndBanksResp = await getLegalEntityList(true);
      dispatch(
        commonSlice.actions.setNewBrokerAndBankLEList(
          leForBrokersAndBanksResp.results,
        ),
      );
      const leForBrokersResp = await getLegalEntityList();
      dispatch(
        commonSlice.actions.setNewBrokerLEList(leForBrokersResp.results),
      );
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch legal entities. Please reload.',
      });
    } finally {
      dispatch(commonSlice.actions.setLEListIsLoading(false));
    }

    try {
      const cpTypesResp = await getCpTypes();
      dispatch(commonSlice.actions.setCpTypes(cpTypesResp.results));
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch counterparties types. Please reload.',
      });
    }

    try {
      dispatch(commonSlice.actions.setIsLoadingBreakCategoryList(true));
      const breakCategoriesResp = await getBreakCommentCategories({});
      dispatch(
        commonSlice.actions.setBreakCategoryList(breakCategoriesResp.results),
      );
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch break categories. Please reload.',
      });
    } finally {
      dispatch(commonSlice.actions.setIsLoadingBreakCategoryList(false));
    }

    try {
      const reconUserListResp = await getReconUserList({
        all: true,
      });
      dispatch(commonSlice.actions.setReconUserList(reconUserListResp.content));
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch recon users. Please reload.',
      });
    }

    try {
      const reconUserListResp = await getReconUserList({});
      dispatch(
        commonSlice.actions.setPersonalReconUserList(reconUserListResp.content),
      );
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch recon personal users. Please reload.',
      });
    }

    try {
      const resolutionCategoriesResp = await getResolutionCategories();
      dispatch(
        commonSlice.actions.setResolutionCategories(
          resolutionCategoriesResp.results,
        ),
      );
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch resolution categories. Please reload.',
      });
    }

    try {
      const cpMappingListResp = await getListCPMapping();
      dispatch(commonSlice.actions.setCpList(cpMappingListResp.results));
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch cp mapping. Please reload.',
      });
    }

    try {
      const ccyListResp = await getListCurrency();
      const ccyList = new Set<string>();
      ccyListResp.results.forEach((entry) => {
        ccyList.add(entry.name);
      });
      dispatch(commonSlice.actions.setCcyList(Array.from(ccyList)));
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch currency list. Please reload.',
      });
    }

    try {
      const operationTypesResp = await getListOperationType();
      const operationTypeList = new Set<string>();
      operationTypesResp.results.forEach((entry) => {
        operationTypeList.add(entry.name);
      });
      dispatch(
        commonSlice.actions.setOperationTypeList(Array.from(operationTypeList)),
      );
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch operation types. Please reload.',
      });
    }

    try {
      const reportTypesResp = await getListMappingReportType();
      dispatch(commonSlice.actions.setReportTypeList(reportTypesResp.results));
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch report types. Please reload.',
      });
    }

    try {
      const reportStatesResp = await getListReportState();
      dispatch(
        commonSlice.actions.setReportStateList(reportStatesResp.results),
      );
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch report states. Please reload.',
      });
    }

    try {
      const categoriesResp = await getCategoryList();
      dispatch(commonSlice.actions.setCategoriesList(categoriesResp.results));
    } catch (e) {
      Notification.error({
        title: 'Failed to fetch categories. Please reload.',
      });
    }
  };

  useEffect(() => {
    if (isFirstRequest.current) {
      isFirstRequest.current = false;
      loadStoreData();
    }
  }, []);

  return <div>{children}</div>;
};
