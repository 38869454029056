import React, { FC, useCallback } from 'react';
import { Checkbox as UIKitCheckbox } from 'react-ui-kit-exante';

import { Types } from 'services/recon/monitoring';

import { typeLabels } from '../constants';

export const Checkbox: FC<{
  type: Types;
  dataTypes: Types[];
  setDataTypes: (val: Types[]) => void;
}> = ({ dataTypes, type, setDataTypes }) => {
  const handleChange = useCallback(() => {
    if (dataTypes.includes(type) && dataTypes.length === 1) {
      return;
    }
    setDataTypes(
      dataTypes.includes(type)
        ? dataTypes.filter((item) => item !== type)
        : [...dataTypes, type],
    );
  }, [type, dataTypes, setDataTypes]);

  return (
    <UIKitCheckbox
      checked={dataTypes.includes(type)}
      onChange={handleChange}
      label={typeLabels[type]}
      className="mr-3"
    />
  );
};
