import { SyntheticEvent } from 'react';
import { ICellValue, IColumn, ISelectOption } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { Script } from 'services/reconRunner';
import { getCellCheckbox } from 'utils';

import { ModalParams } from '../types';

export const getColumns = ({
  onChangeModalParams,
  scriptTypes,
  rows,
  groups,
  selectedIds,
  onSelectRow,
}: {
  onChangeModalParams: (params: ModalParams) => void;
  scriptTypes: ISelectOption[];
  groups: ISelectOption[];
  rows: Script[];
  selectedIds: number[];
  onSelectRow: (value: Script) => void;
}) => {
  const columns: IColumn<Script>[] = [
    {
      accessor: 'id',
      minWidth: 60,
      Header: 'Select',
      Cell: ({ row }: ICellValue<Script>) =>
        getCellCheckbox<Script>(
          row.values.id,
          rows,
          selectedIds,
          onSelectRow,
          (item) => item.id,
        ),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 140,
    },
    {
      Header: 'Group',
      accessor: 'group',
      type: 'select',
      filterOptions: groups,
      minWidth: 120,
    },
    {
      Header: 'Script type',
      accessor: 'script_type',
      type: 'select',
      filterOptions: scriptTypes,
      minWidth: 120,
    },
    {
      Header: 'Current state',
      accessor: 'current_state',
      minWidth: 80,
      disableFilters: true,
    },
    {
      Header: 'Args',
      accessor: 'args_str',
      width: 500,
      disableFilters: true,
    },
    {
      Header: 'Last run time',
      accessor: 'last_run_time',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Last success time',
      accessor: 'last_success_time',
      minWidth,
      disableFilters: true,
    },

    {
      Header: 'Std out',
      accessor: 'post_args_delay',
      disableSortBy: true,
      disableFilters: true,
      minWidth: 50,
      Cell: (cell: ICellValue<Script>) => {
        const handleClickOnLink = (event: SyntheticEvent) => {
          event.preventDefault();
          onChangeModalParams({
            title: 'Std out',
            isShow: true,
            scriptName: cell?.row.original.compose_name,
            std: 'stdout',
          });
        };
        return (
          <div>
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="" onClick={handleClickOnLink}>
                Show
              </a>
            }
          </div>
        );
      },
    },
    {
      Header: 'Std err',
      accessor: 'start_time',
      disableSortBy: true,
      disableFilters: true,
      minWidth: 50,
      Cell: (cell: ICellValue<Script>) => {
        const handleClickOnLink = (event: SyntheticEvent) => {
          event.preventDefault();
          onChangeModalParams({
            title: 'Std err',
            isShow: true,
            scriptName: cell?.row.original.compose_name,
            std: 'stderr',
          });
        };
        return (
          <div>
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="" onClick={handleClickOnLink}>
                Show
              </a>
            }
          </div>
        );
      },
    },
    {
      Header: 'Py exception',
      accessor: 'end_time',
      disableSortBy: true,
      disableFilters: true,
      minWidth: 50,
      Cell: (cell: ICellValue<Script>) => {
        const handleClickOnLink = (event: SyntheticEvent) => {
          event.preventDefault();
          onChangeModalParams({
            title: 'Py exception',
            isShow: true,
            scriptName: cell?.row.original.compose_name,
            std: 'exception',
          });
        };
        return (
          <div>
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="" onClick={handleClickOnLink}>
                Show
              </a>
            }
          </div>
        );
      },
    },
  ];
  return columns;
};
