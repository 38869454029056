import { IColumn } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { Transaction } from 'services/recon';
import { ColumnsWithFilters } from 'types';

export const getColumns = ({
  onFilter,
  onRemove,
}: ColumnsWithFilters): IColumn<Transaction>[] => [
  {
    Header: 'Cp',
    accessor: 'cpname',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Legal Entity',
    accessor: 'legal_entity_name',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Side',
    accessor: 'side',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Report date',
    accessor: 'reportdate',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    minWidth,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    disableFilters: true,
  },
  {
    Header: 'Ccy',
    accessor: 'ccy',
    minWidth,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    disableFilters: true,
  },
  {
    Header: 'Assignee',
    accessor: 'assignee',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Value date',
    accessor: 'value_date',
    minWidth,
    onFilter,
    onRemove,
  },
  {
    Header: 'Category',
    accessor: 'break_report_category',
    minWidth,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    disableFilters: true,
  },
  {
    Header: 'Transaction id',
    accessor: 'transaction_id',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Operation Type',
    accessor: 'operationtype',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Сlient id',
    accessor: 'client_account_id',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Symbol type',
    accessor: 'symboltype',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'ISIN',
    accessor: 'isin',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Account',
    accessor: 'account',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Comment',
    accessor: 'break_comment',
    minWidth,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    disableFilters: true,
  },
];
