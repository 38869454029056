import dayjs from 'dayjs';
import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  Notification,
  Table,
  InputDatePicker,
  Select,
  Button,
  Panel,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import { pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks/redux';
import { ExpectedSymbolMapping, searchOneToOneMapping } from 'services/recon';
import { newCounterPartyNames } from 'store/reducers/commonReducer';
import { getSelectOptions } from 'utils/getSelectOptions';

import { getColumns } from './AutoMappingUtils';

export const AutoMapping = () => {
  const counterPartyNames = useAppSelector(newCounterPartyNames);
  const [dateField, setDateField] = useState<Date | null>(null);
  const [cp, setCp] = useState('');
  const [items, setItems] = useState<ExpectedSymbolMapping[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await searchOneToOneMapping(
        dayjs(dateField).format(DATE_FORMAT),
        cp,
      );
      setItems(response);
    } catch (error) {
      if (!dateField) {
        Notification.error({ title: 'Need to fill "Date"' });
      } else if (!cp) {
        Notification.error({ title: 'Need to fill "Counterparty"' });
      } else {
        Notification.error({ title: 'Load mapping error' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeDate = (date: Date | null) => {
    if (date) {
      setDateField(date);
    }
  };
  const handleChangeCp = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setCp(value);
  };
  const cpOptions = useMemo(
    () => getSelectOptions(counterPartyNames),
    [counterPartyNames],
  );
  const columns = useMemo(
    () => getColumns(dayjs(dateField).format(DATE_FORMAT)),
    [dateField],
  );

  return (
    <div className="container-fluid">
      <Panel title="Find symbol mapping">
        <div className="d-flex">
          <div className="mr-2">
            <InputDatePicker
              locale={defaultLocale}
              onChange={handleChangeDate}
              inputProps={{
                label: 'Date',
              }}
              selected={dateField}
            />
          </div>
          <div style={{ minWidth: '200px' }} className="mr-2">
            <Select
              label="Counterparty"
              fullWidth
              value={cp}
              onChange={handleChangeCp}
              options={cpOptions}
            />
          </div>
          <Button onClick={() => getData()}>Load</Button>
        </div>
      </Panel>
      <Table
        tableId="AutoMappingTable"
        data={items}
        pageSizes={pageSizes}
        pageSize={10}
        isLoading={isLoading}
        manualSortBy
        defaultSortBy={[]}
        columns={columns}
        locale={defaultLocale}
        showTableInfo
        isFlexLayout
        hasPagination
      />
    </div>
  );
};
