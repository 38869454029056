import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { Table, Input, IRow, ICellValue } from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import { PositionReconciliation } from 'services/recon';
import { theme } from 'theme';

import SearchForm from './SearchForm';
import { useTablesData } from './hooks';
import { getPositionsColumns, getCandidatesColumns } from './utils';

export const PositionMappingPage: FC = () => {
  const {
    fetchPositions,
    positions,
    positionsIsLoading,
    selectedPosition,

    fetchMappingsForPosition,
    candidatesIsLoading,
    candidates,
    postMapping,

    mty,
    handleChangeMty,
  } = useTablesData();

  const positionsColumns = useMemo(
    () => getPositionsColumns({ searchMapping: fetchMappingsForPosition }),
    [fetchMappingsForPosition],
  );

  const candidatesColumns = useMemo(
    () => getCandidatesColumns(postMapping),
    [postMapping],
  );
  const preventHandle: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const additionalActions = useMemo(
    () => [
      {
        children: (
          <div>
            <Input
              label="Qty Mty"
              className="mr-3"
              value={mty}
              onChange={handleChangeMty}
            />
          </div>
        ),
        onClick: preventHandle,
        title: 'Qty Mty',
      },
    ],
    [mty],
  );

  const getRowProps = useCallback(
    (row: IRow<PositionReconciliation>) => {
      if (selectedPosition?.id === row.original.id) {
        return {
          style: {
            background: theme?.color.table.bg.source,
          },
        };
      }
      return {
        style: {},
      };
    },
    [selectedPosition],
  );
  const getCellProps = useCallback(
    (cell: ICellValue<PositionReconciliation>) => ({
      style: {
        ...getRowProps(cell.row).style,
        boxShadow: `0 0 0 ${theme?.color.table.bg.basic2}`,
      },
    }),
    [getRowProps],
  );

  return (
    <div className="mui-container-fluid">
      <SearchForm onSearch={fetchPositions} />
      <Table
        tableId="positionsTable"
        title="Positions"
        singleColored
        locale={defaultLocale}
        data={positions}
        pageSizes={pageSizes}
        pageSize={10}
        isLoading={positionsIsLoading}
        additionalActions={additionalActions}
        getRowProps={getRowProps}
        getCellProps={getCellProps}
        columns={positionsColumns}
        manualSortBy
        isFlexLayout
        hasPagination
      />
      <p className="text-left mt-1">Search mappings by position: </p>
      <Table
        tableId="positionCandidatesTable"
        title="Position mappings"
        locale={defaultLocale}
        data={candidates}
        pageSizes={pageSizes}
        pageSize={10}
        isLoading={candidatesIsLoading}
        manualSortBy
        columns={candidatesColumns}
        isFlexLayout
        hasPagination
      />
    </div>
  );
};
