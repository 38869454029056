import classNames from 'classnames';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Panel, Select, Notification } from 'react-ui-kit-exante';

import { useAppSelector } from 'hooks/redux';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as SaveIcon } from 'icons/save.svg';
import {
  bindMappingReport,
  cloneMappingReport,
  MappingReport,
  RawDataReportSetup,
} from 'services/recon';
import { modeNamesSelector } from 'store/reducers/commonReducer';
import { getSelectOptions } from 'utils';

import { compareCp } from '../../../../utils';

interface IProps {
  reloadData: VoidFunction;
  onClose: VoidFunction;
  report: MappingReport;
  le: string;
  cp: string;
  rawDataId: number;
  setRawDataName: (name: string) => void;
  rawDataList: RawDataReportSetup[];
}

export const CloneMapping: FC<IProps> = ({
  reloadData,
  onClose,
  le,
  cp,
  rawDataId,
  setRawDataName,
  rawDataList,
  report,
}) => {
  const modeNamesList = useAppSelector(modeNamesSelector);
  const cpListByEntity = useAppSelector((state) => state.cpListsByEntities);
  const [cpName, setCpName] = useState(cp);
  const [leName, setLeName] = useState(le);
  const [mode, setMode] = useState('');
  const [rawData, setRawData] = useState(rawDataId.toString());

  const title = useMemo(
    () => `Clone ${report.name} normalization to:`,
    [report],
  );
  const modeOptions = useMemo(
    () => getSelectOptions(modeNamesList),
    [modeNamesList],
  );
  const cpOptions = useMemo(
    () =>
      getSelectOptions(cpListByEntity[leName]?.map((item) => item.name) ?? []),
    [cpListByEntity, leName],
  );
  const leOptions = useMemo(
    () =>
      getSelectOptions(
        Object.keys(cpListByEntity).filter(
          (key) => !!cpListByEntity[key]?.length,
        ),
      ),
    [cpListByEntity],
  );
  const rawDataOptions = useMemo(
    () =>
      getSelectOptions(
        rawDataList.filter((item) =>
          compareCp(item.cp, item.counterparty, cpName),
        ),
        'name',
        'name',
      ),
    [rawDataList, cpName],
  );

  useEffect(() => {
    if (rawDataOptions[0]) {
      setRawData(rawDataOptions[0].value);
    }
  }, [rawDataOptions]);
  useEffect(() => {
    if (cpOptions[0]) {
      setCpName(cpOptions[0].value);
    }
  }, [cpOptions]);

  const handleCloneMapping = async () => {
    try {
      const response = await cloneMappingReport(report.report_id, {
        counterparty: cpName,
        legal_entity: leName,
        mode,
      });
      await bindMappingReport({
        raw_data_report_setup_id: Number(rawData),
        mapping_report_id: response.report_id,
      });
      Notification.success({
        title: 'Mapping report was cloned',
      });
      reloadData();
      onClose();
    } catch (error) {
      Notification.error({
        title: 'Error in unbind process',
        description: JSON.stringify(error),
      });
    }
  };

  const handleChangeRawData = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setRawDataName(name);
    setRawData(rawDataList.find((item) => item.name === name)?.name || '');
  };
  return (
    <Panel
      title={title}
      action={
        <div className="d-flex">
          <div
            className={classNames('pointer mr-1', {
              disabled: !rawDataOptions.length,
            })}
            onClick={handleCloneMapping}
          >
            <SaveIcon />
          </div>
          <div className="pointer" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
      }
    >
      <div className="text-left">
        <Select
          placeholder="Le"
          className="mb-3"
          fullWidth
          options={leOptions}
          value={leName}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setLeName(event.target.value);
          }}
        />
        <Select
          placeholder="Cp"
          className="mb-3"
          fullWidth
          options={cpOptions}
          value={cpName}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setCpName(event.target.value);
          }}
        />
        {!rawDataOptions.length ? (
          <p>Need to choose another cp</p>
        ) : (
          <Select
            label="Raw data"
            className="mb-3"
            fullWidth
            value={rawData}
            options={rawDataOptions}
            onChange={handleChangeRawData}
          />
        )}
        <Select
          placeholder="Mode"
          className="mb-3"
          fullWidth
          options={modeOptions}
          value={mode}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setMode(event.target.value);
          }}
        />
      </div>
    </Panel>
  );
};
