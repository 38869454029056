import dayjs from 'dayjs';
import React, { ChangeEvent, FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Input,
  Loader,
  Notification,
  Panel,
  Select,
  Button,
  InputDatePicker,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import {
  getOrderStatus,
  GetOrderStatus,
  OrderStatusResponse,
} from 'services/recon';
import { getSelectOptions } from 'utils/getSelectOptions';

import { counterpartyOptions } from './consts';

type FormType = Omit<GetOrderStatus, 'date' | 'cp_name'>;
export const OrderStatusPage: FC = () => {
  const [data, setData] = useState<OrderStatusResponse | null>(null);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm<FormType>();

  const [cp, setCp] = useState<string>('');
  const handleSetCp = (event: ChangeEvent<HTMLInputElement>) => {
    setCp(event.target.value);
  };
  const [dateField, setDateField] = useState<null | Date>(null);
  const handleChangeDate = (date: Date | null) => {
    if (date) {
      setDateField(date);
    }
  };

  const handleSubmitForm: SubmitHandler<FormType> = async (values) => {
    setLoading(true);
    try {
      const payload: GetOrderStatus = {
        ...values,
        cp_name: cp,
      };
      if (dateField) {
        payload.date = dayjs(dateField).format(DATE_FORMAT);
      }
      const response = await getOrderStatus(payload);
      setData(response);
    } catch (error) {
      Notification.error({ title: 'Error', description: String(error) });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mui-container-fluid">
      <div className="mb-3 mt-3">
        <Panel title="Search status">
          <div className="d-flex">
            <Input
              className="mr-1"
              fullWidth
              label="order_id"
              {...register('order_id')}
            />
            <Input
              className="mr-1"
              fullWidth
              label="exchange_id"
              {...register('exchange_id')}
            />
            <Select
              label="Counterparty"
              className="mr-1"
              fullWidth
              value={cp}
              onChange={handleSetCp}
              options={getSelectOptions(counterpartyOptions)}
            />

            <InputDatePicker
              locale={defaultLocale}
              onChange={handleChangeDate}
              inputProps={{
                label: 'Date',
                fullWidth: true,
              }}
              selected={dateField}
            />
            <Button className="w-100" onClick={handleSubmit(handleSubmitForm)}>
              Search
            </Button>
          </div>
        </Panel>
      </div>
      {loading ? (
        <Loader
          className="d-flex justify-content-center align-items-center w-100"
          size="l"
        />
      ) : (
        <Panel>
          <div className="d-flex justify-content-around">
            <div>
              <p>
                <b>crypto_stats_status</b>
              </p>
            </div>
            <div>
              <p>
                <b>order_db_status</b>
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="w-100 text-left">
              <pre>
                {JSON.stringify(data?.results.crypto_stats_status, null, 5)}
              </pre>
            </div>
            <div className="w-100 text-left">
              <pre>
                {JSON.stringify(data?.results.order_db_status, null, 5)}
              </pre>
            </div>
          </div>
        </Panel>
      )}
    </div>
  );
};
