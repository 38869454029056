import dayjs from 'dayjs';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useTableData,
  Tabs,
  Tab,
  IOnFetchArguments,
  Modal,
  Input,
} from 'react-ui-kit-exante';

import { DatePicker } from 'components';
import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import { useAppSelector } from 'hooks';
import { ReactComponent as GrayPalmIcon } from 'icons/GrayPalm.svg';
import { getCpDataMonitor, Types, Stat, SubRow } from 'services/recon';
import { TableDataWithPagination } from 'types';
import { getSelectOptions, getWorkYesterday } from 'utils';

import { Checkbox } from './components';
import { dataTypesBySegments } from './constants';
import { useHolidayConfirmation } from './hooks';
import { ByFieldNames } from './models';
import {
  HeadDiv,
  StylesToggleButtonGroup,
  CheckboxesBlock,
  Table,
} from './styled';
import { getColumns, getAdditionalFilters } from './utils/getColumns';
import { getConfirmIndicatingCondition } from './utils/getConfirmIndicatingCondition';
import { getMapDataForTreeTable } from './utils/getMapDataForTreeTable';

export const CpDataMonitor: FC = () => {
  const cpTypes = useAppSelector((state) => state.cpTypes);
  const leTypeOptions = useMemo(
    () => getSelectOptions(cpTypes, 'name', 'name'),
    [cpTypes],
  );
  const isFirstRequest = useRef(true);
  const [dateFilter, setDateFilter] = useState<Date | null>(
    new Date(getWorkYesterday()),
  );
  const [dataSegment, setDataSegment] = useState(0);
  const [dataTypes, setDataTypes] = useState<Types[]>([Types.Transactions]);
  const [byFieldName, setByFieldName] = useState<ByFieldNames>(
    ByFieldNames.LegalEntity,
  );

  useEffect(() => {
    setDataTypes(dataTypesBySegments[dataSegment]);
  }, [dataSegment]);

  const formattedDateFilter = useMemo(
    () => dayjs(dateFilter).format(DATE_FORMAT),
    [dateFilter],
  );
  const getTableData = useCallback(
    (props: IOnFetchArguments) => {
      if (isFirstRequest.current) {
        isFirstRequest.current = false;
        return Promise.resolve().then(() => ({
          items: [],
          pagination: { total: 0 },
        }));
      }
      return getCpDataMonitor(
        formattedDateFilter,
        dataTypes,
        props.filtersParams,
      );
    },
    [formattedDateFilter, dataTypes],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTableData },
      filters: {
        getDefaultFilters: () => ({
          legal_entity_type: ['Broker', 'Bank'],
        }),
      },
    }),
    [getTableData],
  );

  const {
    data,
    isLoading,
    resetFilters,
    filters,
    removeFilter,
    setFilter,
    fetchData,
  } = useTableData<TableDataWithPagination<Stat>>(tableData);

  const mappedItems = useMemo(
    () => getMapDataForTreeTable(data?.items ?? [], byFieldName),
    [data, byFieldName],
  );

  const columns = useMemo(
    () => getColumns(dataSegment === 2, dataSegment === 1),
    [dataSegment],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters: getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        leTypeOptions,
      }),
    }),
    [filters, resetFilters, setFilter, removeFilter, leTypeOptions],
  );
  const handleChangeDataSegment = (
    _: ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setDataSegment(newValue);
  };

  const {
    isOpened: confirmModalIsOpened,
    handleCloseModal,
    handleOpenConfirmModal,
    comment: confirmComment,
    handleConfirm,
    handleChangeComment,
  } = useHolidayConfirmation({
    reportDate: formattedDateFilter,
    reloadData: fetchData,
  });

  const showingConfirmButtonIndication = useMemo(
    () => getConfirmIndicatingCondition(byFieldName),
    [byFieldName],
  );

  const headComponent = (
    <HeadDiv>
      <Tabs value={dataSegment} onChange={handleChangeDataSegment}>
        <Tab label="Cash Flow" />
        <Tab label="Postion Flow" />
        <Tab label="Other" />
      </Tabs>
      <div className="d-flex">
        <div className="w-50">
          <div className="d-flex align-items-end ">
            <CheckboxesBlock>
              {dataSegment <= 1 &&
                dataTypesBySegments[dataSegment].map((type) => (
                  <Checkbox
                    key={type}
                    dataTypes={dataTypes}
                    type={type}
                    setDataTypes={setDataTypes}
                  />
                ))}
            </CheckboxesBlock>
          </div>
          <StylesToggleButtonGroup
            exclusive
            label=""
            onChange={(value) => setByFieldName(value as ByFieldNames)}
            options={[
              {
                label: 'Legal Entity',
                value: ByFieldNames.LegalEntity,
                style: {
                  borderTopLeftRadius: '14px',
                  borderBottomLeftRadius: '14px',
                },
              },
              {
                label: 'Counterparty',
                value: ByFieldNames.Counterparty,
                style: {
                  borderTopRightRadius: '14px',
                  borderBottomRightRadius: '14px',
                },
              },
            ]}
            value={byFieldName}
          />
        </div>
        <DatePicker
          className="w-50 "
          date={dateFilter}
          setDate={setDateFilter}
        />
      </div>
    </HeadDiv>
  );

  return (
    <div className="mui-container-fluid text-left">
      <div className="mui-row">
        <div className="mui-col-md-12">
          <Table
            tableId="cpDataMonitorTree"
            title="CP Data Monitor"
            manualSortBy
            isFlexLayout
            showScrollbar
            isPinnedHeader
            customHeadComponent={headComponent}
            expanded={{
              listOfInitialExpandedRowKeys: {
                0: false,
              },
            }}
            rowActions={{
              show: true,
              isEditedRow: () => false,
              additionalActions: [
                {
                  show: showingConfirmButtonIndication,
                  label: <GrayPalmIcon />,
                  width: 100,
                  title: 'Confirm',
                  onClick: (raw: SubRow) => {
                    if (raw.name) {
                      handleOpenConfirmModal(raw.name);
                    }
                  },
                },
              ],
            }}
            hasFilters
            data={mappedItems}
            columns={columns}
            isLoading={isLoading}
            filteringProps={filteringProps}
            filtersExpanded={false}
            locale={defaultLocale}
            defaultSortBy={[]}
            noDataHeight="auto"
          />
        </div>
        <Modal
          isOpened={confirmModalIsOpened}
          title="No file Confirmation"
          onClose={handleCloseModal}
          confirmButton={{
            confirmButtonName: 'Confirm',
            handleConfirm,
            confirmButtonIsDisabled: !confirmComment,
          }}
        >
          <p className="my-0">Do you confirm that there will be no file?</p>
          <Input
            label="Comment"
            fullWidth
            value={confirmComment}
            onChange={handleChangeComment}
          />
        </Modal>
      </div>
    </div>
  );
};
