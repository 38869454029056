import React, { useState } from 'react';
import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { ScriptRunStatuses, ScriptStates } from 'services/reconRunner';
import { theme } from 'theme';

import { ShowLogModal } from './components';

const GetModalCell = (
  cell: ICellValue<ScriptRunStatuses>,
  outputID: string,
) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div>
      {isOpened && (
        <ShowLogModal
          scriptID={`${cell.row.original.script_model}.${cell.row.original.name}`}
          outputID={outputID}
          onClose={() => setIsOpened(false)}
        />
      )}
      <span className="pointer" onClick={() => setIsOpened(true)}>
        Show
      </span>
    </div>
  );
};

export const columns: IColumn<ScriptRunStatuses>[] = [
  {
    Header: 'id',
    accessor: 'id',
    width: 60,
    disableSortBy: true,
    type: 'textInput',
  },
  {
    Header: 'script_id',
    accessor: 'script_id',
    width: 60,
    disableSortBy: true,
    type: 'textInput',
  },
  {
    Header: 'Status',
    accessor: 'state',
    minWidth,
    disableSortBy: true,
    type: 'textInput',
  },
  {
    Header: 'name',
    accessor: 'name',
    minWidth,
    disableSortBy: true,
    type: 'textInput',
  },
  {
    Header: 'full name',
    accessor: 'full_name',
    minWidth,
    disableSortBy: true,
    type: 'textInput',
  },
  {
    Header: 'for date',
    accessor: 'for_date',
    minWidth,
    disableSortBy: true,
    type: 'textInput',
  },
  {
    Header: 'run_time',
    accessor: 'run_time',
    minWidth,
    disableSortBy: true,
    type: 'textInput',
  },
  {
    Header: 'std out',
    accessor: 'std_out',
    width: 50,
    Cell: (cell: ICellValue<ScriptRunStatuses>) => GetModalCell(cell, 'stdout'),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'std err',
    accessor: 'std_err',
    width: 50,
    Cell: (cell: ICellValue<ScriptRunStatuses>) => GetModalCell(cell, 'stderr'),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'exception',
    accessor: 'exception',
    width: 50,
    Cell: (cell: ICellValue<ScriptRunStatuses>) =>
      GetModalCell(cell, 'exception'),
    disableSortBy: true,
    disableFilters: true,
  },
];

export const rowColorByStatus = {
  [ScriptStates.SUCCESS]: theme?.color.table.bg.source,
  [ScriptStates.FAILED]: theme?.color.table.bg.radical,
  [ScriptStates.RUNNING]: theme?.color.table.bg.basic1,
  [ScriptStates.STARTED]: theme?.color.table.bg.basic2,
};
