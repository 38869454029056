import { ChangeEvent, SyntheticEvent, useMemo, useState } from 'react';

import { ReportWithFields } from 'services/recon';

import { formDataInitialValue } from './consts';

export function useReportForm(report: ReportWithFields | null) {
  const initialValue = report
    ? {
        name: report.name,
        reportType: report.type,
        counterpartyName: report.counterparty,
        legalEntityName: report.legal_entity,
        modeName: report.mode,
        setups: [],
      }
    : formDataInitialValue;
  const [formData, setFormData] = useState(initialValue);

  const handleChangeName = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const {
      target: { value, name },
    } = e;
    setFormData({ ...formData, [name]: value });
  };
  const handleChangeCounterparty = (event: SyntheticEvent, newValue: any) => {
    setFormData({ ...formData, counterpartyName: newValue?.value });
  };
  const handleChangeLe = (event: SyntheticEvent, newValue: any) => {
    setFormData({ ...formData, legalEntityName: newValue?.value });
  };
  const handleChangeMode = (event: SyntheticEvent, newValue: any) => {
    setFormData({ ...formData, modeName: newValue?.value });
  };
  const handleChangeType = (event: SyntheticEvent, newValue: any) => {
    setFormData({ ...formData, reportType: newValue?.value });
  };
  const handleChangeSetups = (event: SyntheticEvent, newValue: any) => {
    setFormData({ ...formData, setups: newValue });
  };

  const wasUpdated = useMemo(
    () =>
      report?.name !== formData.name ||
      report?.type !== formData.reportType ||
      report?.counterparty !== formData.counterpartyName ||
      report?.legal_entity !== formData.legalEntityName ||
      report?.mode !== formData.modeName,
    [report, formData],
  );

  return {
    formData,
    setFormData,
    handleChangeName,
    handleChangeCounterparty,
    handleChangeLe,
    handleChangeMode,
    handleChangeType,
    handleChangeSetups,
    wasUpdated,
  };
}
