import cn from 'classnames';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  IOnFetchArguments,
  ISortBy,
  Notification,
  OnSaveEditableRow,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import {
  DEFAULT_FILTER_VALUES,
  defaultOrdering,
  pageSizes,
} from 'constants/tables';
import { useAppSelector, usePropSelector } from 'hooks/redux';
import {
  PositionRec as PositionRecType,
  getPositionReconciliations,
  patchPositionReconciliation,
} from 'services/recon';
import {
  cpListByEntitySelector,
  modeNamesSelector,
  newLegalEntityNamesForBroker,
} from 'store/reducers/commonReducer';
import { calculateCountOfPages } from 'utils';
import { getDefaultFilterParams } from 'utils/getDefaultFilterParams';
import { getSelectOptions } from 'utils/getSelectOptions';

import PositionRecAddForm from './PositionRecAddForm';
import { PositionRecTable } from './types';
import { getColumns, DISPLAYED_COLUMN_KEYS } from './utils/getColumns';

export const PositionRec: FC = () => {
  const filtersIsFirstMounted = useRef(false);
  const isShowForm = false;
  const leNames = useAppSelector(newLegalEntityNamesForBroker);
  const modeNamesList = useAppSelector(modeNamesSelector);

  const entityOptions = useMemo(() => getSelectOptions(leNames), [leNames]);
  const modeOptions = useMemo(
    () => getSelectOptions(modeNamesList),
    [modeNamesList],
  );

  const getTableData = useCallback(
    (props: IOnFetchArguments) => getPositionReconciliations(props),
    [],
  );

  const applyObject = {
    counterparty: DEFAULT_FILTER_VALUES.cp,
    mode: DEFAULT_FILTER_VALUES.mode,
  };
  const filterKeys = getColumns({
    modeOptions,
    cpOptions: [],
    entityOptions,
  }).map((column) => column.accessor);
  const prepareFiltersForParams = useCallback(
    getDefaultFilterParams({
      filterKeys,
      applyObject,
      isApply: filtersIsFirstMounted,
      shouldApply: !window.location.search,
    }),
    [],
  );

  const tableDataArgs: any = useMemo(
    () => ({
      data: { onFetch: getTableData },
      filters: {
        prepareFiltersForParams,
      },
      pagination: { getDefaultPagination: () => ({ limit: 20, skip: 0 }) },
    }),
    [getTableData],
  );

  const {
    filters,
    setFilter,
    removeFilter,
    resetFilters,
    isLoading,
    limit,
    page,
    setPage,
    setLimit,
    setSorting,
    data,
  } = useTableData<PositionRecTable>(tableDataArgs);

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (filters.legal_entity as string) ?? DEFAULT_FILTER_VALUES.le,
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity?.map((item) => item.name) ?? []),
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && filters.counterparty) {
      const foundCp = cpOptions.find(
        (item) => item.value === filters.counterparty,
      );
      if (!foundCp) {
        setFilter('counterparty', cpOptions[0].value);
      }
    }
  }, [cpOptions]);
  useEffect(() => {
    if (leNames.length && !filters.legal_entity) {
      setFilter('legal_entity', leNames[0]);
    }
  }, [leNames]);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        modeOptions,
        cpOptions,
        entityOptions,
      }),
    [setFilter, removeFilter, modeOptions, cpOptions, entityOptions],
  );

  const filterProps = useMemo(
    () => ({
      filters,
      removeAllFilters: resetFilters,
    }),
    [resetFilters, filters],
  );

  const handleSaveRow: OnSaveEditableRow<PositionRecType> = async (
    previousValues,
    updatedValues,
  ) => {
    try {
      await patchPositionReconciliation(updatedValues.id, updatedValues);
    } catch (errInfo) {
      Notification.error({
        title: `Failed to update position reconciliation entry ${errInfo}`,
      });
    }
  };

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className={cn({ 'col-8': isShowForm, 'col-12': !isShowForm })}>
          <Table
            title="Counterparty positions"
            tableId="tablePositionRec"
            showTableInfo
            hasPagination
            hasFilters
            locale={defaultLocale}
            manualSortBy
            isFlexLayout
            showScrollbar
            onSort={handleSorting}
            defaultSortBy={defaultOrdering}
            columns={columns}
            isLoading={isLoading}
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total: data?.pagination.total || 0,
              setPage,
              setPageSize: setLimit,
            }}
            data={data?.positions || []}
            filteringProps={filterProps}
            pageSizes={pageSizes}
            // if you remove this prop, inline editing will appear
            displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
            rowActions={{
              onSave: handleSaveRow,
              show: true,
            }}
          />
        </div>
        <div className={cn({ 'col-4': isShowForm, 'd-none': !isShowForm })}>
          <PositionRecAddForm cpOptions={cpOptions} />
        </div>
      </div>
    </div>
  );
};
