import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ICellValue,
  IOnFetchArguments,
  IRow,
  ISortBy,
  Notification,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { defaultOrdering, pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks';
import {
  getCpBoTransactions,
  getTransactionOperationTypeListForPage,
  SIDE,
  Transaction,
  TransactionOperationType,
} from 'services/recon';
import { calculateCountOfPages, getSelectOptions } from 'utils';

import {
  categoriesByTypesSelector,
  modeNamesSelector,
  newCounterPartyNames,
  newLegalEntityNames,
  userNamesSelector,
} from '../../store/reducers/commonReducer';
import { theme } from '../../theme';
import { ActionsBlock } from '../UnionTransactionsReconcile/ActionsBlock/ActionsBlock';
import { TransactionsTable } from '../UnionTransactionsReconcile/types';
import {
  getAdditionalColumns,
  getTransactionsTableColumns,
} from '../UnionTransactionsReconcile/utils';

export const NewTransactionReconcile: FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [operationTypes, setOperationTypes] = useState<
    TransactionOperationType[]
  >([]);
  const legalEntityNames = useAppSelector(newLegalEntityNames);
  const cpNames = useAppSelector(newCounterPartyNames);
  const userNames = useAppSelector(userNamesSelector);
  const categories = useAppSelector((state) => state.categories);
  const categoriesByTypes = useAppSelector(categoriesByTypesSelector);
  const ccyList = useAppSelector((state) => state.currencies);
  const [selectedTransactions, setSelectedTransactions] = useState<
    Transaction[]
  >([]);

  const modeNamesList = useAppSelector(modeNamesSelector);
  const breakCategories = useAppSelector((state) => state.breakCategories);
  const breakCategoryOptions = useMemo(
    () => [...getSelectOptions(breakCategories, 'name', 'name')],
    [breakCategories],
  );
  const breakCategoryObject = useMemo(
    () =>
      breakCategories.reduce(
        (acc: Record<string, string>, item) => ({
          ...acc,
          [item.name]: String(item.id),
        }),
        {},
      ),
    [breakCategories],
  );
  const cpOptions = useMemo(() => getSelectOptions(cpNames), [cpNames]);

  const getTransactions = useCallback((props: IOnFetchArguments) => {
    const { break_report_category: breakReportCategory } = props.filtersParams;
    return getCpBoTransactions({
      ...props,
      filtersParams: {
        ...props.filtersParams,
        break_report_category:
          (breakReportCategory as string[])?.map(
            (name) => breakCategoryObject[name],
          ) ?? undefined,
      },
    });
  }, []);

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTransactions },
      sorting: {
        getDefaultSorting: () => defaultOrdering,
      },
      filters: {
        getDefaultFilters: () => ({
          reportdate: [dayjs().subtract(7, 'day').toDate(), dayjs().toDate()],
          le: legalEntityNames[0],
        }),
      },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
    }),
    [getTransactions],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    fetchData,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<TransactionsTable>(tableData);

  const handleCheckAllOnPage = () => {
    if (data) {
      setSelectedTransactions([
        ...selectedTransactions,
        ...(data?.transactions ?? []),
      ]);
    }
  };
  const handleUnCheckAllOnPage = () => {
    const withoutTransactionsOnPage = selectedTransactions.filter((item) => {
      const foundTransaction = data?.transactions?.find(
        (i) => i.id === item.id,
      );
      return !foundTransaction;
    });
    setSelectedTransactions(withoutTransactionsOnPage);
  };

  const handleCheckTransaction = useCallback(
    (transaction: Transaction) => {
      if (selectedTransactions.find((i) => i.id === transaction.id)) {
        setSelectedTransactions([
          ...selectedTransactions.filter((i) => i.id !== transaction.id),
        ]);
      } else {
        setSelectedTransactions([...selectedTransactions, transaction]);
      }
    },
    [selectedTransactions, setSelectedTransactions],
  );
  useEffect(() => {
    getTransactionOperationTypeListForPage('transactions-reconcile')
      .then((response) => {
        if (response.results) {
          setOperationTypes(response.results);
        }
      })
      .catch(() => {
        Notification.error({
          title: 'Fetch TransactionTypes error',
        });
      });
  }, []);

  const transactionsTableColumns = useMemo(
    () =>
      getTransactionsTableColumns(
        {
          transactions: data?.transactions,
          selectedTransactionsIds: selectedTransactions.map((i) => i.id),
          onCheckTransaction: handleCheckTransaction,
          onCheckAllTransactionOnPage: handleCheckAllOnPage,
          onUnCheckAllTransactionOnPage: handleUnCheckAllOnPage,
          breakCategoryOptions,
          operationTypes,
          cpOptions,
          modeNames: modeNamesList,
          ccyList,
          userNames,
          categories,
          categoriesByTypes,
          onFilter: setFilter,
          onRemove: removeFilter,
          reloadData: fetchData,
          updateData: () => {},
        },
        false,
      ),
    [
      data?.transactions,
      categoriesByTypes,
      breakCategoryOptions,
      selectedTransactions,
      operationTypes,
      setFilter,
      userNames,
      removeFilter,
      cpOptions,
      modeNamesList,
      filters,
    ],
  );

  const entityOptions = useMemo(
    () => getSelectOptions(legalEntityNames),
    [legalEntityNames],
  );
  const additionalFilters = useMemo(
    () =>
      getAdditionalColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        entityOptions,
        counterPartyOptions: cpOptions,
        isReconciled: false,
      }),
    [setFilter, removeFilter, entityOptions, cpOptions],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters,
    }),
    [filters, resetFilters, additionalFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const getRowProps = useCallback(
    (row: IRow<Transaction>) => {
      let background = theme?.color.table.bg.basic2;
      const isSelectElement = !!selectedTransactions.find(
        (i) => i.id === row.original.id,
      );
      if (isSelectElement) {
        background = theme?.color.table.bg.source;
      } else if (row.original.side === SIDE.OUR) {
        background = theme?.color.bg.secondary;
      }
      return {
        style: {
          background,
        },
      };
    },
    [selectedTransactions],
  );
  const getCellProps = useCallback(
    (cell: ICellValue<Transaction>) =>
      cell.column.id === 'actions'
        ? {
            style: {
              ...getRowProps(cell.row).style,
              boxShadow: `0 0 0 ${theme?.color.table.bg.basic2}`,
            },
          }
        : {},
    [getRowProps],
  );

  return (
    <div className="mui-container-fluid" ref={containerRef}>
      <ActionsBlock
        reloadReconciledTable={() => {}}
        reloadData={fetchData}
        transactions={data?.transactions || []}
        selectedTransactions={selectedTransactions}
        onUpdateSelectedTransactions={setSelectedTransactions}
        containerRef={containerRef}
      />
      <Table
        tableId="NewMixedTransactions"
        title="Transactions"
        data={data?.transactions ?? []}
        columns={transactionsTableColumns}
        filteringProps={filteringProps}
        isLoading={isLoading}
        showScrollbar
        saveColumnOrder
        saveViewParamsAfterLeave
        getRowProps={getRowProps}
        getCellProps={getCellProps}
        pageSizes={pageSizes}
        hasFilters
        locale={defaultLocale}
        hasPagination
        showTableInfo
        manualSortBy
        onSort={handleSorting}
        isFlexLayout
        defaultSortBy={defaultOrdering}
        serverPaginationProps={{
          pageIndex: page,
          pageCount,
          pageSize: limit,
          total,
          setPage,
          setPageSize: setLimit,
        }}
      />
    </div>
  );
};
