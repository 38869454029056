export * from './number';
export * from './dates';
export * from './getDefaultFilterParams';
export * from './url';
export * from './getChangedValues';
export * from './getColumnComponents';
export * from './getSelectOptions';
export * from './array';
export * from './clickToLinkForFetchingData';

export function addBaseTag() {
  const baseTag = document.createElement('base');
  const path = window.location.pathname.split('/');
  const branchIdx = path.findIndex((p) => /BPA-\d*/.test(p));
  if (branchIdx !== -1) {
    baseTag.href = `${
      window.location.origin + path.slice(0, branchIdx + 1).join('/')
    }/`;
    document.head.prepend(baseTag);
  }
}

export function getBaseName() {
  if (window.location.href.includes('BPA-')) {
    return window.location.href.split('/').slice(0, 4)[3];
  }
  if (window.location.href.includes('release')) {
    return 'release';
  }
  return '';
}

export const calculateCountOfPages = (total: number, limit: number) =>
  Math.ceil(total / limit);
