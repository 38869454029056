import { Notification } from 'react-ui-kit-exante';

import { ReconRunnerApiService } from './api';
import {
  RunByCPAndTypePayload,
  RunByCPAndTypeResponse,
  RunScriptsPayload,
  RunScriptsResponse,
  Script,
  ScriptRunStatuses,
} from './types';

export async function getScripts(group: string) {
  const url = group === undefined ? `scripts/` : `scripts/?group=${group}`;
  return ReconRunnerApiService.getResource<Script[]>(url);
}

export async function getScriptsStatus() {
  return ReconRunnerApiService.getResource<Script[]>('scripts/');
}

export async function getScriptOutput(scriptName: string, outputID: string) {
  return ReconRunnerApiService.getResource<{ result: string }>(
    `script-output/?id=${scriptName}&output=${outputID}`,
  );
}

export async function getScriptsExecutionsInfo() {
  return ReconRunnerApiService.getResource<ScriptRunStatuses[]>(
    'scripts-run-statuses/',
  );
}

export async function runScripts(
  payload: RunScriptsPayload,
  onlyPostScripts: boolean,
) {
  const endpoint = onlyPostScripts ? 'run-post-script/' : 'run-script/';
  return ReconRunnerApiService.postResource<
    RunScriptsPayload,
    RunScriptsResponse
  >(endpoint, payload);
}

export async function runByCPAndType(payload: RunByCPAndTypePayload) {
  try {
    const response = await ReconRunnerApiService.postResource<
      RunByCPAndTypePayload,
      RunByCPAndTypeResponse
    >('run-script-for-cp/', payload);
    if (response.status === 'success') {
      if (response.scripts.length) {
        Notification.success({
          title: `${response.scripts.join(
            ', ',
          )} script run signal has been sent`,
        });
      } else {
        Notification.success({ title: 'Rerun success, but not have scripts' });
      }
    }
  } catch (e) {
    Notification.error({ title: 'Error when tried rerun script' });
  }
}
