import { createTheme } from 'react-ui-kit-exante';

// this is the fix for problem with types
// https://exante.slack.com/archives/C035P49RZQA/p1699875622217889?thread_ts=1699622144.725389&cid=C035P49RZQA
declare module '@mui/material/styles/createTheme' {
  interface Theme {
    color: Record<string, any>;
    font: Record<string, any>;
    size: Record<string, any>;
  }
}

const defaultTheme = {
  palette: {
    primary: {
      light: '#af1430',
      main: '#007F39',
    },
    success: {
      main: '#409F6B',
    },
    text: {
      primary: '#252C2E',
      secondary: '#4E5D60',
      disabled: '#B8BBB7',
    },
    warning: {
      main: '#9F4040',
    },
  },
  color: {
    bg: {
      basic: '#F3F3F3',
      primary: '#FFFFFF',
      secondary: '#F7F7F7',
      promo: '#4E5D60',
      radical: '#F6E8E8',
      warning: '#FEF6E7',
    },
    button: {
      primary: {
        gr: {
          default: 'linear-gradient(180deg, #029E47 0%, #01803A 100%)',
          focus: '#01803A',
          hover: 'linear-gradient(180deg, #01C056 0%, #01803A 100%)',
        },
        typo: {
          default: '#FFFFFF',
          hover: '#FFFFFF',
          focus: '#CCE6D8',
          disabled: '#FFFFFF',
        },
        corner: {
          default: '#01662E',
        },
      },
      radical: {
        gr: {
          default: 'linear-gradient(180deg, #BF3B4B 0%, #8B2D2D 100%)',
          focus: '#8B2D2D',
          hover: 'linear-gradient(180deg, #CA4747 0%, #A63636 100%)',
        },
        typo: {
          default: '#FFFFFF',
          hover: '#FFFFFF',
          focus: '#E6CCCC',
          disabled: '#FFFFFF',
        },
        corner: {
          default: '#6F2626',
        },
      },
      secondary: {
        gr: {
          default: 'linear-gradient(180deg, #F0F0F0 0%, #E4E4E4 100%)',
          focus: '#E6E6E6',
          hover: 'linear-gradient(180deg, #F9F9F9 0%, #F0F0F0 100%)',
        },
        typo: {
          default: '#4E5D60',
          hover: '#252C2E',
          focus: '#BABABA',
          disabled: '#4E5D60',
        },
        corner: {
          default: '#D3D3D3',
        },
      },
    },
    checkbox: {
      primary: {
        checked: {
          bg: 'linear-gradient(180deg, #029E47 0%, #01803A 100%)',
          border: {
            default: 'transparent',
            focus: 'transparent',
            hover: 'transparent',
          },
          boxShadow: {
            default:
              'inset 0 -4.8px 9.6px rgba(33, 229, 100, 0.24), inset 0 4.8px 9.6px rgba(12, 211, 161, 0.41)',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF',
          labelColor: {
            default: '#252C2E',
            focus: '#252C2E',
            hover: '#252C2E',
          },
        },
        nonChecked: {
          bg: '#FFFFFF',
          border: {
            default: '#818C99',
            focus: '#4E5D60',
            hover: '#4E5D60',
          },
          boxShadow: {
            default: 'none',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF',
          labelColor: {
            default: '#4E5D60;',
            focus: '#252C2E',
            hover: '#252C2E',
          },
        },
      },
      warning: {
        checked: {
          bg: 'linear-gradient(180deg, #FFA800 0%, #EC9F0B 100%)',
          border: {
            default: 'transparent',
            focus: 'transparent',
            hover: 'transparent',
          },
          boxShadow: {
            default:
              'inset 0 -4.8px 9.6px rgba(255, 176, 26, 0.24), inset 0 4.8px 9.6px rgba(255, 229, 181, 0.41)',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF',
          labelColor: {
            default: '#252C2E',
            focus: '#252C2E',
            hover: '#252C2E',
          },
        },
        nonChecked: {
          bg: '#FFFFFF',
          border: {
            default: '#EC9F0B',
            focus: '#F1A615',
            hover: '#F1A615',
          },
          boxShadow: {
            default: 'none',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF',
          labelColor: {
            default: '#4E5D60;',
            focus: '#252C2E',
            hover: '#252C2E',
          },
        },
      },
    },
    controls: {
      gr: 'linear-gradient(180deg, #029E47 0%, #01803A 100%)',
    },
    defaultBoxShadow: '0 4px 8px 0 rgba(37, 44, 46, 0.1)',
    dropdown: {
      bg: {
        default: '#FFFFFF',
      },
      border: '#EBEBEB',
      error: '#BF3B4B',
      list: {
        bg: {
          focus: '#F3F3F3',
          hover: '#F7F8F8',
        },
      },
      chip: {
        bg: '#F9FAFA',
      },
    },
    icon: {
      action: '#007F39',
      ghost: '#9A9D99',
      hover: '#03BF56',
      inverse: '#FFFFFF',
      primary: '#252C2E',
      promo: '#4E5D60',
      radical: '#9F4040',
      secondary: '#818C99',
      source: '#409F6B',
    },
    indicator: {
      bg: {
        basic: '#F3F3F3',
      },
    },
    input: {
      bg: {
        default: '#FFFFFF',
        hover: '#FBFBFB',
      },
      border: '#F2F1F1',
      error: '#BF3B4B',
      warning: '#EC9F0B',
      transparentWarning: 'rgba(236, 159, 11, 0.3)',
    },
    modal: {
      bg: '#FFFFFF',
    },
    line: {
      action: '#007F39',
      primary: '#EBEBEB',
      level: {
        page: '#F7F7F7',
      },
    },
    notifications: {
      success: '#409F6B',
      error: '#9F4040',
      warning: '#EC9F0B',
    },
    typo: {
      action: '#007F39',
      ghost: '#9A9D99',
      hover: '#03BF56',
      inverse: '#FFFFFF',
      primary: '#252C2E',
      promo: '#4E5D60',
      radical: '#9F4040',
      secondary: '#818C99',
      source: '#409F6B',
    },
    table: {
      bg: {
        basic1: '#FAFAFA',
        basic2: '#FFFFFF',
        radical: '#EFE8E8',
        source: '#EEF6F2',
        hover: '#D8EADA',
        selected: '#CBE3CD',
      },
      boxShadow: {
        basic1: 'inset 0 1px 0 #F2F1F1',
      },
    },
    datepicker: {
      background: '#FFFFFF',
      colorWeek: '#252C2E',
      colorDay: '#818C99',
      daySelected: '#F2F8F2',
      dayHover: '#D8EADA',
      dayBorder: '#007F39',
    },
    loader: { color: '#B8BBB7' },
    tree: {
      hover: '#F5F5F5',
      focused: '#007f3933',
      expanded: '#ECF5F0',
    },
  },
  effect: {
    controls: {
      warning:
        'inset 0 -4.8px 9.6px rgba(236, 159, 11, 0.24), inset 0 4.8px 9.6px rgba(236, 159, 11, 0.41)',
      inter:
        'inset 0 -4.8px 9.6px rgba(33, 229, 100, 0.24), inset 0 4.8px 9.6px rgba(12, 211, 161, 0.41)',
      drop: '0 2.32257px 6.19352px rgba(0, 0, 0, 0.15)',
    },
  },
  font: {
    main: 'Inter, sans-serif',
    header: 'FoxSansPro, sans-serif',
  },
  size: {
    loader: {
      md: '16px',
      lg: '32px',
    },
    text: {
      sm: '12px',
      md: '13px',
      lg: '15px',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      tablet: 512,
      sm: 768,
      md: 1024,
      lg: 1280,
      lr: 1440,
      xl: 1920,
    },
  },
};

export const theme = createTheme(defaultTheme);
