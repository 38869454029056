import { useMemo, useState } from 'react';

import { useAppSelector } from 'hooks';
import { getAllRawDataSetups, RawDataReportSetup } from 'services/recon';

import { compareCp } from './utils';

export function useSetupsList() {
  const cpByEntity = useAppSelector((state) => state.cpListsByEntities);
  const cpList = useAppSelector((state) => state.new_counterparty_list);
  const [setupList, setSetupList] = useState<RawDataReportSetup[]>([]);

  const getFilteredSetupList = (cp: string, le: string) =>
    setupList.filter(
      (setup) =>
        compareCp(setup.cp, setup.counterparty, cp) &&
        setup.legal_entity === le,
    );

  const calculatedData = useMemo(
    () =>
      Object.keys(cpByEntity)
        .map((key) => ({
          name: key,
          subRows: cpByEntity[key]
            .map((cp) => ({
              name: cp.name,
              label: cpList.find((item) => item.name === cp.name)?.type ?? '',
              leName: key,
              subRows: getFilteredSetupList(cp.name, key)
                .sort(
                  (first, second) =>
                    first.related_mapping.length -
                    second.related_mapping.length,
                )
                .map((setup) => ({
                  name: setup.name,
                  cpName: cp.name,
                  leName: key,
                  label: setup.module ?? '',
                  subRows: setup.related_mapping.map((mapping) => ({
                    name: mapping?.name ?? '',
                    cpName: cp.name,
                    leName: key,
                    rawDataName: setup.name,
                    label: mapping.type,
                  })),
                })),
            }))
            .sort(
              (first, second) =>
                getFilteredSetupList(first.name, key).length -
                getFilteredSetupList(second.name, key).length,
            ),
        }))
        .filter((item) => item.subRows.length),
    [cpByEntity, setupList],
  );

  const loadSetupLists = async () => {
    const setupsResponse = await getAllRawDataSetups();
    setSetupList(setupsResponse);
  };

  return { setupList, calculatedData, loadSetupLists };
}
