import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CenteredLoader } from 'react-ui-kit-exante';

import { Navbar } from './components';
import { useAppSelector } from './hooks';
import { AddValueMappingRule } from './pages/AddValueMappingRule/AddValueMappingRule';
import { AssignmentSchedule } from './pages/AssignmentSchedule/AssignmentSchedule';
import { AutoMapping } from './pages/AutoMapping/AutoMapping';
import { AutoReconcile } from './pages/AutoReconcile/AutoReconcile';
import { BreakDashboard } from './pages/BreakDashboard/BreakDashboard';
import { CTrades } from './pages/CTrades/CTrades';
import { ChecksOfMovements } from './pages/ChecksOfMovements/ChecksOfMovements';
import { SymbolCOTPage } from './pages/CotSymbol/CotSymbol';
import { CpDataMonitor } from './pages/CpDataMonitor/CpDataMonitor';
import { CPMonitorPage } from './pages/CpMonitor/CpMonitor';
import { CpTrades } from './pages/CpTrades/CpTrades';
import { MappingPage } from './pages/MappingPage/MappingPage';
import { NewTransactionReconcile } from './pages/NewTransactionReconcile/NewTransactionReconcile';
import { OrderStatusPage } from './pages/OrderStatus/OrderStatus';
import { PositionMappingPage } from './pages/PositionMapping/PositionMapping';
import { PositionRec } from './pages/PositionRec/PositionRec';
import { PositionRecon } from './pages/PositionRecon';
import { RawDataPage } from './pages/RawData/RawData';
import { ReconciledTrades } from './pages/ReconciledTrades/ReconciledTrades';
import { ReconciledTransactions } from './pages/ReconciledTransactions/ReconciledTransactions';
import { RowBalance } from './pages/RowBalance/RowBalance';
import { RunInfoPage } from './pages/RunInfo/RunInfo';
import { ScriptsStatusesPage } from './pages/ScriptsStatuses/ScriptsStatuses';
import { SetupEtl } from './pages/SetupEtl/SetupEtl';
import { TradeReconMonitor } from './pages/TradeReconMonitor/TradeReconMonitor';
import { UnionTradesReconcilePage } from './pages/UnionTradesReconcile/UnionTradesReconcile';
import { UnionTransactionsReconcilePage } from './pages/UnionTransactionsReconcile/UnionTransactionsReconcile';
import { ValueMappingRules } from './pages/ValueMappingRules/ValueMappingRules';
import { NewReportPage } from './pages/report/Report/NewReportPage';
import { UpdateReportPage } from './pages/report/Report/UpdateReportPage';
import { ReportsListPage } from './pages/report/ReportList';
import { RunScriptPage } from './pages/run-script-page';
import {
  breakCategoriesIsLoadingSelector,
  cpListIsLoadingSelector,
  leListIsLoadingSelector,
} from './store/reducers/commonReducer';

const Router: FC = () => {
  const isInsideRun = !!window.runUIhistoryContainer;
  const urlPrefix = isInsideRun ? '/recon' : '';
  const cpListIsLoading = useAppSelector(cpListIsLoadingSelector);
  const leListIsLoading = useAppSelector(leListIsLoadingSelector);
  const breakCategoriesIsLoading = useAppSelector(
    breakCategoriesIsLoadingSelector,
  );

  return cpListIsLoading || leListIsLoading || breakCategoriesIsLoading ? (
    <div className="App">
      {!isInsideRun && <Navbar />}
      <CenteredLoader />
    </div>
  ) : (
    <div className="App">
      {!isInsideRun && (
        <>
          <Navbar />
          <div className="mb-2" />
        </>
      )}
      <Switch>
        <Route path={`${urlPrefix}/run-info`}>
          <RunInfoPage />
        </Route>

        <Route path={`${urlPrefix}/users/assignment-schedule`}>
          <AssignmentSchedule />
        </Route>

        <Route path={`${urlPrefix}/edit/tables`}>
          <CTrades />
        </Route>
        <Route path={`${urlPrefix}/edit/cptrades`}>
          <CpTrades />
        </Route>
        <Route path={`${urlPrefix}/auto-reconcile`}>
          <AutoReconcile />
        </Route>
        <Route path={`${urlPrefix}/edit/mapping`}>
          <MappingPage />
        </Route>
        <Route path={`${urlPrefix}/mapping/find-symbol-mapping`}>
          <AutoMapping />
        </Route>
        <Route path={`${urlPrefix}/edit/positionrec`}>
          <PositionRec />
        </Route>
        <Route path={`${urlPrefix}/run/trade-recon`}>
          <RunScriptPage group="trade-recon" isTradeRecon hasDate />
        </Route>
        <Route path={`${urlPrefix}/run/views`}>
          <RunScriptPage group="views" hasDate />
        </Route>
        <Route path={`${urlPrefix}/run/misc`}>
          <RunScriptPage group="misc" />
        </Route>
        <Route path={`${urlPrefix}/run/load-trades`}>
          <RunScriptPage group="load-trades" hasDate />
        </Route>
        <Route path={`${urlPrefix}/run/reporting`}>
          <RunScriptPage group="reporting" />
        </Route>
        <Route path={`${urlPrefix}/manual-reconcile-union`}>
          <UnionTradesReconcilePage isReconciledPage={false} />
        </Route>
        <Route exact path={`${urlPrefix}/trades-reconciled`}>
          <ReconciledTrades />
        </Route>
        <Route path={`${urlPrefix}/symbol-cot`}>
          <SymbolCOTPage />
        </Route>
        <Route path={`${urlPrefix}/order-status`}>
          <OrderStatusPage />
        </Route>
        <Route path={`${urlPrefix}/cp-monitor`}>
          <CPMonitorPage />
        </Route>
        <Route path={`${urlPrefix}/position-mapping`}>
          <PositionMappingPage />
        </Route>
        <Route path={`${urlPrefix}/row-balance/add`}>
          <RowBalance />
        </Route>
        <Route path={`${urlPrefix}/mapping/value-mapping-rules`}>
          <ValueMappingRules />
        </Route>
        <Route path={`${urlPrefix}/mapping/add-value-mapping-rule`}>
          <AddValueMappingRule />
        </Route>
        <Route path={`${urlPrefix}/mapping/new-report`}>
          <NewReportPage />
        </Route>
        <Route path={`${urlPrefix}/mapping/report/:reportId/`}>
          <UpdateReportPage />
        </Route>
        <Route path={`${urlPrefix}/mapping/reports`}>
          <ReportsListPage />
        </Route>
        <Route exact path={`${urlPrefix}/transactions-reconciled`}>
          <ReconciledTransactions />
        </Route>
        <Route exact path={`${urlPrefix}/transactions-reconcile-union`}>
          <UnionTransactionsReconcilePage isReconciledPage={false} />
        </Route>
        <Route exact path={`${urlPrefix}/new-transactions-reconcile-union`}>
          <NewTransactionReconcile />
        </Route>
        <Route exact path={isInsideRun ? '/recon/status' : '/'}>
          <ScriptsStatusesPage />
        </Route>
        <Route path={`${urlPrefix}/raw-data`}>
          <RawDataPage />
        </Route>
        <Route path={`${urlPrefix}/cp-data-monitor`}>
          <CpDataMonitor />
        </Route>
        <Route path={`${urlPrefix}/setup-etl`}>
          <SetupEtl />
        </Route>
        <Route path={`${urlPrefix}/break-dashboard`}>
          <BreakDashboard />
        </Route>
        <Route path={`${urlPrefix}/position-recon`}>
          <PositionRecon />
        </Route>
        <Route path={`${urlPrefix}/checks-of-movements`}>
          <ChecksOfMovements />
        </Route>
        <Route path={`${urlPrefix}/trade-monitor`}>
          <TradeReconMonitor />
        </Route>
      </Switch>
    </div>
  );
};

export default Router;
