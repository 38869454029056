import { FC, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Notification,
  Input,
  Select,
  Modal,
  TimePicker,
} from 'react-ui-kit-exante';

import { useAppSelector } from 'hooks/redux';
import {
  postSymbolCutOffTime,
  CutOffTimeBySymbolCreatePayload,
} from 'services/recon';
import { getSelectOptions } from 'utils/getSelectOptions';

interface Props {
  reloadData: VoidFunction;
  isOpened: boolean;
  onClose: VoidFunction;
}
const AddForm: FC<Props> = ({ reloadData, isOpened, onClose }) => {
  const { register, handleSubmit } = useForm<CutOffTimeBySymbolCreatePayload>();

  const cpList = useAppSelector((state) => state.cpList);

  const cpOptions = useMemo(
    () => getSelectOptions(cpList, 'cp', 'cp'),
    [cpList],
  );

  const [time, setTime] = useState('');
  const handleChangeTime = (date: string) => {
    setTime(date);
  };

  const handleCreateSymbolCot: SubmitHandler<
    CutOffTimeBySymbolCreatePayload
  > = async ({ symbol, cp }) => {
    try {
      await postSymbolCutOffTime({
        symbol,
        cp,
        time,
      });
      reloadData();
      onClose();
    } catch (error) {
      Notification.error({
        title: 'Create symbol cot error',
        description: String(error),
      });
    }
  };

  return (
    <Modal
      isOpened={isOpened}
      title="Symbol cot"
      onClose={onClose}
      confirmButton={{
        confirmButtonName: 'Create',
        handleConfirm: handleSubmit(handleCreateSymbolCot),
      }}
    >
      <div className="mt-4">
        <Input
          label="Symbol"
          className="my-3"
          fullWidth
          {...register('symbol')}
        />
        <Select
          label="Cp"
          className="mb-3 w-100"
          options={cpOptions}
          {...register('cp')}
        />
        <TimePicker
          label="Time"
          controlProps={{ fullWidth: true }}
          value={time}
          onChange={handleChangeTime}
        />
      </div>
    </Modal>
  );
};

export default AddForm;
