export function getSumFromArray<T extends Record<string, any>>(
  arr: T[],
  key: string,
) {
  return arr.reduce((acc, i) => acc + Number(i[key]), 0);
}

export function sortInAlphabet<T>(key: keyof T) {
  return (first: T, second: T) => {
    if (first[key] < second[key]) {
      return -1;
    }
    if (first[key] > second[key]) {
      return 1;
    }
    return 0;
  };
}
