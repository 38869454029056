export const getReconApiDomain = () =>
  window.RECON_UI?.api_urls?.RECON || process.env.REACT_APP_API_RECON || '';

export const getRunnerApiDomain = () =>
  window.RECON_UI?.api_urls?.RUNNER || process.env.REACT_APP_API_RUNNER || '';

export const getRawDataApiDomain = () =>
  window.RECON_UI?.api_urls?.RAW_DATA ||
  process.env.REACT_APP_API_RAW_DATA ||
  '';
