export const counterpartyOptions = [
  'BINANCE',
  'BITFINEX',
  'BITSTAMP',
  'DERIBIT',
  'GLOBITEX',
  'HITBTC',
  'LMAX',
  'OKCOIN',
];
