import { styled } from 'react-ui-kit-exante';

export const HeadDiv = styled('div')(() => ({
  padding: '0 24px 16px 24px',
}));
export const ActionsDiv = styled('div')(() => ({
  width: '300px',
  marginRight: '20px',
}));
export const FiltersDiv = styled('div')(() => ({
  marginTop: '22px',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const FormDiv = styled('div')<{ isNostro: boolean }>(({ isNostro }) => ({
  borderTop: '1px solid #EEF1F1',
  marginTop: '22px',
  paddingTop: '20px',
  display: 'grid',
  gridTemplateColumns: isNostro
    ? '315px 200px 1fr 160px'
    : '315px 200px 200px 1fr 160px',
}));
