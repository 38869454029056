import { ReactChild, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Panel,
  Notification,
  LoaderContainer,
  Loader,
} from 'react-ui-kit-exante';

import { getMappingReportWithFields, ReportWithFields } from 'services/recon';

import { ReportForm } from './ReportForm/ReportForm';
import { ReportFormProvider } from './ReportFormProvider';

export const UpdateReportPage = () => {
  const params = useParams<{ reportId?: string }>();
  const [report, setReport] = useState<ReportWithFields | null>(null);
  const [reportLoadIsFailed, setReportLoadIsFailed] = useState(false);
  const fetchReport = async () => {
    try {
      const response = await getMappingReportWithFields(params?.reportId ?? '');
      setReport(response);
    } catch (error) {
      setReportLoadIsFailed(true);
      Notification.error({
        title: 'Load report error',
        description: String(error),
      });
    }
  };
  useEffect(() => {
    if (params?.reportId) {
      fetchReport();
    }
  }, []);

  let updateComponent: ReactChild = (
    <LoaderContainer>
      <Loader isCentered size="l" />
    </LoaderContainer>
  );
  if (reportLoadIsFailed) {
    updateComponent = 'Load report error';
  }
  if (report && !reportLoadIsFailed) {
    updateComponent = (
      <ReportFormProvider isUpdate report={report} reloadData={fetchReport}>
        <ReportForm onNewReportCreated={() => undefined} />
      </ReportFormProvider>
    );
  }

  return (
    <div className="container-fluid text-left">
      <Panel title={`Update report ${params?.reportId}`}>
        <div className="mt-2">{updateComponent}</div>
      </Panel>
    </div>
  );
};
