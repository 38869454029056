import { FC } from 'react';

import './ErrorIndicator.css';

export const ErrorIndicator: FC = () => (
  <div className="container-fluid">
    <div className="error-indicator">
      <span className="boom">BOOM!</span>
      <span>Something has gone terribly wrong</span>
      <span>Please reload a page and try again</span>
    </div>
  </div>
);
