import dayjs from 'dayjs';
import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import { DATE_FORMAT } from 'constants/date';
import { getDateForRequest } from 'utils';

import { FetchArrayResponseWithoutNext } from '../../types';
import { getMultiSortingParams, getUrlFromPayload } from '../../utils';
import { ReconApiService } from '../api';
import { CpAndCTrade, SIDE, BreakItemsFilters } from '../types';

import {
  ExpectedSymbolMapping,
  PatchCpAndCTradePayload,
  ReconcileTradesPayload,
  CpAndCTradeWithRecStatus,
} from './types';

const breaksStartInfo = '2022-01-01';

export async function getTradeBreakItems(
  { params }: IOnFetchArguments,
  filters: BreakItemsFilters,
) {
  try {
    const payload = {
      from_date: breaksStartInfo,
      to_date: dayjs().format(DATE_FORMAT),
      page: params.page + 1,
      limit: params.limit,
      side: filters.sideFilter,
      mode: filters.modeFilter,
      break_report_filter: true,
      counterparty: filters.cpFilter,
      le: filters.leFilter,
      break_report_category: filters.categoryFilter,
      type_of_trade: 'Trade',
    };
    const response = await ReconApiService.getResource<
      FetchArrayResponseWithoutNext<CpAndCTrade>
    >(
      `${getUrlFromPayload(
        'trade-recon/get-cptrades-ctrades/?',
        payload,
      )}order=${getMultiSortingParams(params.sorting)}`,
    );
    return {
      items: response.results,
      pagination: { total: response.total },
    };
  } catch (e) {
    Notification.error({ title: `Get breaks error: ${e}` });
  }
  return {
    items: [],
    pagination: { total: 0 },
  };
}

export function getCpAndCTradesUrl(
  { sortingParams, paginationParams, filtersParams, params }: IOnFetchArguments,
  isReconciled = false,
) {
  const {
    date = [],
    break_report_category: breakReportCategory = [],
    skip,
    ...otherFilters
  } = {
    ...filtersParams,
    ...paginationParams,
  };
  const [fromDate, toDate] = (date as string[]) ?? [];
  const payload: Record<string, any> = {
    ...otherFilters,
    page_number: params?.page,
    from_date: getDateForRequest(fromDate),
    to_date: getDateForRequest(toDate),
    reconciled: isReconciled,
    type_of_trade: 'Trade',
  };
  if (!isReconciled) {
    payload.break_report_category = breakReportCategory;
  }
  const sorting = getMultiSortingParams(sortingParams);
  const sortingUrl = isReconciled
    ? `order=recon_group_id_desc,${sorting}`
    : `order=${sorting}`;
  return {
    url: `${getUrlFromPayload('trade-recon/get-cptrades-ctrades/?', payload)}${
      sorting.length ? sortingUrl : ''
    }`,
    dates: {
      fromDate: getDateForRequest(fromDate),
      toDate: getDateForRequest(toDate),
    },
  };
}
export async function getCpAndCTrades(
  payload: IOnFetchArguments,
  isReconciled = false,
) {
  try {
    const response = await ReconApiService.getResource<
      FetchArrayResponseWithoutNext<CpAndCTrade>
    >(getCpAndCTradesUrl(payload, isReconciled).url);
    return {
      trades: response.results,
      pagination: { total: response.total } as any,
    };
  } catch (error) {
    Notification.error({
      title: 'Load trades error',
      description: JSON.stringify(error),
    });
  }
  return {
    trades: [],
    pagination: {},
  };
}

export function getCpTradesUrl({ params, filtersParams }: IOnFetchArguments) {
  const { page, limit, sorting } = params;
  const { date, trade_type: tradeType = '', ...otherFilters } = filtersParams;
  const [fromDate, toDate] = (date as string[]) ?? [];
  const payload: Record<string, any> = {
    ...otherFilters,
    page_number: page,
    limit,
    le: filtersParams.le,
    from_date: getDateForRequest(fromDate),
    to_date: getDateForRequest(toDate),
    side: SIDE.THEIR,
  };
  if (tradeType) {
    payload.type_of_trade = tradeType;
  }

  return {
    url: `${getUrlFromPayload(
      'trade-recon/get-cptrades-ctrades/?',
      payload,
    )}order=${getMultiSortingParams(sorting)}`,
    dates: {
      fromDate: getDateForRequest(fromDate),
      toDate: getDateForRequest(toDate),
    },
  };
}
export async function getCpTrades(params: IOnFetchArguments) {
  try {
    const response = await ReconApiService.getResource<
      FetchArrayResponseWithoutNext<CpAndCTradeWithRecStatus>
    >(getCpTradesUrl(params).url);
    return {
      trades: response.results,
      pagination: { total: response.total },
    };
  } catch (error) {
    Notification.error({
      title: 'Load cp trades error',
      description: JSON.stringify(error),
    });
  }
  return {
    trades: [],
    pagination: { total: 0 },
  };
}

export function getCTradesUrl({ params, filtersParams }: IOnFetchArguments) {
  const { page, limit, sorting } = params;
  const { date, recstatus, ...otherFilters } = filtersParams;
  const [fromDate, toDate] = (date as string[]) ?? [];
  const payload: Record<string, any> = {
    ...otherFilters,
    page_number: page,
    limit,
    le: filtersParams.le,
    from_date: getDateForRequest(fromDate),
    to_date: getDateForRequest(toDate),
    side: SIDE.OUR,
    reconciled: recstatus,
  };
  const url = `${getUrlFromPayload(
    'trade-recon/get-cptrades-ctrades/?',
    payload,
  )}order=${getMultiSortingParams(sorting)}`;

  return {
    url,
    dates: {
      fromDate: getDateForRequest(fromDate),
      toDate: getDateForRequest(toDate),
    },
  };
}

export async function getCTrades(params: IOnFetchArguments) {
  try {
    const response = await ReconApiService.getResource<
      FetchArrayResponseWithoutNext<CpAndCTradeWithRecStatus>
    >(getCTradesUrl(params).url);
    return {
      trades: response.results.map((trade) => ({
        ...trade,
        recstatus: !!trade.recon_group_id,
      })),
      pagination: { total: response.total },
    };
  } catch (error) {
    Notification.error({
      title: 'Load trades error',
      description: JSON.stringify(error),
    });
  }
  return {
    trades: [],
    pagination: {},
  };
}

export async function searchOneToOneMapping(
  date: string,
  counterparty: string,
) {
  const url = `trade-recon/search-one-to-one-mapping/?date=${date}&cp=${counterparty}`;
  return ReconApiService.getResource<ExpectedSymbolMapping[]>(url);
}

export async function patchCpAndCTrade(payload: PatchCpAndCTradePayload) {
  const url = `trade-recon/edit-cptrades-ctrades/`;
  const tempPayload = payload;
  if (payload.cp) {
    tempPayload.cp = tempPayload.cp === 'null' ? null : tempPayload.cp;
  }
  return ReconApiService.patch<PatchCpAndCTradePayload>(url, tempPayload);
}

export async function reconcileTrades(payload: ReconcileTradesPayload) {
  return ReconApiService.postResource<
    ReconcileTradesPayload,
    ReconcileTradesPayload
  >('trade-recon/reconcile-trades/', payload);
}

export async function unReconcileTrades(payload: any) {
  return ReconApiService.postResource<
    { group_id: string },
    { message: string }
  >('trade-recon/unreconcile-group/', payload);
}
