export const bannedFieldsList = [
  'compare_fields',
  'id',
  'parsing_tag',
  'parsing_uniq_hash',
  'raw',
  'report',
  'ruleid',
  'source_file',
  'timestamp',
  'uid',
  'value_rules_applied',
];

export const optionsBoolean = [
  { label: 'Reset', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];
