import {
  FileMonitorAdditionalFilters,
  CpDataMonitorItemStatuses,
} from '../types';

export enum ProcessTypes {
  RAW = 'raw',
  VERIFY = 'verify',
}

export interface CpStatusItem {
  report_id?: number;
  cpstatus_id?: number;
  date: string;
  mode: string;
  counterparty_name: string;
  legal_entity_name: string;
  process: ProcessTypes;
  log?: string;
  status: CpDataMonitorItemStatuses;
  type: string;
  user_name: string;
}

export interface CpStatusMonitorAdditionalFilters
  extends FileMonitorAdditionalFilters {
  process: ProcessTypes;
}
