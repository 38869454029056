import dayjs from 'dayjs';
import React, { ChangeEvent, FC, SyntheticEvent, useMemo } from 'react';
import {
  Button,
  ButtonDatePicker,
  Checkbox,
  FormHelperText,
  Input,
  Select,
  Autocomplete,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-ui-kit-exante';

import { FILTERS_DATE_FORMAT } from 'constants/date';
import { SelectOption } from 'types';

import { ActionsDiv, FiltersDiv, FormDiv, HeadDiv } from '../styled';

interface IProps {
  leId: number;
  onChangeLeId: (event: ChangeEvent<HTMLInputElement>) => void;
  leOptions: SelectOption[];
  cpId?: number;
  onChangeCpId: (event: SyntheticEvent, newValue: any) => void;
  cp?: string;
  onChangeCp: (event: ChangeEvent<HTMLInputElement>) => void;
  cpOptions: SelectOption[];
  breakCategory?: string;
  onChangeBreakCategory: (event: ChangeEvent<HTMLInputElement>) => void;
  breakCategoryOptions: SelectOption[];
  isShowAllBreaks: boolean;
  setIsShowAllBreaks: (val: boolean) => void;
  isShowAllPositions: boolean;
  setIsShowAllPositions: (val: boolean) => void;
  dateFields: (Date | null)[];
  onChangeStartDate: (date: Date | null) => void;
  onChangeEndDate: (date: Date | null) => void;
  confirmIsAvailable: boolean;
  onConfirmBreaks: VoidFunction;
  onMatchBreaks: VoidFunction;
  breakComment: string;
  onChangeComment: (event: ChangeEvent<HTMLInputElement>) => void;
  updateIsAvailable?: boolean;
  matchIsAvailable: boolean;
  onUpdateBreaks: VoidFunction;
  modeId: number;
  setModeId: (val: number) => void;
  modeIds: { client: number; nostro: number };
  isNostro: boolean;
  isSymbolFilterExist: boolean;
  isIsinFilterExist: boolean;
}
export const Head: FC<IProps> = ({
  leId,
  onChangeLeId,
  leOptions,
  cpId,
  onChangeCpId,
  breakCategoryOptions,
  onChangeBreakCategory,
  onChangeComment,
  onConfirmBreaks,
  isShowAllBreaks,
  cpOptions,
  isShowAllPositions,
  onMatchBreaks,
  onUpdateBreaks,
  onChangeCp,
  confirmIsAvailable,
  breakComment,
  cp,
  breakCategory,
  setIsShowAllBreaks,
  setIsShowAllPositions,
  updateIsAvailable,
  matchIsAvailable,
  onChangeStartDate,
  onChangeEndDate,
  dateFields,
  modeId,
  setModeId,
  modeIds,
  isNostro,
  isSymbolFilterExist,
  isIsinFilterExist,
}) => {
  const datesBlock = useMemo(() => {
    const firstDate = dayjs(dateFields[0]);
    if (!isSymbolFilterExist && !isIsinFilterExist) {
      return firstDate.format(FILTERS_DATE_FORMAT);
    }
    const secondDate = dayjs(dateFields[1]);
    return `${firstDate.format(FILTERS_DATE_FORMAT)} - ${
      secondDate.isValid()
        ? secondDate.format(FILTERS_DATE_FORMAT)
        : firstDate.format(FILTERS_DATE_FORMAT)
    }`;
  }, [isSymbolFilterExist, isIsinFilterExist, dateFields]);

  const onChangeDate = (value: Date | [Date | null, Date | null] | null) => {
    if (Array.isArray(value)) {
      const [start, end] = value;
      onChangeStartDate(start);
      onChangeEndDate(end);
    } else {
      onChangeStartDate(value);
    }
  };

  const showRange = isSymbolFilterExist || isIsinFilterExist;

  return (
    <HeadDiv>
      <Tabs value={modeId} onChange={(_, val) => setModeId(val)}>
        <Tab label="Client" value={modeIds.client} />
        <Tab label="Nostro" value={modeIds.nostro} />
      </Tabs>
      <FiltersDiv>
        <div className="d-flex align-items-end">
          <ActionsDiv>
            <Select
              fullWidth
              label="Legal Entity"
              value={leId}
              onChange={onChangeLeId}
              options={leOptions}
            />
          </ActionsDiv>
          {isNostro && (
            <ActionsDiv>
              <Autocomplete
                placeholder="Counterparty"
                fullWidth
                options={cpOptions}
                value={cpOptions.find(
                  (item) => item.value === String(cpId ?? ''),
                )}
                onChange={onChangeCpId}
              />
            </ActionsDiv>
          )}
        </div>
        <div className="d-flex align-items-end">
          <Checkbox
            checked={isShowAllBreaks}
            onChange={(_, checked) => {
              setIsShowAllBreaks(checked);
            }}
            label="Show all breaks"
            className="mr-3"
          />
          <Checkbox
            checked={isShowAllPositions}
            onChange={(_, checked) => {
              setIsShowAllPositions(checked);
            }}
            label="Show all positions"
            className="mr-3"
          />
          <ButtonDatePicker
            selectsRange={showRange}
            onChange={onChangeDate}
            startDate={showRange ? dateFields[0] : undefined}
            endDate={showRange ? dateFields[1] : undefined}
            selected={!showRange ? dateFields[0] : undefined}
          />
          <div className="ml-1">{datesBlock}</div>
        </div>
      </FiltersDiv>
      <FormDiv isNostro={isNostro}>
        <div>
          <ToggleButtonGroup className="mr-3" fullWidth value="first">
            <ToggleButton
              value="confirm"
              error={false}
              disabled={!confirmIsAvailable}
              onClick={onConfirmBreaks}
              color="warning"
            >
              Confirm Break
            </ToggleButton>
            <ToggleButton
              value="right"
              error={false}
              disabled={!matchIsAvailable}
              onClick={onMatchBreaks}
            >
              Match symbols
            </ToggleButton>
            <FormHelperText error={false} />
          </ToggleButtonGroup>
        </div>
        {!isNostro && (
          <div>
            <Select
              className="mr-3"
              placeholder="Counterparty"
              fullWidth
              value={cp}
              onChange={onChangeCp}
              options={cpOptions}
            />
          </div>
        )}
        <div>
          <Select
            className="mr-3"
            placeholder="Break Category"
            fullWidth
            value={breakCategory}
            onChange={onChangeBreakCategory}
            options={breakCategoryOptions}
          />
        </div>
        <div>
          <Input
            className="mr-3"
            label="Comment"
            fullWidth
            value={breakComment}
            onChange={onChangeComment}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button disabled={!updateIsAvailable} onClick={onUpdateBreaks}>
            Update Break
          </Button>
        </div>
      </FormDiv>
    </HeadDiv>
  );
};
