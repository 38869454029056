import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  Notification,
  Select,
  Modal,
  InputDatePicker,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import { useAppSelector } from 'hooks/redux';
import {
  addRightUser,
  BreakTypes,
  CreateRightUsersPayload,
} from 'services/recon';
import { userNamesSelector } from 'store/reducers/commonReducer';
import { getSelectOptions } from 'utils/getSelectOptions';

interface IProps {
  reloadData: VoidFunction;
  isOpened: boolean;
  setIsOpened: (val: boolean) => void;
}
export const AddForm: FC<IProps> = ({ reloadData, isOpened, setIsOpened }) => {
  const entities = useAppSelector(
    (state) => state.new_legal_entity_lists.brokerAndBank,
  );
  const userNames = useAppSelector(userNamesSelector);

  const entityOptions = getSelectOptions(entities, 'name', 'id');
  const userOptions = getSelectOptions(userNames);
  const dataOptions = getSelectOptions([
    BreakTypes.TRADE,
    BreakTypes.TRANSACTION,
  ]);

  const { register, handleSubmit } = useForm<CreateRightUsersPayload>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleChangeStarDate = (date: Date | null) => {
    setStartDate(date);
  };
  const handleChangeEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const handleCloseModal = () => {
    setIsOpened(false);
  };
  const handleAddUser: SubmitHandler<CreateRightUsersPayload> = async (
    data,
  ) => {
    try {
      await addRightUser({
        username: data.username,
        role: 'recon',
        legal_entity_id: data.legal_entity_id,
        data_type: data.data_type,
        start_date: dayjs(startDate).format(DATE_FORMAT),
        end_date: endDate ? dayjs(endDate).format(DATE_FORMAT) : null,
      });
      Notification.success({
        title: 'Schedule was added',
      });
      reloadData();
      setIsOpened(false);
    } catch (error) {
      Notification.error({ title: `Add schedule error: ${error}` });
    }
  };
  return (
    <Modal
      isOpened={isOpened}
      title="Set Schedule"
      onClose={handleCloseModal}
      confirmButton={{
        confirmButtonName: 'Submit',
        handleConfirm: handleSubmit(handleAddUser),
      }}
    >
      <>
        <Select
          label="Data type"
          className="w-100"
          options={dataOptions}
          {...register('data_type')}
        />
        <Select
          label="User"
          className="w-100"
          options={userOptions}
          {...register('username')}
        />
        <Select
          label="Legal Entity"
          className="w-100"
          options={entityOptions}
          {...register('legal_entity_id')}
        />
        <InputDatePicker
          locale={defaultLocale}
          onChange={handleChangeStarDate}
          inputProps={{
            label: 'Start date',
            fullWidth: true,
          }}
          minDate={new Date()}
          selected={startDate}
        />

        <InputDatePicker
          locale={defaultLocale}
          onChange={handleChangeEndDate}
          inputProps={{
            label: 'End date',
            fullWidth: true,
          }}
          minDate={startDate ? new Date(startDate) : new Date()}
          selected={endDate}
        />
      </>
    </Modal>
  );
};
