import dayjs from 'dayjs';
import { IColumn, ICellValue } from 'react-ui-kit-exante';

import { DATE_FORMAT } from 'constants/date';
import { PositionRec } from 'services/recon';

import { ColumnsPositionRecProps } from '../types';

export const DISPLAYED_COLUMN_KEYS = [
  'report_date',
  'cp',
  'cp_symbol',
  'bo_symbol',
  'qty',
  'timestamp',
  'account',
  'price',
  'qty_long',
  'qty_short',
  'value_date',
  'cp_qty',
  'ccy',
  'isin',
  'type',
  'symboltype',
  'value',
  'exchange',
  'comment',
  'underlying',
  'cusip',
  'sedol',
  'option_type',
  'strike',
  'contract_size',
];

export const getColumns = ({
  onRemove,
  onFilter,
  modeOptions,
  cpOptions,
  entityOptions,
}: ColumnsPositionRecProps): IColumn<PositionRec>[] => [
  {
    Header: 'Id',
    accessor: 'id',
    width: 100,
    disableFilters: true,
  },
  {
    Header: 'assignee',
    accessor: 'assignee',
    disableFilters: true,
  },
  {
    Header: 'Report date',
    accessor: 'report_date',
    type: 'dateInput',
    useLocale: false,
    onFilter,
    onRemove,
  },
  {
    Header: 'Cp',
    accessor: 'counterparty',
    type: 'select',
    onFilter,
    onRemove,
    filterOptions: cpOptions,
  },
  {
    Header: 'Legal Entity',
    accessor: 'legal_entity',
    type: 'select',
    onFilter,
    onRemove,
    filterOptions: entityOptions,
  },
  {
    Header: 'Mode',
    accessor: 'mode',
    type: 'select',
    filterOptions: modeOptions,
    onFilter,
    onRemove,
  },
  {
    Header: 'Cp symbol',
    accessor: 'cp_symbol',
    onFilter,
    onRemove,
  },
  {
    Header: 'Bo symbol',
    accessor: 'bo_symbol',
    onFilter,
    onRemove,
    editParams: {
      editable: true,
      inputType: 'text',
    },
  },
  {
    Header: 'Qty',
    accessor: 'qty',
    disableFilters: true,
    editParams: {
      editable: true,
      inputType: 'text',
    },
  },
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
    disableFilters: true,
    Cell: ({ row }: ICellValue<PositionRec>) => {
      const timestamp = row.values?.timestamp;
      return (
        <span title={dayjs(timestamp).format(DATE_FORMAT)}>
          {timestamp ? dayjs(timestamp).format(DATE_FORMAT) : '–'}
        </span>
      );
    },
  },
  {
    Header: 'Account',
    accessor: 'account',
    disableFilters: true,
  },
  {
    Header: 'Price',
    accessor: 'price',
    disableFilters: true,
    editParams: {
      editable: true,
      inputType: 'text',
    },
  },
  {
    Header: 'Quantity long',
    accessor: 'qty_long',
    disableFilters: true,
    editParams: {
      editable: true,
      inputType: 'text',
    },
  },
  {
    Header: 'Quantity short',
    accessor: 'qty_short',
    disableFilters: true,
    editParams: {
      editable: true,
      inputType: 'text',
    },
  },
  {
    Header: 'Value date',
    accessor: 'value_date',
    disableFilters: true,
  },
  {
    Header: 'Cp qty',
    accessor: 'cp_qty',
    disableFilters: true,
    editParams: {
      editable: true,
      inputType: 'text',
    },
  },
  {
    Header: 'Сcy',
    accessor: 'ccy',
    disableFilters: true,
  },
  {
    Header: 'Isin',
    accessor: 'isin',
    disableFilters: true,
  },
  {
    Header: 'type',
    accessor: 'type',
    disableFilters: true,
  },
  {
    Header: 'symboltype',
    accessor: 'symboltype',
    disableFilters: true,
  },
  {
    Header: 'value',
    accessor: 'value',
    disableFilters: true,
  },
  {
    Header: 'exchange',
    accessor: 'exchange',
    disableFilters: true,
  },
  {
    Header: 'comment',
    accessor: 'comment',
    disableFilters: true,
  },
  {
    Header: 'underlying',
    accessor: 'underlying',
    disableFilters: true,
  },
  {
    Header: 'cusip',
    accessor: 'cusip',
    disableFilters: true,
  },
  {
    Header: 'sedol',
    accessor: 'sedol',
    disableFilters: true,
  },
  {
    Header: 'option type',
    accessor: 'option_type',
    disableFilters: true,
  },
  {
    Header: 'strike',
    accessor: 'strike',
    disableFilters: true,
  },
  {
    Header: 'contract size',
    accessor: 'contract_size',
    disableFilters: true,
  },
];
