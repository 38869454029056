import dayjs from 'dayjs';

import { DATE_FORMAT } from 'constants/date';

export * from './getCulumns';

const editTableValueMapping: Record<string, string> = {
  operationtype: 'operation_type',
  reportdate: 'report_date',
  symbol: 'cp_symbol',
};

export const getDiffBetweenObjects = (
  prev: Record<string, any>,
  next: Record<string, any>,
): Record<string, any> =>
  Object.keys(next).reduce((acc, item) => {
    const prevValue = prev[item];
    const nextValue = next[item];
    const fieldName = editTableValueMapping[item] ?? item;

    const isDate = item === 'reportdate';
    const dateValue = dayjs(nextValue).format(DATE_FORMAT);

    return prevValue === nextValue
      ? acc
      : { ...acc, [fieldName]: isDate ? dateValue : nextValue };
  }, {});
