import { SelectOption } from '../types';

import { isGozo } from './app';

export const minColumnWidth = 100;
export const editColumnWidth = 260;
export const defaultPageSize = 30;
export const pageSizes = [5, 10, 30, 50, 100, 200];
export const defaultOrdering = [{} as any];

const DEFAULT_FILTER_VALUES_FOR_EXANTE = {
  le: 'XNT',
  cp: 'Cowen',
  mode: 'Client-CP',
};

const DEFAULT_FILTER_VALUES_FOR_GOZO = {
  le: 'iHodl',
  cp: 'Binance',
  mode: 'Client-CP',
};

const filtersByUrl = isGozo
  ? DEFAULT_FILTER_VALUES_FOR_GOZO
  : DEFAULT_FILTER_VALUES_FOR_EXANTE;

const filtersByRun = {
  le: window.RECON_UI?.params?.le ?? '',
  cp: window.RECON_UI?.params?.cp ?? '',
  mode: window.RECON_UI?.params?.mode ?? '',
};
export const DEFAULT_FILTER_VALUES = window.RECON_UI?.params?.le
  ? filtersByRun
  : filtersByUrl;

export const unknownCpOption: SelectOption = {
  value: 'null',
  label: 'UNKNOWN',
};
