import dayjs from 'dayjs';
import { useCallback, useMemo, useRef } from 'react';
import {
  IOnFetchArguments,
  Notification,
  OnSaveEditableRow,
} from 'react-ui-kit-exante';

import { DEFAULT_FILTER_VALUES } from 'constants/tables';
import {
  bulkPatchBoFinancialTransactions,
  getCpBoTransactions,
  patchBoFinancialTransaction,
  patchCpTransaction,
  SIDE,
  Transaction,
} from 'services/recon';
import { getDefaultFilterParams } from 'utils';

import { getDiffBetweenObjects, getTransactionsTableColumns } from './utils';

export function useGetTableData(
  isReconciledPage: boolean,
  counterparty: string,
  firstLegalEntity: string,
  breakCategoryObject: Record<string, string>,
) {
  const filtersIsFirstMounted = useRef(false);
  const isFirstRequest = useRef(true);

  const tableId = isReconciledPage
    ? 'DivideReconciledTransactions'
    : 'MixedTransactions';
  const filterKeys = getTransactionsTableColumns({}, isReconciledPage).map(
    (column) => column.accessor,
  );

  const applyObject = useMemo(() => {
    return {
      reportdate: [dayjs().subtract(7, 'day').toDate(), dayjs().toDate()],
      mode: DEFAULT_FILTER_VALUES.mode,
      operationtype: ['FUNDING/WITHDRAWAL'],
      counterparty,
    };
  }, []);

  const prepareFiltersForParams = useCallback(
    getDefaultFilterParams({
      filterKeys,
      applyObject,
      isApply: filtersIsFirstMounted,
      shouldApply: !JSON.parse(
        localStorage.getItem(`${tableId}-view-params`) ?? '{}',
      )?.filters,
      tableId,
    }),
    [],
  );

  const getRequestPayload = useCallback((props: IOnFetchArguments) => {
    const { break_report_category: breakReportCategory } = props.filtersParams;
    return {
      ...props,
      filtersParams: {
        ...props.filtersParams,
        break_report_category:
          (breakReportCategory as string[])?.map(
            (name) => breakCategoryObject[name],
          ) ?? undefined,
      },
    };
  }, []);

  const getTransactions = useCallback((props: IOnFetchArguments) => {
    if (isFirstRequest.current) {
      isFirstRequest.current = false;
      return Promise.resolve().then(() => ({
        transactions: [] as Transaction[],
        pagination: { total: 0 },
      }));
    }
    return getCpBoTransactions(getRequestPayload(props), isReconciledPage);
  }, []);

  const tableData = useMemo(
    () => ({
      tableId,
      data: { onFetch: getTransactions },
      saveViewParamsAfterLeave: true,
      filters: {
        prepareFiltersForParams,
        getDefaultFilters: () => ({
          le: firstLegalEntity,
        }),
      },
      pagination: { getDefaultPagination: () => ({ limit: 5, skip: 0 }) },
    }),
    [getTransactions],
  );

  return {
    tableData,
    tableId,
    getRequestPayload,
  };
}

interface InlineEditPayload {
  selectedTransactions: Transaction[];
  setSelectedTransactions: (values: Transaction[]) => void;
  fetchData: VoidFunction;
  dataTransactions?: Transaction[];
  items: Transaction[];
  setItems: (values: Transaction[]) => void;
}
export function useInlineEdit({
  selectedTransactions,
  setSelectedTransactions,
  fetchData,
  dataTransactions,
  items,
  setItems,
}: InlineEditPayload) {
  const handleUpdateOurTransaction = async (
    id: number,
    transaction: Record<string, any>,
  ) => {
    try {
      const {
        report_date: reportDate,
        cpname: counterparty,
        ...otherFields
      } = transaction;
      const selectedIds = selectedTransactions.map((item) => item.id);
      if (selectedIds.length) {
        await bulkPatchBoFinancialTransactions(selectedIds, {
          ...otherFields,
          counterparty,
          cp_report_date: reportDate,
        });
      } else {
        await patchBoFinancialTransaction(id, {
          ...otherFields,
          counterparty,
          cp_report_date: reportDate,
        });
      }

      Notification.success({
        title: 'Our transaction updated',
      });
      setSelectedTransactions([]);
      await fetchData();
      return true;
    } catch (_) {
      Notification.error({ title: 'Update our transaction error' });
      return false;
    }
  };

  const handleUpdateTheirTransaction = async (
    id: number,
    transaction: Record<string, any>,
  ) => {
    try {
      await patchCpTransaction(id, transaction);
      Notification.success({ title: 'Update their transaction' });
      await fetchData();
      return true;
    } catch (_) {
      Notification.error({ title: 'Update their transaction error' });
      return false;
    }
  };

  const handleSaveEditableRow: OnSaveEditableRow<Transaction> = async (
    original,
  ) => {
    const previousValues = dataTransactions?.find(
      (item) => item.id === original.id,
    );
    const updatedValues = items.find((item) => item.id === original.id);
    if (!previousValues || !updatedValues) {
      return;
    }
    const diff = getDiffBetweenObjects(previousValues, updatedValues);
    if (!Object.values(diff).length) {
      return;
    }
    if (previousValues.side === SIDE.THEIR) {
      await handleUpdateTheirTransaction(updatedValues.id, diff);
    } else {
      await handleUpdateOurTransaction(updatedValues.id, diff);
    }
  };

  const handleCancelEditableRow: OnSaveEditableRow<Transaction> = async (
    original,
  ) => {
    const previousValues = dataTransactions?.find(
      (item) => item.id === original.id,
    );
    const foundIndex = items.findIndex((item) => item.id === original.id);
    if (foundIndex >= 0 && previousValues) {
      const bufItems = [...items];
      bufItems[foundIndex] = previousValues;
      setItems(bufItems);
    }
  };

  return {
    handleCancelEditableRow,
    handleSaveEditableRow,
  };
}
