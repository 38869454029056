export interface Script {
  id: number;
  name: string;
  group: string;
  current_state: string;
  args_str: string;
  last_run_time: null | string;
  last_success_time: null | string;

  script_type: string;
  start_time: string;
  end_time: string;

  added_to_monitor: boolean;
  allow_run_for_date: boolean;
  allow_weekend: boolean;
  args_for_date_format: string;
  check_notify_emails_str: string;
  compose_name: string;
  counterparty: string;
  data_found_time: null | string;
  email_notification: boolean;
  env_variables_str: string;
  last_email_warning_date: null | string;
  maintainers: string;
  periodic_task: null | string;
  post_args_delay: number;
  post_args_for_date_format: string;
  post_args_str: string;
  related_legal_entity: null | string;
  run_anyway: boolean;
  time_limit: number;
}
export enum ScriptStates {
  SUCCESS = 'success',
  FAILED = 'failed',
  RUNNING = 'running',
  STARTED = 'started',
}

export interface ScriptRunStatuses {
  id: number;
  for_date: string;
  full_name: string;
  name: string;
  run_time: string;
  script_id: number;
  script_model: string;
  state: ScriptStates;
}
export interface RunScriptsPayload {
  date?: string;
  scripts: string | string[];
  update_mapping?: boolean;
  update_table?: boolean;
  force_run: boolean;
  use_bo_drive: boolean;
}
export interface RunScriptsResponse {
  scripts: string | string[];
  status: string;
  message: string;
}
export interface RunByCPAndTypePayload {
  cp: string;
  type: string;
}
export interface RunByCPAndTypeResponse {
  status: 'success' | 'failed';
  message: string;
  scripts: string[];
}
