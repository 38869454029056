import dayjs from 'dayjs';
import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import { DATE_FORMAT } from 'constants/date';
import { getDateForRequest } from 'utils';

import { FetchArrayResponseWithoutNext } from '../../types';
import { getMultiSortingParams, getUrlFromPayload } from '../../utils';
import { ReconApiService } from '../api';
import { Transaction, BreakItemsFilters } from '../types';

import { ReconcileTransactionsPayload } from './types';

const breaksStartInfo = '2022-01-01';

export async function getTransactionBreakItems(
  { params }: IOnFetchArguments,
  filters: BreakItemsFilters,
) {
  try {
    const payload = {
      from_date: breaksStartInfo,
      to_date: dayjs().format(DATE_FORMAT),
      page: params.page + 1,
      limit: params.limit,
      side: filters.sideFilter,
      mode: filters.modeFilter,
      break_report_filter: true,
      counterparty: filters.cpFilter,
      le: filters.leFilter,
      break_report_category: filters.categoryFilter,
    };
    const response = await ReconApiService.getResource<
      FetchArrayResponseWithoutNext<Transaction>
    >(
      `${getUrlFromPayload(
        'transactions/get-cp-bo-transactions/?',
        payload,
      )}order=${getMultiSortingParams(params.sorting)}`,
    );
    return {
      items: response.results,
      pagination: { total: response.total },
    };
  } catch (e) {
    Notification.error({ title: `Get breaks error: ${e}` });
  }
  return {
    items: [],
    pagination: { total: 0 },
  };
}

export function getCpBoTransactionsUrl(
  { sortingParams, paginationParams, filtersParams, params }: IOnFetchArguments,
  isReconciled = false,
) {
  const {
    reportdate: reportDate = [],
    legal_entity: legalEntity = '',
    break_report_category: breakReportCategory = [],
    skip,
    ...otherFilters
  } = {
    ...filtersParams,
    ...paginationParams,
  };
  const [fromDate, toDate] = (reportDate as string[]) ?? [];
  const payload: Record<string, any> = {
    ...otherFilters,
    page_number: params.page,
    legal_entity: legalEntity,
    from_date: getDateForRequest(fromDate),
    to_date: getDateForRequest(toDate),
    reconciled: isReconciled,
  };
  if (!isReconciled) {
    payload.break_report_category = breakReportCategory;
  }

  const urlWithFilters = getUrlFromPayload(
    'transactions/get-cp-bo-transactions/?',
    payload,
  );
  const sorting = getMultiSortingParams(sortingParams);
  const mappedSorting = `${
    isReconciled ? 'order=recon_group_id_desc,' : 'order='
  }${sorting}`;
  return {
    url: `${urlWithFilters}${sorting.length ? mappedSorting : ''}`,
    dates: {
      fromDate: getDateForRequest(fromDate),
      toDate: getDateForRequest(toDate),
    },
  };
}

export async function getCpBoTransactions(
  payload: IOnFetchArguments,
  isReconciled = false,
) {
  try {
    const response = await ReconApiService.getResource<
      FetchArrayResponseWithoutNext<Transaction>
    >(getCpBoTransactionsUrl(payload, isReconciled).url);
    return {
      transactions: response.results,
      pagination: { total: response.total } as any,
    };
  } catch (error) {
    Notification.error({
      title: 'Load transactions error',
      description: JSON.stringify(error),
    });
  }
  return {
    transactions: [],
    pagination: {},
  };
}

export async function postReconcileTransactions(
  payload: ReconcileTransactionsPayload,
) {
  const url = 'transactions/reconcile/';
  return ReconApiService.postResource<
    ReconcileTransactionsPayload,
    ReconcileTransactionsPayload
  >(url, payload);
}

export async function deleteReconcileTransactions(payload: {
  group_id: number;
}) {
  const url = 'transactions/unreconcile/';
  return ReconApiService.postResource<
    { group_id: number },
    { group_id: number }
  >(url, payload);
}
