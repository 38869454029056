import dayjs from 'dayjs';
import { useState } from 'react';
import {
  IColumn,
  ICellValue,
  Input,
  Button,
  Notification,
} from 'react-ui-kit-exante';

import { ExpectedSymbolMapping, postSymbolsMapping } from 'services/recon';

export const getColumns = (
  selectedDate: string,
): IColumn<ExpectedSymbolMapping>[] => [
  {
    Header: 'CP',
    accessor: 'cp',
  },
  {
    Header: 'CP Symbol',
    accessor: 'cp_symbol',
  },
  {
    Header: 'Exante Symbol',
    accessor: 'exante_symbol',
  },
  {
    Header: 'CCY',
    accessor: 'ccy',
  },
  {
    Header: 'Isin',
    accessor: 'isin',
  },
  {
    Header: 'Exante isin',
    accessor: 'exante_isin',
  },
  {
    Header: 'Mty Value',
    accessor: 'mty',
    Cell: ({ row }: ICellValue<ExpectedSymbolMapping>) => (
      <Input
        size="small"
        defaultValue={row.original.mty}
        id={`id_${row.id}`}
        name="mty"
      />
    ),
  },
  {
    Header: 'Mty Price',
    accessor: 'cmty',
    Cell: ({ row }: ICellValue<ExpectedSymbolMapping>) => (
      <Input
        size="small"
        defaultValue={row.original.cmty}
        id={`id_${row.id}`}
        name="cmty"
      />
    ),
  },
  {
    Header: 'Mty Qty',
    accessor: 'mty_qty',
    Cell: ({ row }: ICellValue<ExpectedSymbolMapping>) => (
      <Input
        size="small"
        defaultValue={row.original.mty_qty}
        id={`id_${row.id}`}
        name="mty_qty"
      />
    ),
  },
  {
    Header: 'Exact',
    accessor: 'exact',
    Cell: ({ row }: ICellValue<ExpectedSymbolMapping>) => (
      <span>{row.values.exact ? 'YES' : 'NO'}</span>
    ),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: ({ row }: ICellValue<ExpectedSymbolMapping>) => {
      const [disabled, setDisabled] = useState(false);
      const handleClick = async () => {
        const elements = document.querySelectorAll(`#id_${row.id}`);
        const {
          mty,
          cmty,
          mty_qty: mtyQty,
          cp,
          exact,
          exante_symbol: exanteSymbol,
          cp_symbol: cpSymbol,
          ccy,
          cp_type: cpType,
        } = row.original;
        const elementArray: { key: string; value: string }[] = [];
        elements.forEach((i) => {
          const input = i as HTMLInputElement;
          elementArray.push({ key: input.name, value: input.value });
        });
        const values = elementArray.reduce(
          (arr, item) => {
            const { key, value } = item;
            return { ...arr, [key]: Number(value) ? Number(value) : 0 };
          },
          { mty, cmty, mty_qty: mtyQty },
        );
        const payload = {
          cp,
          exact,
          exante_symbol: exanteSymbol,
          cp_symbol: cpSymbol,
          ccy,
          cp_type: cpType,
          account: cp,
          start_date: dayjs(selectedDate).toISOString(),
          value_multiplier: String(values.mty),
          price_multiplier: String(values.cmty),
          qty_multiplier: String(values.mty_qty),
        };
        try {
          await postSymbolsMapping(payload);
          Notification.success({ title: 'Success accepted' });
        } catch (e) {
          Notification.error({ title: 'Failed to add auto mapping' });
        } finally {
          setDisabled(true);
        }
      };
      return (
        <Button
          disabled={disabled}
          color="red"
          size="small"
          onClick={handleClick}
        >
          Accept
        </Button>
      );
    },
  },
];
