import cn from 'classnames';
import React, {
  useMemo,
  useState,
  FC,
  ChangeEvent,
  MutableRefObject,
} from 'react';
import {
  Button,
  Notification,
  useModal,
  Modal,
  Select,
  Input,
  Autocomplete,
} from 'react-ui-kit-exante';

import { PinnedBlock } from 'components/PinnedBlock';
import { useAppSelector } from 'hooks/redux';
import {
  TablesWithBreakReport,
  SIDE,
  Transaction,
  ReconcileTransactionsPayload,
  updateBreaks,
  postReconcileTransactions,
} from 'services/recon';
import { personalUserNamesSelector } from 'store/reducers/commonReducer';
import { getSumFromArray } from 'utils/array';
import { getSelectOptions } from 'utils/getSelectOptions';

import classes from './ActionsBlock.module.css';

interface Props {
  reloadData: VoidFunction;
  reloadReconciledTable: VoidFunction;
  transactions: Transaction[];
  selectedTransactions: Transaction[];
  onUpdateSelectedTransactions: (transactions: Transaction[]) => void;
  containerRef: MutableRefObject<null | HTMLDivElement>;
}

export const ActionsBlock: FC<Props> = ({
  reloadData,
  reloadReconciledTable,
  transactions,
  selectedTransactions,
  onUpdateSelectedTransactions,
  containerRef,
}) => {
  const {
    isOpened,
    onClose: closeModal,
    onOpen: openModal,
  } = useModal({ defaultOpen: false });
  const userNames = useAppSelector(personalUserNamesSelector);
  const breakCategories = useAppSelector((state) => state.breakCategories);
  const resolutionCategories = useAppSelector(
    (state) => state.resolutionCategories,
  );
  const [reconcileComment, setReconcileComment] = useState('');
  const [reconcileCategoryId, setReconcileCategoryId] = useState('');
  const [isShowReconcileComment, setIsShowReconcileComment] = useState(false);
  const [breakCategory, setBreakCategory] = useState(0);
  const [breakComment, setBreakComment] = useState('');
  const [assignee, setAssignee] = useState('');
  const [updateBreakIsLoading, setUpdateBreakIsLoading] = useState(false);

  const selectedTheirTransactions = useMemo(
    () => selectedTransactions.filter((i) => i.side === SIDE.THEIR),
    [selectedTransactions],
  );
  const selectedOurTransactions = useMemo(
    () => selectedTransactions.filter((i) => i.side === SIDE.OUR),
    [selectedTransactions],
  );
  const breakCategoryOptions = useMemo(
    () => getSelectOptions(breakCategories, 'name', 'id'),
    [breakCategories],
  );
  const userNamesOptions = useMemo(
    () => getSelectOptions(userNames),
    [userNames],
  );
  const resolutionCategoryOptions = useMemo(
    () => getSelectOptions(resolutionCategories, 'name', 'id'),
    [resolutionCategories],
  );
  const selectedElementsSum = useMemo(
    () =>
      parseFloat(
        Number(
          getSumFromArray(selectedOurTransactions, 'amount') -
            getSumFromArray(selectedTheirTransactions, 'amount'),
        ).toFixed(6),
      ),
    [selectedTransactions, transactions],
  );
  const { selectedElementsCurrencyIsSame, reconcileDisabled } = useMemo(() => {
    const everyIsOneCcy = selectedTransactions.every(
      (i) => i.ccy === selectedTransactions[0].ccy,
    );
    return {
      selectedElementsCurrencyIsSame: everyIsOneCcy,
      reconcileDisabled: selectedTransactions.length < 2 || !everyIsOneCcy,
    };
  }, [selectedTransactions, transactions]);

  const handleUpdateBreaks = async () => {
    if (!selectedTransactions.length) {
      Notification.error({
        title: 'Select transactions',
      });
      return;
    }
    setUpdateBreakIsLoading(true);
    const cpTransactionIds = selectedTheirTransactions.map((i) => i.id);
    const boTransactionIds = selectedOurTransactions.map((i) => i.id);
    try {
      const response = await updateBreaks({
        category: breakCategory,
        comment: breakComment,
        assignee,
        record_types_mapping: {
          [TablesWithBreakReport.CPTRANSACTION]: cpTransactionIds,
          [TablesWithBreakReport.FTBO]: boTransactionIds,
        },
      });
      if (!response.skipped_breaks.length) {
        Notification.success({
          title: 'Success updated break categories',
        });
        onUpdateSelectedTransactions([]);
      } else {
        Notification.error({
          title: 'Please set a break category for selected records',
        });
        const skippedTransactions = response.skipped_breaks.map((id) =>
          transactions.find((item) => item.id === id),
        );
        onUpdateSelectedTransactions(
          skippedTransactions.filter((item) => !!item) as Transaction[],
        );
      }
      await reloadData();
    } catch (e) {
      Notification.error({
        title: 'Error in change break categories process',
      });
    } finally {
      setUpdateBreakIsLoading(false);
    }
  };

  const handleChangeCategory = (event: React.SyntheticEvent, val: any) => {
    if (val?.value) {
      setBreakCategory(Number(val.value));
    } else {
      setBreakCategory(0);
    }
  };
  const handleChangeComment = (event: ChangeEvent<HTMLInputElement>) => {
    setBreakComment(event.target.value);
  };
  const handleChangeAssignee = (event: React.SyntheticEvent, val: any) => {
    if (val?.value) {
      setAssignee(val.value);
    } else {
      setAssignee('');
    }
  };
  const handleChangeReconcileComment = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setReconcileComment(event.target.value);
  };
  const handleChangeReconcileCategory = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setReconcileCategoryId(event.target.value);
  };
  const handleReconcile = async () => {
    closeModal();
    try {
      const cpSelectedIds = selectedTheirTransactions.map((i) => i.id);
      const boSelectedIds = selectedOurTransactions.map((i) => i.id);
      const payload: ReconcileTransactionsPayload = {
        cp_ids: cpSelectedIds,
        bo_ids: boSelectedIds,
      };
      if (reconcileComment) {
        payload.comment = reconcileComment;
      }
      if (reconcileCategoryId.length) {
        payload.resolution_category_id = reconcileCategoryId;
      }
      await postReconcileTransactions(payload);
      Notification.success({
        title: 'Transactions reconciled',
      });
      await reloadData();
      await reloadReconciledTable();
      onUpdateSelectedTransactions([]);
    } catch (e) {
      Notification.error({
        title: 'Reconcile error',
      });
    } finally {
      setReconcileComment('');
      setReconcileCategoryId('');
    }
  };
  const handleOpenReconcileConfirm = async () => {
    setIsShowReconcileComment(true);
    openModal();
  };
  const handleCloseModal = () => {
    closeModal();
    setIsShowReconcileComment(false);
  };
  const confirmReconcileIsDisabled = useMemo(
    () => isShowReconcileComment && !reconcileCategoryId,
    [isShowReconcileComment, reconcileCategoryId],
  );
  const updateBreakIsDisabled = useMemo(
    () =>
      !selectedTransactions.length ||
      (!assignee && !breakCategory && !breakComment) ||
      updateBreakIsLoading,
    [
      selectedTransactions,
      assignee,
      breakCategory,
      breakComment,
      updateBreakIsLoading,
    ],
  );

  return (
    <PinnedBlock containerRef={containerRef}>
      <p className="m-0 mt-2 text-left">Update break on selected: </p>
      <div>
        <div className="my-2">
          <div className={classes.actionBlockItems}>
            <div className={classes.actionBlockItem}>
              <Autocomplete
                placeholder="Break Category"
                className="w-100"
                options={breakCategoryOptions}
                onChange={handleChangeCategory}
              />
            </div>
            <div className={classes.actionBlockItem}>
              <Input
                label="Comment"
                fullWidth
                value={breakComment}
                onChange={handleChangeComment}
              />
            </div>
            <div className={classes.actionBlockItem}>
              <Autocomplete
                placeholder="Assignee"
                className="w-100"
                options={userNamesOptions}
                onChange={handleChangeAssignee}
              />
            </div>
            <div className={classes.actionBlockItem}>
              <Button
                fullWidth
                disabled={updateBreakIsDisabled}
                onClick={handleUpdateBreaks}
                color="red"
              >
                Update Break
              </Button>
            </div>
            <div className={classes.actionBlockItem}>
              <Button
                fullWidth
                disabled={reconcileDisabled}
                onClick={handleOpenReconcileConfirm}
              >
                Reconcile
              </Button>
            </div>
          </div>
        </div>
        {selectedTransactions.length ? (
          <div className="row">
            <div className="col-12">
              <div
                className={cn({
                  'my-2': true,
                  alert: true,
                  'w-100': true,
                  'alert-danger': Number(selectedElementsSum) !== 0,
                  'alert-primary': Number(selectedElementsSum) === 0,
                })}
              >
                Value difference between selected transactions is{' '}
                <b>{selectedElementsSum}</b>
              </div>
              {!selectedElementsCurrencyIsSame ? (
                <div className="alert alert-danger my-2 w-100">
                  CCY is different!
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      <Modal
        isOpened={isOpened}
        title="Resolution"
        onClose={handleCloseModal}
        confirmButton={{
          confirmButtonName: 'Confirm',
          handleConfirm: handleReconcile,
          confirmButtonIsDisabled: confirmReconcileIsDisabled,
        }}
      >
        {isShowReconcileComment && (
          <>
            {selectedElementsSum !== 0 && (
              <p className="my-0">Value difference is {selectedElementsSum}</p>
            )}
            <p className="my-0">
              If you still want to reconcile this objects, please provide
              comment in field below and press Confirm button
            </p>
            <Input
              label="Comment"
              fullWidth
              value={reconcileComment}
              onChange={handleChangeReconcileComment}
            />
            <Select
              label="Category"
              className="mt-3 w-100"
              value={reconcileCategoryId}
              options={resolutionCategoryOptions}
              onChange={handleChangeReconcileCategory}
            />
          </>
        )}
        <p>Do you want to reconcile them?</p>
      </Modal>
    </PinnedBlock>
  );
};
