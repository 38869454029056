import { ChangeEvent, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import {
  FetchPositionReconUnmappedPayload,
  FetchSearchMappingsForPositionPayload,
  PositionReconciliation,
  searchUnmappedPositions,
  searchMappingsForPosition,
  postSymbolsMapping,
  updatePositionReconciliationFromMapping,
  PositionMappingCandidate,
} from 'services/recon';

export function useTablesData() {
  const [positionsIsLoading, setPositionsIsLoading] = useState(false);
  const [positions, setPositions] = useState<PositionReconciliation[]>([]);
  const [selectedPosition, setSelectedPosition] =
    useState<PositionReconciliation | null>(null);
  const [mty, setMty] = useState('1');

  const [candidatesIsLoading, setCandidatesIsLoading] = useState(false);
  const [positionReconciliations, setPositionReconciliations] = useState<
    PositionReconciliation[]
  >([]);
  const [candidates, setCandidates] = useState<PositionMappingCandidate[]>([]);

  const [candidatePostIsLoading, setCandidatePostIsLoading] = useState(false);

  const fetchPositions = async (payload: FetchPositionReconUnmappedPayload) => {
    setPositionsIsLoading(true);
    try {
      const response = await searchUnmappedPositions(payload);
      setPositions(response);
    } catch (error) {
      Notification.error({ title: 'Load positions error' });
    } finally {
      setPositionsIsLoading(false);
    }
  };

  const fetchMappingsForPosition = async (
    payload: Omit<FetchSearchMappingsForPositionPayload, 'mty'>,
  ) => {
    setCandidatesIsLoading(true);
    try {
      const foundPosition = positions.find((i) => i.id === payload.id);
      if (foundPosition) {
        setSelectedPosition(foundPosition);
      }
      const response = await searchMappingsForPosition({
        ...payload,
        mty: Number(mty),
      });
      setPositionReconciliations(response.position_reconciliation);
      setCandidates(response.candidates);
    } catch (error) {
      Notification.error({
        title: 'Load mappings error',
      });
    } finally {
      setCandidatesIsLoading(false);
    }
  };

  const postMapping = async (candidate: PositionMappingCandidate) => {
    setCandidatePostIsLoading(true);
    const startDate = new Date(selectedPosition?.report_date ?? '');
    const payload = {
      account: selectedPosition?.account ?? null,
      exante_symbol: candidate.symbol,
      ccy: candidate.ccy,
      isin: candidate.isin,
      cp_symbol: selectedPosition?.cp_symbol ?? '',
      cp: selectedPosition?.counterparty ?? '',
      cp_type: selectedPosition?.symboltype ?? null,
      mty: 1,
      cmty: 1,
      mty_qty: mty,
      start_date: startDate.toISOString(),
    };
    try {
      const response = await postSymbolsMapping(payload);
      setCandidates([]);
      await updatePositionReconciliationFromMapping(response.id);
    } catch (error) {
      Notification.error({ title: 'Post mapping error' });
    } finally {
      await fetchPositions({
        report_date: selectedPosition?.report_date,
        legal_entity: selectedPosition?.legal_entity,
        mode: selectedPosition?.mode,
      });
      setCandidatePostIsLoading(false);
    }
  };

  const handleChangeMty = (event: ChangeEvent<HTMLInputElement>) => {
    setMty(event.target.value);
  };

  return {
    fetchPositions,
    positionsIsLoading,
    positions,
    selectedPosition,

    candidatesIsLoading,
    fetchMappingsForPosition,
    candidates,
    positionReconciliations,

    postMapping,
    candidatePostIsLoading,

    mty,
    handleChangeMty,
  };
}
