export function getUrlFromPayload(url: string, payload: Record<string, any>) {
  return Object.keys(payload).reduce((acc, key) => {
    let value = payload[key];
    if (
      value === '' ||
      value === undefined ||
      (Array.isArray(value) && !value.length)
    ) {
      return acc;
    }
    if (typeof value === 'string' && value.includes('&')) {
      value = value.replace('&', '%26');
    }
    return `${acc}${key}=${value}&`;
  }, url);
}
