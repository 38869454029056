import React, { ChangeEvent, SyntheticEvent, useContext, useMemo } from 'react';
import { Autocomplete, Button, Checkbox, Input } from 'react-ui-kit-exante';

import { SelectOption } from 'types';

import { ConditionItem } from '../ConditionItem/ConditionItem';
import { ReportFormContext } from '../ReportFormContext';
import { InfoBlock } from '../components';
import { FieldMappingItem as IFieldMappingItem } from '../types';

interface Props {
  value: IFieldMappingItem;
  mappingFieldOptions: SelectOption[];
}

export const FieldMappingItem: React.FC<Props> = ({
  value,
  mappingFieldOptions,
}) => {
  const {
    getConditionsByMappingId,
    onDeleteMapping,
    onPermanentlyDeleteMapping,
    onAddCondition,
    onUpdateMapping,
  } = useContext(ReportFormContext);

  const conditions = useMemo(
    () => getConditionsByMappingId(value.uuid),
    [value, getConditionsByMappingId],
  );

  const handleAddCondition = () => {
    onAddCondition(value.uuid);
  };

  const handleChangeCpField = (e: ChangeEvent<HTMLInputElement>) => {
    onUpdateMapping({ ...value, cp_field: e.target.value });
  };
  const handleChangeConditionOperations = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    onUpdateMapping({ ...value, condition_operations: e.target.value });
  };
  const handleChangeDateFormat = (e: ChangeEvent<HTMLInputElement>) => {
    onUpdateMapping({ ...value, date_format: e.target.value });
  };
  const handleChangeMappingField = (e: SyntheticEvent, newValue: any) => {
    onUpdateMapping({ ...value, mapping_field: newValue?.value });
  };
  const handleChangeAction = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onUpdateMapping({ ...value, skip: String(checked) });
  };

  const handleDeleteFieldMapping = () => {
    if (value.isNew) {
      onDeleteMapping(value.uuid);
    } else {
      onPermanentlyDeleteMapping(value.uuid);
    }
  };

  return (
    <>
      <div className="row my-2">
        <div className="col-md-2">
          {value.isNew ? (
            <Input
              fullWidth
              onChange={handleChangeConditionOperations}
              value={value.condition_operations}
              label="Condition operations"
            />
          ) : (
            <InfoBlock
              value={value.condition_operations}
              label="Condition operations"
            />
          )}
        </div>
        <div className="col-md-2">
          {value.isNew ? (
            <Input
              fullWidth
              onChange={handleChangeDateFormat}
              value={value.date_format}
              label="Date format"
            />
          ) : (
            <InfoBlock value={value.date_format} label="Date format" />
          )}
        </div>
        <div className="col-md-2">
          {value.isNew ? (
            <Input
              fullWidth
              disabled={!value.isNew}
              onChange={handleChangeCpField}
              value={value.cp_field}
              label="CP Field"
            />
          ) : (
            <InfoBlock value={value.cp_field} label="CP Field" />
          )}
        </div>
        <div className="col-md-2">
          {value.isNew ? (
            <Autocomplete
              placeholder="Mapping Field"
              className="w-100"
              disabled={!value.isNew}
              options={mappingFieldOptions}
              onChange={handleChangeMappingField}
            />
          ) : (
            <InfoBlock value={value.mapping_field} label="Mapping Field" />
          )}
        </div>
        <div className="col-md-1">
          <Checkbox
            className="mt-4"
            checked={value.skip === 'true'}
            label="Skip"
            disabled={!value.isNew}
            onChange={handleChangeAction}
          />
        </div>
        <div className="col-md-1">
          <Button fullWidth color="red" onClick={handleDeleteFieldMapping}>
            Delete
          </Button>
        </div>
        {value.isNew && (
          <div className="col-md-2">
            <Button fullWidth onClick={handleAddCondition}>
              Add condition
            </Button>
          </div>
        )}
      </div>
      {!!conditions.length && (
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-10 text-left">
            <b>
              <p className="font-size-16">Conditions: </p>
            </b>
          </div>
        </div>
      )}
      {conditions.map((item) => (
        <ConditionItem key={item.uuid} value={item} />
      ))}
    </>
  );
};
