import { ICellValue } from 'react-ui-kit-exante';

import { RawDataReportSetup } from 'services/recon';

export const columns = [
  {
    Header: 'ID',
    accessor: 'id',
    width: 60,
    disableFilters: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    minWidth: 100,
    width: 100,
    disableFilters: true,
  },
  {
    Header: 'isActive',
    accessor: 'isActive',
    minWidth: 80,
    width: 80,
  },
  {
    Header: 'active from',
    accessor: 'active_from',
    minWidth: 100,
    width: 100,
    disableFilters: true,
  },
  {
    Header: 'active to',
    accessor: 'active_to',
    minWidth: 100,
    width: 100,
    disableFilters: true,
  },
  {
    Header: 'Module',
    accessor: 'module',
    minWidth: 100,
    width: 100,
    disableFilters: true,
  },
  {
    id: 'module_configs',
    Header: 'Module configs',
    width: 220,
    disableFilters: true,
    Cell: ({ row }: ICellValue<RawDataReportSetup>) => {
      const isShowPartly = ['excel', 'csv'].includes(row.original.module);
      const pathItem = row.original.module_configs.find(
        (item) => item.param_name === 'path',
      );
      const containsItem = row.original.module_configs.find(
        (item) => item.param_name === 'contains',
      );
      const showInfoMessage = row.original.module_configs.length > 2;
      return row.original.module_configs.length ? (
        <div className="text-left">
          {isShowPartly && (
            <ul className="list-group">
              {pathItem && (
                <li>
                  <span className="font-weight-bold">
                    {pathItem.param_name}
                  </span>
                  : {pathItem.param_value}
                </li>
              )}
              {containsItem && (
                <li>
                  <span className="font-weight-bold">
                    {containsItem.param_name}
                  </span>
                  : {containsItem.param_value}
                </li>
              )}
              {showInfoMessage && <span>...</span>}
            </ul>
          )}
        </div>
      ) : (
        'no configs'
      );
    },
  },
];
