import { ReactNode } from 'react';

import { ReactComponent as ErrorIcon } from 'icons/checkError.svg';
import { ReactComponent as PassIcon } from 'icons/checkPass.svg';
import { CpDataMonitorItemStatuses } from 'services/recon';

const completedList = [
  CpDataMonitorItemStatuses.COMPLETED,
  CpDataMonitorItemStatuses.HOLIDAY,
  CpDataMonitorItemStatuses.DONE,
];
const errorList = [
  CpDataMonitorItemStatuses.FILE_NOT_FOUND,
  CpDataMonitorItemStatuses.FILE_NOT_FOUND_NOTIFIED,
  CpDataMonitorItemStatuses.FAILED,
  CpDataMonitorItemStatuses.NOT_READY,
  CpDataMonitorItemStatuses.IN_PROGRESS,
  CpDataMonitorItemStatuses.WAITING,
  CpDataMonitorItemStatuses.NOT_STARTED,
  CpDataMonitorItemStatuses.HAVE_NOT_STATUS,
];

export const getIconByStatus = (status: CpDataMonitorItemStatuses) => {
  const returnObject: { icon: null | ReactNode; status: 'error' | 'success' } =
    {
      icon: null,
      status: 'error',
    };
  if (errorList.includes(status)) {
    returnObject.icon = <ErrorIcon />;
  }
  if (completedList.includes(status)) {
    returnObject.icon = <PassIcon />;
    returnObject.status = 'success';
  }
  return returnObject;
};
