import { Component } from 'react';
import { Provider } from 'react-redux';
import { Router, BrowserRouter, RouterProps } from 'react-router-dom';
import { NotificationProvider } from 'react-ui-kit-exante';

import { ErrorBoundary } from 'components';
import { MetaDataFetcher } from 'containers';
import { setupStore } from 'store';
import { getBaseName } from 'utils';

import Pages from './Router';

const store = setupStore();

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  render() {
    const isInsideRun = !!window.runUIhistoryContainer;
    const baseName = getBaseName();

    const runHistory =
      window.runUIhistoryContainer as unknown as RouterProps['history'];

    const routes = isInsideRun ? (
      <Router history={runHistory}>
        <Pages />
      </Router>
    ) : (
      <BrowserRouter basename={baseName}>
        <Pages />
      </BrowserRouter>
    );

    return (
      <ErrorBoundary>
        <Provider store={store}>
          <NotificationProvider />
          <MetaDataFetcher>{routes}</MetaDataFetcher>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
