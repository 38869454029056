import dayjs from 'dayjs';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { DATE_FORMAT } from 'constants/date';
import { DEFAULT_FILTER_VALUES } from 'constants/tables';
import {
  CpAndCTrade,
  ReconcileTradesPayload,
  SIDE,
  reconcileTrades,
  getCTrades,
} from 'services/recon';
import { getDefaultFilterParams } from 'utils/getDefaultFilterParams';

import { getColumns, getPairColumns } from './utils/getColumns';

export const useAutoReconcile = () => {
  const filtersIsFirstMounted = useRef(false);

  const applyObject = {
    date: [dayjs().subtract(7, 'day').toDate(), dayjs().toDate()],
    counterparty: DEFAULT_FILTER_VALUES.cp,
    mode: DEFAULT_FILTER_VALUES.mode,
  };
  const filterKeys = getColumns({}).map((column) => column.accessor);
  const prepareFiltersForParams = useCallback(
    getDefaultFilterParams({
      filterKeys,
      applyObject,
      isApply: filtersIsFirstMounted,
      shouldApply: !window.location.search,
    }),
    [],
  );

  return {
    prepareFiltersForParams,
  };
};

interface UsePairsLogicPayload {
  reloadCpTrades: VoidFunction;
}
export const usePairsLogic = ({ reloadCpTrades }: UsePairsLogicPayload) => {
  const [activeTradeId, setActiveTradeId] = useState<null | number>(null);
  const [foundPairs, setFoundPairs] = useState<CpAndCTrade[]>([]);
  const [pairsIsLoading, setPairsIsLoading] = useState(false);

  const handleSearchPair = async (trade: CpAndCTrade) => {
    try {
      setPairsIsLoading(true);
      const response = await getCTrades({
        params: { page: 0, limit: 100, skip: 0, sorting: [] },
        paginationParams: { page: 0, limit: 100, skip: 0 },
        sortingParams: {},
        filtersParams: {
          side: SIDE.OUR,
          recstatus: false,
          date: [
            dayjs(trade.date).subtract(14, 'days').format(DATE_FORMAT),
            dayjs().format(DATE_FORMAT),
          ],
          mode: trade.mode,
          le: trade.legal_entity_name,
          price: trade.price,
          qty: trade.qty,
          ccy: trade.ccy,
        },
      });
      setActiveTradeId(trade.id);
      setFoundPairs(response.trades ?? []);
    } catch (e) {
      Notification.error({ title: 'Find pair error' });
    } finally {
      setPairsIsLoading(false);
    }
  };

  const handleReconcile = async (ourTradeId: number) => {
    if (!activeTradeId) {
      return;
    }
    try {
      const payload: ReconcileTradesPayload = {
        ctrade_ids: [ourTradeId],
        cptrade_ids: [activeTradeId],
        comment: 'auto-reconcile',
      };
      await reconcileTrades(payload);
      Notification.success({ title: 'Trades was reconciled' });
      setFoundPairs([]);
      setActiveTradeId(null);
      reloadCpTrades();
    } catch (e) {
      Notification.error({ title: 'Reconcile error' });
    }
  };

  const pairColumns = useMemo(() => getPairColumns(), []);

  return {
    handleSearchPair,
    activeTradeId,
    foundPairs,
    pairsIsLoading,
    pairColumns,
    handleReconcile,
  };
};
