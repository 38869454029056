import { IColumn } from 'react-ui-kit-exante';

import { ValueMappingRule } from 'services/recon';
import { ColumnsWithFilters, SelectOption } from 'types';

import { GetConditionCell } from './getConditionCell';

interface GetColumnsPayload extends ColumnsWithFilters {
  cpOptions: SelectOption[];
  leOptions: SelectOption[];
  modeOptions: SelectOption[];
  reportOptions: SelectOption[];
}
export const getColumns = ({
  onFilter,
  onRemove,
  cpOptions,
  leOptions,
  modeOptions,
  reportOptions,
}: GetColumnsPayload | Record<string, any>) => {
  const columns: IColumn<ValueMappingRule>[] = [
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 160,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'CP',
      accessor: 'counterparty',
      disableSortBy: true,
      filterOptions: cpOptions,
      type: 'select',
      onFilter,
      onRemove,
    },
    {
      Header: 'Legal entity',
      accessor: 'legal_entity',
      disableSortBy: true,
      filterOptions: leOptions,
      type: 'select',
      onFilter,
      onRemove,
    },
    {
      Header: 'Mode',
      accessor: 'mode',
      disableSortBy: true,
      filterOptions: modeOptions,
      type: 'select',
      onFilter,
      onRemove,
    },
    {
      Header: 'Report',
      accessor: 'report_name',
      disableSortBy: true,
      filterOptions: reportOptions,
      type: 'select',
      onFilter,
      onRemove,
    },
    {
      Header: 'Order Index',
      accessor: 'order_index',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Update Field',
      accessor: 'cp_update_field',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Source Field',
      accessor: 'cp_source_field',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Replace Type',
      accessor: 'replace_type',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Replace Value',
      accessor: 'replace_value',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Standard Value',
      accessor: 'standard_value',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Standard Type',
      accessor: 'standard_value_type',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Start Date',
      accessor: 'start_date',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'End Date',
      accessor: 'end_date',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Conditions',
      accessor: 'conditions',
      minWidth: 300,
      Cell: GetConditionCell,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Active',
      accessor: 'active',
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  return columns;
};
