import { ParsingModuleParam } from 'services/recon';

export const paramValuesMap = (param: ParsingModuleParam) => {
  let defaultValue: string | boolean = '';
  if (param.param_type === 'bool') {
    defaultValue = Boolean(param.default_value);
  }
  if (
    param.default_value &&
    (param.param_type === 'string' || param.param_type === 'select')
  ) {
    defaultValue = param.default_value;
  }
  return {
    param_name: param.param_name,
    param_value: defaultValue,
  };
};
