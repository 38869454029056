import { useCallback, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

const WIDTH_PERCENT = 100;

export const useResize = () => {
  const [contentWidth, setContentWidth] = useState(0);

  const onResize = useCallback((width?: number) => {
    setContentWidth(Number(((width ?? 0 * WIDTH_PERCENT) / 100).toFixed()));
  }, []);

  const { ref: containerRef } = useResizeDetector({ onResize });

  return { contentWidth, containerRef };
};
