import dayjs from 'dayjs';
import { ChangeEvent, FC, useState } from 'react';
import { Select, Button, Notification } from 'react-ui-kit-exante';

import { DATE_FORMAT } from 'constants/date';
import { RunByCPAndTypePayload } from 'services/reconRunner';

import { ModifyCounterparty } from '../types';

import { options } from './constants';

export const ActionCell: FC<{
  value: ModifyCounterparty;
  onAction: (payload: RunByCPAndTypePayload) => void;
  buttonIsDisabled: boolean;
}> = ({
  value: { legal_entity, name, mode, report_date },
  onAction,
  buttonIsDisabled,
}) => {
  const [type, setType] = useState('');

  const handleSetScriptType = (event: ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  };

  const handleRunScripts = async () => {
    if (!type) {
      Notification.error({ title: 'Select scriptType' });
      return;
    }
    const payload = {
      cp: `${name}${legal_entity}${mode}`,
      date: dayjs(report_date).format(DATE_FORMAT),
      type,
    };
    onAction(payload);
  };
  return (
    <div className="d-flex w-100">
      <Select
        size="small"
        className="mr-3"
        fullWidth
        onChange={handleSetScriptType}
        options={options}
      />
      <Button
        disabled={buttonIsDisabled}
        size="small"
        className="w-lg"
        onClick={handleRunScripts}
      >
        Rerun
      </Button>
    </div>
  );
};
