import { IColumn } from 'react-ui-kit-exante';

import { CutOffTimeBySymbol } from 'services/recon';

export const columns: IColumn<CutOffTimeBySymbol> = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Cp',
    accessor: 'cp',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Time',
    accessor: 'time',
    disableFilters: true,
    disableSortBy: true,
    editParams: {
      editable: true,
      inputType: 'text',
    },
  },
];
